/* RESPONSIBLE TEAM: team-billing */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr, belongsTo, type SyncHasMany } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { ANSWER_BOT_ESSENTIAL_ID, PLAN_DATA } from 'embercom/lib/billing';
import { inject as service } from '@ember/service';
import {
  CORE_ACCELERATE_ID,
  CORE_STARTER_ID,
  PLAN_DATA_CUSTOM_ANSWERS,
} from 'embercom/lib/billing';

import BillingConstants from 'embercom/lib/billing';

import type Product from './product';
import type Store from '@ember-data/store';
import { dasherize } from '@ember/string';
import type IntlService from 'embercom/services/intl';
import moment from 'moment-timezone';
import { isPresent } from '@ember/utils';
import { isServicesPlan } from 'embercom/lib/billing/plans';

export default class Plan extends Model {
  @attr('boolean') declare active: boolean;
  @attr('boolean') declare activeTrial: boolean;
  @attr('boolean') declare activeTrialIsGraduating: boolean;
  @attr('boolean') declare billableCustomerPlan: boolean;
  @attr('date') declare activatedAt: Date;
  @attr('date') declare deactivatedAt: Date;
  @attr('number') declare tierId: number;
  @attr('boolean') declare selfServe: boolean;
  @attr('boolean') declare selfServeTrialable: boolean;
  @attr('date') declare activeTrialStartedAt: Date;
  @attr('date') declare activeTrialEndedAt: Date;
  @attr('date') declare latestTrialEndedAt: Date;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;

  @belongsTo('product', { async: false }) declare product: Product;

  @fragmentArray('billing-feature') declare features: SyncHasMany<any>;
  @fragmentArray('pricing-strategy') declare pricingStrategies: SyncHasMany<any>;

  get idAsNumber(): number {
    return parseInt(this.id, 10);
  }
  get price(): any | undefined {
    let prices = this.store.peekAll('price');
    return prices.find((price) => price.hasSamePlans([this.idAsNumber]));
  }

  get planData(): any {
    if (this.appService.app?.hasIntershopFinEnabled && this.id === ANSWER_BOT_ESSENTIAL_ID) {
      return PLAN_DATA_CUSTOM_ANSWERS;
    }
    return PLAN_DATA[this.id];
  }

  get description(): string {
    return this.planData?.description;
  }

  get descriptionTranslationKey(): string {
    return this.planData?.descriptionTranslationKey;
  }

  get marketingFeaturesTranslationKeys(): Array<string> | undefined {
    return this.planData?.marketingFeaturesTranslationKeys;
  }

  get name(): string {
    //used very rarely, mostly in the context of a product
    return this.planData?.name;
  }

  get nameTranslationKey(): string {
    return this.planData?.nameTranslationKey;
  }

  get marketingName() {
    //used in the purchase flow
    return this.planData?.marketingName || this.nameWithProduct;
  }

  get marketingNameTranslationKey(): string {
    return this.planData?.marketingNameTranslationKey || this.nameWithProductTranslationKey;
  }

  get selfTrialable() {
    return this.planData.selfTrialAvailableOnContract;
  }

  get nameWithProduct() {
    //most of the time this is the name that should be used.
    return this.planData?.nameWithProductTranslationKey
      ? this.intl.t(this.planData?.nameWithProductTranslationKey)
      : this.planData?.nameWithProduct || this.name;
  }

  get nameWithProductTranslationKey(): string {
    return this.planData?.nameWithProductTranslationKey || this.nameTranslationKey;
  }

  get messengerText(): string {
    let planName = this.nameWithProduct;
    if (planName.includes('plan')) {
      planName = this.intl.t('app.models.plan.the-plan-name', { planName });
    }
    return this.intl.t('app.models.plan.messenger-text', { planName });
  }

  get prefix() {
    if (this.product?.id === CORE_STARTER_ID && this.id === CORE_ACCELERATE_ID) {
      return 'Everything in Starter, plus:';
    }
    return this.planData?.prefix;
  }

  get prefixTranslationKey() {
    if (this.product?.id === CORE_STARTER_ID && this.id === CORE_ACCELERATE_ID) {
      return 'app.models.plan.starter-prefix';
    }
    return this.planData?.prefixTranslationKey;
  }

  /**
   * Used in the subscription-upgrade component to determine
   * what missing plans are required from the customer.
   */
  get requiresAdditionalPlans() {
    return (BillingConstants.MODAL_ADD_PLAN_INFO as any)[this.idAsNumber]?.multiplan || false;
  }

  get showPricingMetrics() {
    return (
      (BillingConstants.MODAL_ADD_PLAN_INFO as any)[this.idAsNumber]?.allowedPricingMetricsCharge
        ?.length > 0 || false
    );
  }

  get customTooltipContent() {
    return (BillingConstants.MODAL_ADD_PLAN_INFO as any)[this.idAsNumber]?.customTooltipContent;
  }

  get showPricingPageURL() {
    return this.planData?.showPricingPageURL;
  }

  get slug(): string {
    return dasherize(this.name);
  }

  get isHighestTierOnTrial(): boolean {
    if (!this.activeTrial) {
      return false;
    }
    let highestPlanTierOnTrialId = this.product?.plans?.filter((plan) => plan.activeTrial)
      ?.lastObject?.id;

    return this.id === highestPlanTierOnTrialId;
  }

  get isHigherTierActive(): boolean {
    let activePlan = this.product?.plans?.find((p) => p.active);
    if (activePlan === undefined) {
      return false;
    }

    return activePlan.tierId > this.tierId;
  }

  get isLowerTierThanBillableCustomerPlan(): boolean {
    let billableCustomerPlan = this.product?.plans?.find((p) => p.billableCustomerPlan);
    if (billableCustomerPlan === undefined) {
      return false;
    }

    return billableCustomerPlan.tierId > this.tierId;
  }

  get daysToTrialExpiration(): number {
    if (this.isPostTrial) {
      return 0;
    }

    let today = moment().startOf('day');
    let expirationDay = moment(this.activeTrialEndedAt).startOf('day');
    let diff = expirationDay.diff(today);
    return Math.round(moment.duration(diff).asDays());
  }

  get isPostTrial(): boolean {
    return !this.activeTrial && this.trialLatelyExpired;
  }

  get trialLatelyExpired(): boolean {
    if (!isPresent(this.latestTrialEndedAt)) {
      return false;
    }
    let expirationDay = moment(this.latestTrialEndedAt).startOf('day');
    let today = moment().startOf('day');
    let diff = today.diff(expirationDay);
    return Math.round(moment.duration(diff).asDays()) <= 14;
  }

  get finFeatureComponent(): object {
    return this.planData?.finFeatureComponent;
  }

  get badge(): object {
    return this.planData?.badge;
  }

  get isServicesPlan() {
    return isServicesPlan(this.id);
  }
}
