/* RESPONSIBLE TEAM: team-reporting */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { type AccessType } from 'embercom/services/report-access-service';

export default class ReportAccessControlList extends Model {
  @service appService: $TSFixMe;

  @attr('string') declare adminId?: string;
  @attr('string') declare reportId: string;
  @attr('string') declare accessType: AccessType;
  @attr('boolean') declare hasConflict: boolean;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  get app() {
    return this.appService.app;
  }

  async updateAccess(accessType: AccessType) {
    this.accessType = accessType;
    return this.save();
  }
}
