/* RESPONSIBLE TEAM: team-messenger */
import { attr } from '@ember-data/model';
import { gt } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
const CHAR_LIMIT = 160;

export default Fragment.extend({
  localeId: attr('string'),
  name: attr('string'),
  // TODO: Rename this as welcome messages are now Team Intros
  userWelcomeMessage: attr('string'),
  isPermitted: attr('boolean'),
  greeting: attr('string', { defaultValue: '' }),
  greetingSubtitle: attr('string', { defaultValue: '' }),
  mobileGreeting: attr('string', { defaultValue: '' }),
  mobileGreetingSubtitle: attr('string', { defaultValue: '' }),
  welcomeMessageTooLong: gt('userWelcomeMessage.length', CHAR_LIMIT),
  conversationalGreeting: attr('string', { defaultValue: '' }),
});
