/* RESPONSIBLE TEAM: team-proactive-support */
export enum EntityType {
  Tour = 0,
  TourProgress = 1,
  //InboundTrigger = 2,
  MessengerHomeCardSlot = 3,
  InApp = 4,
  Conversation = 5,
  Article = 6,
  ArticleView = 7,
  Email = 8,
  //MessengerTrigger = 9,
  Banner = 10,
  BannerView = 11,
  LegacyMessagePush = 12,
  LegacyMessageInApp = 13,
  LegacyMessageEmail = 14,
  LegacyMessageControlGroup = 15,
  Ruleset = 16,
  LegacyMessageVariation = 17,
  Push = 18,
  PushInstance = 19,
  RulesetLink = 20,
  MessengerInboundConversationsForUsers = 21,
  MessengerInboundConversationsForVisitors = 22,
  Team = 23,
  Answer = 24,
  FakePush = 25,
  FakePushInstance = 26,
  ConversationSla = 27,
  AppTeam = 28,
  //FakeEmail = 29,
  Sms = 30,
  SmsInstance = 31,
  MessengerContentSuggestionsForUsers = 32,
  MessengerContentSuggestionsForVisitors = 33,
  ControlGroup = 34,
  ControlGroupInstance = 35,
  FakeInApp = 36,
  FakeInAppInstance = 37,
  Chat = 38,
  LocalizedChatContent = 39,
  //SeriesSingleEntryInstance = 40,
  SeriesMultiEntryInstance = 41,
  Carousel = 42,
  LocalizedCarouselContent = 43,
  CarouselInstance = 44,
  Post = 45,
  LocalizedPostContent = 46,
  Series = 47,
  SeriesEntryRecord = 48,
  RenderableChatVersion = 49,
  RenderablePostVersion = 50,
  SeriesCondition = 51,
  SeriesWait = 52,
  Tagger = 53,
  CustomBot = 54,
  CustomBotConversationState = 55,
  LocalizedEmailContent = 56,
  LocalizedEmailContentVersion = 57,
  AnswerbotPredictiveContext = 58,
  MessengerSettingsPredictiveAnswersForUsers = 59,
  MessengerSettingsPredictiveAnswersForVisitors = 60,
  LocalizedBannerContent = 61,
  InboundCustomBot = 62,
  ButtonCustomBot = 63,
  SeriesSplitter = 64,
  EdgeSplit = 65,
  SeriesCheckpoint = 66,
  LegacyMessage = 67,
  ResolutionBotBehavior = 68,
  ResolutionBotConversationState = 69,
  ConversationPart = 70,
  UserMessage = 71,
  Role = 72,
  MessengerSettingsHelpCenterRequireSearchForUsers = 73,
  MessengerSettingsHelpCenterRequireSearchForVisitors = 74,
  OutboundWebhook = 75,
  OutboundWebhookInstance = 76,
  CustomBotSnapshot = 77,
  Workflow = 78,
  AssignmentRule = 79,
  Admin = 80,
  Assignee = 81,
  Bot = 82,
  User = 83,
  Survey = 84,
  SenderEmailAddress = 88,
  Tag = 89,
  SavedReply = 90,
  LegacyRule = 91,
  InboxView = 95,
  SurveyStep = 96,
  TriggerableCustomBot = 104,
  GithubIssue = 107,
  ConversationTopic = 112,
  WorkflowConnectorAction = 113,
  Whatsapp = 115,
  ArticleContent = 120,
  HelpCenterSite = 126,
  ArticleCollection = 127,
  ExternalContent = 129,
  ContentImportSource = 130,
  ContentSnippet = 131,
  FileSourceContent = 134,
  PastConversationSnippet = 136,
  InternalArticle = 137,
  ContentLibraryFolder = 138,
  ConversationExcerpt = 142,
  SlackThread = 143,
  FinPersistedDataAttribute = 151,
}
