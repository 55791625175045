/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { EntityType } from 'embercom/models/data/entity-types';
import { type EmberCPValidationsCompat } from 'embercom/validations/typescript/ember-cp-validations-compat';
import { validateHelpCenterRedirect } from 'embercom/validations/typescript/help-center-redirect/help-center-redirect';
import type Store from '@ember-data/store';
import BrowserLocales from 'embercom/lib/browser-locales';

export default class HelpCenterRedirect extends Model implements EmberCPValidationsCompat {
  @service declare appService: any;
  @service declare helpCenterService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @attr('number') declare articleId?: number;
  @attr('number') declare collectionId?: number;
  @attr('string') declare fromUrl: string;
  @attr('string') declare locale?: string;
  @attr('number') declare entityId?: number;
  @attr('number') declare entityType: EntityType;
  @attr('string') declare entityName: string;
  @attr('string') declare entityState: 'published' | 'draft';
  @attr('number') declare helpCenterId: number;

  get validations() {
    return validateHelpCenterRedirect(this);
  }

  get isRedirectValid() {
    return this.validations.isValid;
  }

  get selectedArticleId() {
    if (this.entityId && this.id) {
      let articleContent = this.store.peekRecord('articles/article-content', this.entityId);

      return articleContent?.article?.get('id');
    }

    return this.entityId;
  }

  get name() {
    switch (this.entityType) {
      case EntityType.ArticleContent:
        return this.intl.t(
          'articles.settings.redesign.general.domain.redirects.drawer.view.article',
        );
      case EntityType.ArticleCollection:
        return this.intl.t(
          'articles.settings.redesign.general.domain.redirects.drawer.view.collection',
        );
      default:
        return '';
    }
  }

  get intercomUrl() {
    switch (this.entityType) {
      case EntityType.ArticleContent:
        return `/a/apps/${this.appService.app.id}/articles/articles/article-contents/${this.entityId}/edit?language=${this.locale}`;
      case EntityType.ArticleCollection:
        return this.appService.app.canUseMultipleHelpCenters
          ? `/a/apps/${this.appService.app.id}/articles/site/collections/site-collection/${this.helpCenterService.site.id}?language=${this.locale}`
          : `/a/apps/${this.appService.app.id}/articles/site/collections?language=${this.locale}`;
      default:
        return '';
    }
  }

  get localeName() {
    if (!this.locale) {
      return '';
    }
    return BrowserLocales[this.locale] || this.locale?.toUpperCase();
  }
}
