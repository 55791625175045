/* RESPONSIBLE TEAM: team-ai-chatbot */
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Validations from 'embercom/validations/operator/bot-intro/part';
import { type Block } from 'embercom/models/common/blocks/block';
import { type SyncHasMany } from '@ember-data/model';

export default class Part extends Fragment.extend(Validations) {
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;

  get jsonBlocks() {
    return this.blocks.serialize();
  }
}
