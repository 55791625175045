/* RESPONSIBLE TEAM: team-purchase-experience */
import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

export default class StripeIntent extends Model {
  @service declare intl: IntlService;

  @belongsTo('app', { async: false, inverse: null }) declare app: any; // App model is written in legacy Ember which is awkward with TypeScript, so typing as 'any' for now
  @attr('string') declare clientSecret: string;
  @attr('string') declare stripeAccountId: string;
  @attr('string') declare objectId: string;
  @attr('string') declare objectType: string;
  @attr('number') declare amount: number;

  updateFromPayload(payload: any) {
    this.setProperties({
      objectId: payload.object_id,
      clientSecret: payload.client_secret,
      stripeAccountId: payload.stripe_account_id,
      amount: payload.amount,
    });
  }

  get isSetupIntent() {
    return this.objectType === 'setup_intent';
  }

  get isPaymentIntent() {
    return this.objectType === 'payment_intent';
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'billing/stripe-intent': StripeIntent;
  }
}
