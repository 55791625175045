/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { and, not, or } from '@ember/object/computed';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import {
  StandardAudienceSummaryConfiguration,
  StandardGoalSummaryConfiguration,
  StandardScheduleSummaryConfiguration,
} from 'embercom/objects/content-editor/summaries/standard-summary-configurations';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/banner/summary-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';

export default function generateViewModeConfig(options) {
  let container = options.container;
  let intl = container.lookup('service:intl');
  return ViewModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesViewModeHeaderConfiguration.create({
      moreDropdownConfig: MoreDropdownConfiguration.create({
        hideDuplicateOption: true,
        hideCsvExportOption: false,
        hideTagOption: true,
        hideDeleteOption: true,
        hideVersionsOption: true,
        container,
      }),
      container,
    }),
    headerComponentConfigs: [
      StandardViewModeHeaderConfiguration.create({
        container,
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/banners/preview-button',
        }),
      }),
    ],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: 'Banner',
          icon: 'alignment-reference',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.views'),
          view: 'receipts',
          container,
        }),
        StandardStatTabConfiguration.extend({
          _isVisibleForAction: or(
            'contentEditorService.activeObject.isOpenAURLAction',
            'contentEditorService.activeObject.isProductTourAction',
          ),
          isVisible: and('_isVisibleForAction', 'isNotDraft'),
        }).create({
          label: intl.t('outbound.stats-system.tabs.clicked'),
          view: 'clicks',
          container,
        }),
        StandardStatTabConfiguration.extend({
          isVisible: and('contentEditorService.activeObject.isReactionAction', 'isNotDraft'),
        }).create({
          label: 'Reacted',
          view: 'reactions',
          tabComponent: 'content-editor/tabs/reactions/stat-tab',
          container,
        }),
        StandardStatTabConfiguration.extend({
          _isNotDraft: not('contentEditorService.ruleset.isDraft'),
          isVisible: and('contentEditorService.ruleset.hasGoal', '_isNotDraft'),
        }).create({
          label: intl.t('outbound.stats-system.tabs.goal'),
          view: 'goals',
          container,
        }),
        StandardStatTabConfiguration.extend({
          isVisible: and('contentEditorService.activeObject.isEmailCollectorAction', 'isNotDraft'),
        }).create({
          label: 'Emails collected',
          view: 'collectedEmails',
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [
        ContentSummaryConfiguration.create({ container }),
        StandardAudienceSummaryConfiguration.create({ container }),
        StandardScheduleSummaryConfiguration.create({
          title: 'Frequency and scheduling',
          container,
        }),
        StandardGoalSummaryConfiguration.create({ container }),
      ],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: true,
          noun: 'view',
          verb: 'viewed',
          emptyStateDescription: "As people view your banner, we'll show you when it was seen",
          container,
        }),
      ],
      clicks: [StandardStatListConfiguration.create({ stat: 'click', container })],
      reactions: [StandardStatListConfiguration.create({ stat: 'reaction', container })],
      collectedEmails: [
        StandardStatListConfiguration.create({ stat: 'collectedEmail', container }),
      ],
      goals: [
        StandardStatListConfiguration.create({
          filters: [{ text: 'Hit the goal after clicking', value: stats.click }],
          stat: 'goalSuccess',
          container,
        }),
      ],
    }),
    footerComponentConfigs: [],
  });
}
