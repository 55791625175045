/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { none, or, empty } from '@ember/object/computed';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default Model.extend({
  app_id: attr(),
  body: attr(),
  escaped_body: attr(),
  body_html_safe: attr(),
  created_at: attr(),
  model_id: attr(),
  is_company: attr(),
  last_edited_at: attr(),
  created_by: attr(),
  admin: belongsTo('admin'),
  hasNoAdmin: none('admin'),
  updated_by: belongsTo('admin'),
  new: false,
  bodyHtmlSafe: computed('body_html_safe', function () {
    let body = this.body_html_safe;
    if (body) {
      return sanitizeHtml(this.body_html_safe);
    }
    return '';
  }),
  isDisplayable: or('isLoaded', 'isSaving'),
  noAdminText: 'Added via the Intercom API',
  adminDisplayAsIsEmpty: empty('admin.display_as'),
  authorText: ternaryToProperty('adminDisplayAsIsEmpty', 'noAdminText', 'admin.display_as'),
});
