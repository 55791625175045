/* RESPONSIBLE TEAM: team-tickets-1 */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';

interface EntitiesUnLinkedWireFormat {
  admin_summary: AdminSummaryWireFormat;
  entities_unlinked: UnlinkedEntityWireFormat[];
  unlinked_from: UnlinkedFromEntityWireFormat;
  entities_unlinked_user_summary: UserSummaryWireFormat[];
}

interface UnlinkedEntityWireFormat {
  entity_id: number;
  user_id: string;
  category_id?: number;
  ticket_type_id?: number;
  user_facing_ticket_id?: number;
  ticket_title?: string;
}

interface UnlinkedFromEntityWireFormat {
  entity_id: number;
  ticket_type_id: number;
  user_facing_ticket_id: number;
  ticket_title?: string;
}

export interface UnlinkedEntity {
  entityId: number;
  userId: string;
  categoryId?: number;
  ticketTypeId?: number;
  userFacingTicketId?: number;
  ticketTitle?: string;
}

export interface UnlinkedFromEntity {
  entityId: number;
  ticketTypeId: number;
  userFacingTicketId: number;
  ticketTitle?: string;
}

export default class EntitiesUnLinked implements RenderableData {
  renderableType: RenderableType = RenderableType.EntitiesUnlinked;
  readonly adminSummary: AdminSummary;

  readonly entitiesUnlinked: UnlinkedEntity[];
  readonly unlinkedFrom: UnlinkedFromEntity;
  readonly entitiesUnlinkedUserSummary: UserSummary[] | undefined;

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  constructor(
    adminSummary: AdminSummary,
    entitiesUnlinked: any,
    unlinkedFrom: any,
    entitiesUnlinkedUserSummary: any,
  ) {
    this.adminSummary = adminSummary;
    this.entitiesUnlinked = entitiesUnlinked;
    this.unlinkedFrom = unlinkedFrom;
    this.entitiesUnlinkedUserSummary = entitiesUnlinkedUserSummary;
  }

  get adminOnly() {
    return true;
  }

  get styles() {
    return {
      backgroundColor: 'bg-sky-dark dark:bg-dm-blue-dark',
      textColor: 'text-blue-darkest dark:text-dm-white',
      backgroundColorHover: 'bg-sky-darkest dark:bg-blue-darkest',
    };
  }

  static deserialize(json: EntitiesUnLinkedWireFormat): EntitiesUnLinked {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    let entitiesUnlinked = json.entities_unlinked.map(
      (unlinkedEntity: UnlinkedEntityWireFormat) => {
        return {
          entityId: unlinkedEntity.entity_id,
          userId: unlinkedEntity.user_id,
          userFacingTicketId: unlinkedEntity.user_facing_ticket_id,
          ticketTypeId: unlinkedEntity.ticket_type_id,
          ticketTitle: unlinkedEntity.ticket_title,
        };
      },
    );
    let unlinkedFrom = {
      entityId: json.unlinked_from.entity_id,
      ticketTypeId: json.unlinked_from.ticket_type_id,
      userFacingTicketId: json.unlinked_from.user_facing_ticket_id,
      ticketTitle: json.unlinked_from.ticket_title,
    };
    let entitiesUnlinkedUserSummary = json.entities_unlinked_user_summary
      ? json.entities_unlinked_user_summary.map((summary: UserSummaryWireFormat) =>
          UserSummary.deserialize(summary),
        )
      : undefined;

    return new EntitiesUnLinked(
      adminSummary,
      entitiesUnlinked,
      unlinkedFrom,
      entitiesUnlinkedUserSummary,
    );
  }
}
