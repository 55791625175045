/* RESPONSIBLE TEAM: team-workflows */

import AdminSummary, { type AdminSummaryWireFormat } from './admin-summary';

export interface OperatorWorkflowEventDataWireFormat {
  admin?: AdminSummaryWireFormat;
  wait_started?: WaitStartedWireFormat;
  skip_assignment_action?: SkipAssignmentActionWireFormat;
  workflow_finished?: WorkflowFinishedWireFormat;
  action_data?: ActionDataWireFormat;
  language_override_updated?: LanguageOverrideUpdatedWireFormat;
}

interface WaitStarted {
  durationType: string;
  customDuration: number | null;
}
type WaitStartedWireFormat = KeysToSnakeCase<WaitStarted>;

interface SkipAssignmentAction {
  outboundId: number | undefined;
  outboundType: number;
  outboundName: string;
}
type SkipAssignmentActionWireFormat = KeysToSnakeCase<SkipAssignmentAction>;

interface WorkflowFinished {
  reason: string;
}
type WorkflowFinishedWireFormat = KeysToSnakeCase<WorkflowFinished>;

interface ActionData {
  tagId?: string;
  attributeIdentifier?: string;
  value?: string;
}
type ActionDataWireFormat = KeysToSnakeCase<ActionData>;
interface LanguageOverrideUpdated {
  previousValue: string | null;
  newValue: string;
}
type LanguageOverrideUpdatedWireFormat = KeysToSnakeCase<LanguageOverrideUpdated>;

export default class OperatorWorkflowEventData {
  readonly admin?: AdminSummary;
  readonly waitStarted?: WaitStarted;
  readonly skipAssignmentAction?: SkipAssignmentAction;
  readonly workflowFinished?: WorkflowFinished;
  readonly actionData?: ActionData;
  readonly languageOverrideUpdated?: LanguageOverrideUpdated;

  constructor(
    admin?: AdminSummary,
    waitStarted?: WaitStarted,
    skipAssignmentAction?: SkipAssignmentAction,
    workflowFinished?: WorkflowFinished,
    actionData?: ActionData,
    languageOverrideUpdated?: LanguageOverrideUpdated,
  ) {
    this.admin = admin;
    this.waitStarted = waitStarted;
    this.skipAssignmentAction = skipAssignmentAction;
    this.workflowFinished = workflowFinished;
    this.actionData = actionData;
    this.languageOverrideUpdated = languageOverrideUpdated;
  }

  static deserialize(json: OperatorWorkflowEventDataWireFormat): OperatorWorkflowEventData {
    return new OperatorWorkflowEventData(
      json.admin ? AdminSummary.deserialize(json.admin) : undefined,
      json.wait_started
        ? {
            durationType: json.wait_started.duration_type,
            customDuration: json.wait_started.custom_duration,
          }
        : undefined,
      json.skip_assignment_action
        ? {
            outboundId: json.skip_assignment_action.outbound_id,
            outboundType: json.skip_assignment_action.outbound_type,
            outboundName: json.skip_assignment_action.outbound_name,
          }
        : undefined,
      json.workflow_finished
        ? {
            reason: json.workflow_finished.reason,
          }
        : undefined,
      json.action_data
        ? {
            tagId: json.action_data.tag_id,
            attributeIdentifier: json.action_data.attribute_identifier,
            value: json.action_data.value,
          }
        : undefined,
      json.language_override_updated
        ? {
            previousValue: json.language_override_updated.previous_value,
            newValue: json.language_override_updated.new_value,
          }
        : undefined,
    );
  }
}
