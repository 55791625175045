/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment } from 'ember-data-model-fragments/attributes';
import type Gradient from './gradient';
import tinycolor from 'tinycolor2';
import { tracked } from '@glimmer/tracking';
import { DEFAULT_GLOBAL_OPTIONS } from './collection-card-options';
//@ts-ignore no type declaration available for ember-copy
import { copy } from 'ember-copy';
import { isEmpty } from '@ember/utils';

export enum BackgroundType {
  COLOR = 'color',
  IMAGE = 'image',
  GRADIENT = 'gradient',
}

export default class OutboundContent extends Fragment {
  @tracked backgroundType: BackgroundType = BackgroundType.COLOR;
  @tracked lastSelectedBackgroundColor: string | null = null;

  @attr('string') declare backgroundColor: string | null;
  @attr('string') declare fontColor: string | null;
  @attr('string') declare buttonColor: string | null;
  @attr('string') declare buttonFontColor: string | null;
  @attr('boolean') declare fadeToEdge: boolean;
  @attr('string') declare backgroundImageUrl: string | null;

  @fragment('customization-options/gradient') declare backgroundGradient: Gradient | null;

  get isTextReadable(): boolean {
    if (!this.fontColor || !this.backgroundColor) {
      return true;
    }
    return tinycolor.isReadable(this.backgroundColor, this.fontColor, {
      level: 'AA',
      size: 'small',
    });
  }

  createGradient() {
    let gradient = this.store.createFragment('customization-options/gradient');
    gradient.addStep(this.backgroundColor || this.lastSelectedBackgroundColor);
    gradient.addStep();
    this.backgroundGradient = gradient;
  }

  duplicateGradient(gradient: Gradient | null) {
    if (!gradient) {
      this.removeGradient();
      return;
    }
    this.backgroundGradient = copy(gradient);
  }

  removeGradient() {
    this.backgroundGradient = null;
  }

  removeImage() {
    this.backgroundImageUrl = null;
  }

  selectColor(color: string) {
    this.backgroundColor = color;
    this.lastSelectedBackgroundColor = this.backgroundColor;
  }

  useLastSelectedColor() {
    this.backgroundColor =
      this.lastSelectedBackgroundColor || DEFAULT_GLOBAL_OPTIONS.backgroundColor;
  }

  removeColor() {
    this.backgroundColor = null;
  }

  hasGradient() {
    return !!this.backgroundGradient && this.backgroundGradient.hasSteps;
  }

  clearBackground() {
    this.backgroundColor = null;
    this.backgroundImageUrl = null;
    this.backgroundGradient = null;
  }

  get isBackgroundsEnabled() {
    return !(
      isEmpty(this.backgroundColor) &&
      isEmpty(this.backgroundImageUrl) &&
      isEmpty(this.backgroundGradient)
    );
  }
}
