/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { type BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { type HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { Channel } from 'embercom/models/data/inbox/channels';
import type Router from '@ember/routing/router-service';
import type Macro from 'embercom/objects/inbox/macro';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export enum ComposerPaneType {
  Reply = 'Reply',
  Note = 'Note',
}

export interface ComposerPaneStyles {
  container: string;
}

export enum ReplyChannelType {
  Chat,
  Email,
  Whatsapp,
  SMS,
}

export const MULTI_PARTICIPANT_CHANNELS = [ReplyChannelType.Chat, ReplyChannelType.Email];

export enum OutboundReplyChannelType {
  Chat = ReplyChannelType.Chat,
  Email = ReplyChannelType.Email,
  SMS = ReplyChannelType.SMS,
  Whatsapp = ReplyChannelType.Whatsapp,
}

export const ObjectTypeForReplyChannel: Record<OutboundReplyChannelType, number> = {
  [OutboundReplyChannelType.Chat]: objectTypes.chat,
  [OutboundReplyChannelType.Email]: objectTypes.email,
  [OutboundReplyChannelType.SMS]: objectTypes.sms,
  [OutboundReplyChannelType.Whatsapp]: objectTypes.whatsapp,
};

export interface ReplyChannel {
  readonly type: ReplyChannelType;
  readonly icon: InterfaceIconName;
  readonly nameTranslationKey: string;
  readonly hotkeyId?: HotkeyID;
  readonly feature?: string;
  getSetupUrl?: Function;
}

export const EmailChannel: ReplyChannel = {
  type: ReplyChannelType.Email,
  icon: 'email-filled',
  nameTranslationKey: 'inbox.composer.panes.email',
};

export const ChatChannel: ReplyChannel = {
  type: ReplyChannelType.Chat,
  icon: 'chat-filled',
  nameTranslationKey: 'inbox.composer.panes.chat',
};

export const HybridChannel: ReplyChannel = {
  type: ReplyChannelType.Chat,
  icon: 'chat-filled',
  nameTranslationKey: 'inbox.composer.panes.hybrid',
};

export const WhatsappChannel: ReplyChannel = {
  type: ReplyChannelType.Whatsapp,
  icon: 'whatsapp',
  nameTranslationKey: 'inbox.composer.panes.whatsapp',
  feature: 'whatsapp_integration',
  getSetupUrl: (router: Router, appId: any) => {
    return router.urlFor('apps.app.appstore.app-package', appId, 'whatsapp-fqam');
  },
};

export const FacebookChannel: ReplyChannel = {
  type: ReplyChannelType.Whatsapp,
  icon: 'facebook',
  nameTranslationKey: 'inbox.composer.panes.facebook',
};

export const TwitterChannel: ReplyChannel = {
  type: ReplyChannelType.Whatsapp,
  icon: 'twitter',
  nameTranslationKey: 'inbox.composer.panes.twitter',
};

export const InstagramChannel: ReplyChannel = {
  type: ReplyChannelType.Whatsapp,
  icon: 'instagram',
  nameTranslationKey: 'inbox.composer.panes.instagram',
};

export const SMSChannel: ReplyChannel = {
  type: ReplyChannelType.SMS,
  icon: 'sms',
  nameTranslationKey: 'inbox.composer.panes.sms',
};

export const ReplyChannelMap: Record<Channel, ReplyChannel> = {
  [Channel.Unknown]: HybridChannel,
  [Channel.Desktop]: HybridChannel,
  [Channel.Android]: HybridChannel,
  [Channel.IOS]: HybridChannel,
  [Channel.Email]: EmailChannel,
  [Channel.Twitter]: TwitterChannel,
  [Channel.Facebook]: FacebookChannel,
  [Channel.FacebookMessenger]: FacebookChannel,
  [Channel.Whatsapp]: WhatsappChannel,
  [Channel.Instagram]: InstagramChannel,
  [Channel.PhoneSwitch]: HybridChannel,
  [Channel.SMS]: SMSChannel,
  [Channel.PhoneCall]: HybridChannel,
};

export function replyChannelIsChat(replyChannel?: ReplyChannel): boolean {
  return replyChannel?.type === ReplyChannelType.Chat;
}

export function replyChannelIsEmail(replyChannel?: ReplyChannel): boolean {
  return replyChannel?.type === ReplyChannelType.Email;
}

export function replyChannelIsSms(replyChannel?: ReplyChannel): boolean {
  return replyChannel?.type === ReplyChannelType.SMS;
}

export function replyChannelIsWhatsapp(replyChannel?: ReplyChannel): boolean {
  return replyChannel?.type === ReplyChannelType.Whatsapp;
}

export function replyChannelIsMultiParticipant(replyChannel?: ReplyChannel): boolean {
  return replyChannel?.type !== undefined
    ? MULTI_PARTICIPANT_CHANNELS.includes(replyChannel.type)
    : false;
}

export function channelIsMobile(channel: Channel): boolean {
  return channel === Channel.Android || channel === Channel.IOS;
}

export default interface ComposerPane {
  readonly type: ComposerPaneType;
  readonly styles: ComposerPaneStyles;
  readonly nameTranslationKey: string;
  readonly icon: InterfaceIconName;
  readonly hotkeyId?: HotkeyID;
  readonly feature?: string;

  blocksDoc: BlocksDocument;
  macroActions: Macro['actions'];
  channel?: ReplyChannel;
  getSetupUrl?: Function;

  clear(): void;
}
