/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import numberFormatter from 'embercom/lib/number-formatter';
import {
  humanReadableObjectNames,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/resolution-bot-behavior/edit-mode-generator';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/resolution-bot-behavior/view-mode-generator';
import generateFinNotificationMessages from 'embercom/objects/content-editor/configuration-generators/resolution-bot-behavior/notification-messages-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import { resolutionBotBillingFeature } from 'embercom/components/operator/answer-bot/billing-helper';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.resolutionBotBehavior;
  let app = options.container.lookup('service:appService')?.app;

  let notificationMessages;
  if (app.canUseFinExperience) {
    notificationMessages = generateFinNotificationMessages(options);
  } else {
    notificationMessages = generateNotificationMessages(options);
    notificationMessages.activate = generateResolutionBotBehaviorActivationMessages(
      options.container,
      humanReadableObjectNames[options.objectType],
    );
  }

  let feature = app ? resolutionBotBillingFeature(app) : 'answer_workflows';
  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    notificationMessages,
    requiredFeature: feature,
  });
}

function generateResolutionBotBehaviorActivationMessages(container, humanReadableObjectName) {
  let intl = container.lookup('service:intl');
  return NotificationMessage.extend({
    contentEditorService: service(),
    humanReadableObjectName,
    async confirmation() {
      await this.contentEditorService.ruleset.fetchAudiencePreview();

      let ruleset = this.contentEditorService.ruleset;

      let audienceCount = ruleset.audiencePreview.count;
      let title = intl.t('ai-agent.profiles.config-generator.ready');
      let confirmButtonText = intl.t('ai-agent.profiles.config-generator.set-live');
      let body = intl.t('ai-agent.profiles.config-generator.audience', {
        audienceCount: numberFormatter(audienceCount),
      });

      return { title, body, confirmButtonText };
    },
  }).create({
    success: intl.t('ai-agent.profiles.config-generator.bot-set-live'),
    failure: intl.t('ai-agent.profiles.config-generator.set-live-error'),
    container,
  });
}
