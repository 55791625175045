/* RESPONSIBLE TEAM: team-ai-chatbot */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary from '../admin-summary';
import { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

interface LanguageDetectionDetailsWireFormat {
  fallback_locale?: string;
  admin_summary: AdminSummaryWireFormat;
}

export default class LanguageDetectionDetails implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.LanguageDetectionDetails;
  readonly fallbackLocale?: string;
  readonly adminSummary: AdminSummary;

  get creatingEntity() {
    return {
      type: EntityType.Bot,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  constructor(adminSummary: AdminSummary, fallbackLocale?: string) {
    this.adminSummary = adminSummary;
    this.fallbackLocale = fallbackLocale;
  }

  static deserialize(json: LanguageDetectionDetailsWireFormat): LanguageDetectionDetails {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    return new LanguageDetectionDetails(adminSummary, json.fallback_locale);
  }
}
