/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
// @ts-ignore
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';

export const UNASSIGNED_ID = 0;

const PALETTE = [
  'fd3a57',
  'ffb3c6',
  'fa7080',
  'f0b3fc',
  'c701f1',
  '9401b2',
  '261860',
  '286efa',
  '6b9cff',
  'd3e2fd',
  '8fe3eb',
  '0accac',
  '17c65d',
  '8ae2ad',
  'b8e65c',
  'f7d50b',
  'ffb848',
  'ff8b47',
  '888888',
  'c8c6c4',
  'e5e5e5',
];

export interface WorkloadManagementSettings {
  assignmentLimit?: number;
}

export interface CountMetadata {
  tickets?: number;
  conversations?: number;
}

export interface AdminSummaryWireFormat {
  id: number;
  name: string;
  image_url: string | null;
  is_impersonating?: boolean;
  is_away?: boolean;
  admin_status?: {
    away_mode_enabled?: boolean;
    reassign_conversations?: boolean;
    is_auto_changed?: boolean;
    channel_availability?: string;
  };
  count?: number;
  count_metadata?: {
    tickets?: number;
    conversations?: number;
  };
  email?: string;
  workload_management_settings?: {
    assignment_limit?: number;
  };
  wlm_inbox_priority_level?: number;
  restricted_contexts?: number[];
  removed?: boolean;
  is_operator?: boolean;
}

export class AdminStatus {
  awayModeEnabled: boolean | undefined;
  reassignConversations: boolean | undefined;
  isAutoChanged: boolean | undefined;
  channelAvailability: string | undefined;

  constructor(
    awayModeEnabled: boolean | undefined,
    reassignConversations: boolean | undefined,
    isAutoChanged: boolean | undefined,
    channelAvailability: string | undefined,
  ) {
    this.awayModeEnabled = awayModeEnabled;
    this.reassignConversations = reassignConversations;
    this.isAutoChanged = isAutoChanged;
    this.channelAvailability = channelAvailability;
  }
}

export default class AdminSummary {
  readonly id: number;
  readonly name: string;
  readonly imageURL: string;
  readonly isImpersonating?: boolean;
  readonly isAway?: boolean;
  readonly openCount?: number;
  readonly countMetadata?: CountMetadata;
  readonly email?: string;
  readonly workloadManagementSettings?: WorkloadManagementSettings;
  readonly wlmInboxPriorityLevel?: number;
  readonly restrictedContexts?: number[];
  adminStatus?: AdminStatus;
  readonly removed?: boolean;
  readonly isOperator?: boolean;

  constructor(
    id: number,
    name: string,
    imageURL: string | null,
    isImpersonating?: boolean,
    isAway?: boolean,
    openCount?: number,
    countMetadata?: CountMetadata,
    email?: string,
    workloadManagementSettings?: WorkloadManagementSettings,
    wlmInboxPriorityLevel?: number,
    restrictedContexts?: Array<number>,
    adminStatus?: AdminStatus,
    removed?: boolean,
    isOperator?: boolean,
  ) {
    this.id = id;
    this.name = name;
    this.imageURL = imageURL || DefaultAvatars.admins[128];
    this.isImpersonating = isImpersonating;
    this.isAway = isAway;
    this.openCount = openCount;
    this.countMetadata = countMetadata;
    this.email = email;
    this.workloadManagementSettings = workloadManagementSettings;
    this.wlmInboxPriorityLevel = wlmInboxPriorityLevel;
    this.restrictedContexts = restrictedContexts;
    this.adminStatus = adminStatus;
    this.removed = removed;
    this.isOperator = isOperator;
  }

  static deserialize(json: AdminSummaryWireFormat): AdminSummary {
    let adminStatus = new AdminStatus(
      json.admin_status?.away_mode_enabled,
      json.admin_status?.reassign_conversations,
      json.admin_status?.is_auto_changed,
      json.admin_status?.channel_availability,
    );
    return new AdminSummary(
      json.id,
      json.name,
      json.image_url,
      json.is_impersonating,
      json.is_away,
      json.count,
      {
        tickets: json.count_metadata?.tickets,
        conversations: json.count_metadata?.conversations,
      },
      json.email,
      { assignmentLimit: json.workload_management_settings?.assignment_limit },
      json.wlm_inbox_priority_level,
      json.restricted_contexts,
      adminStatus,
      json.removed,
      json.is_operator,
    );
  }

  static get unassigned() {
    return new AdminSummary(UNASSIGNED_ID, 'Unassigned', null);
  }

  clone() {
    return new AdminSummary(
      this.id,
      this.name,
      this.imageURL,
      this.isImpersonating,
      this.isAway,
      this.openCount,
      this.countMetadata,
      this.email,
      this.workloadManagementSettings,
      this.wlmInboxPriorityLevel,
      this.restrictedContexts,
      this.adminStatus,
      this.removed,
      this.isOperator,
    );
  }

  get translationKey(): string | void {
    if (this.isUnassignedAssignee) {
      return 'inbox.unassigned';
    }
  }

  get isUnassignedAssignee(): boolean {
    return this.id === UNASSIGNED_ID;
  }

  get color(): string {
    return PALETTE[this.id % PALETTE.length];
  }

  get initials(): string {
    return this.name
      .split(' ')
      .map((part) => part[0])
      .join('');
  }

  get firstName(): string {
    return this.name.split(' ')[0];
  }
}
