/* RESPONSIBLE TEAM: team-proactive-support */
interface StatsParams {
  contentId: number;
  contentType: number;
  contentObject: any;
  goal: any;
  uiObjectName: string;
  shortObjectName: string;
  startDate: Date;
  range: any;
}

export default class StatsParameters {
  contentId: number;
  contentType: number;
  contentObject: any;
  goal: any;
  uiObjectName: string;
  shortObjectName: string;
  startDate: Date;
  range: any;

  constructor(inputs: StatsParams) {
    let {
      contentId,
      contentType,
      contentObject,
      goal,
      uiObjectName,
      shortObjectName,
      startDate,
      range,
    } = inputs;
    this.contentId = contentId;
    this.contentType = contentType;
    this.contentObject = contentObject;
    this.goal = goal;
    this.uiObjectName = uiObjectName;
    this.shortObjectName = shortObjectName;
    this.startDate = startDate;
    this.range = range;
  }
}
