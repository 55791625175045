/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Model, { attr, belongsTo } from '@ember-data/model';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { isEqual, sortBy } from 'underscore';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';

export enum StripeMigrationType {
  reactive = 'reactive',
  proactive = 'proactive',
  platform = 'platform',
}

export enum StripeMigrationStatus {
  draft = 'draft',
  scheduled = 'scheduled',
  inProgress = 'in_progress',
  complete = 'complete',
  preFinalised = 'pre_finalised',
  finalised = 'finalised',
  reverted = 'reverted',
}

export default class StripeMigration extends Model {
  @service declare intl: IntlService;

  @belongsTo('billing/customer', { async: false, inverse: null }) declare customer: any; // Customer model is written in legacy Ember which is awkward with TypeScript, so typing as 'any' for now
  @belongsTo('admin', { async: false, inverse: null }) declare initiatedByAdmin: any; // Admin model is written in legacy Ember which is awkward with TypeScript, so typing as 'any' for now
  @belongsTo('billing/migration-seat-configuration', { async: false })
  declare migrationSeatConfiguration: MigrationSeatConfiguration;
  @attr('string') declare migrationStatus: StripeMigrationStatus;
  @attr('date') declare migrationDate: Date;
  @attr('string') declare migrationType: StripeMigrationType;
  @attr('array') declare planIds: number[];
  @attr('array') declare recommendedPlanIds: number[];
  @attr('string') declare priceSetIdentifier: string;
  @attr('boolean') declare isLocked: boolean;
  @attr('boolean') declare isConfirmedByCustomer: boolean;
  @attr('date') declare confirmedAt: Date;

  get isProactive() {
    return this.migrationType === StripeMigrationType.proactive;
  }

  get isReactive() {
    return this.migrationType === StripeMigrationType.reactive;
  }

  get isPending() {
    return (
      this.migrationStatus === StripeMigrationStatus.preFinalised ||
      this.migrationStatus === StripeMigrationStatus.inProgress ||
      this.migrationStatus === StripeMigrationStatus.scheduled ||
      this.migrationStatus === StripeMigrationStatus.finalised
    );
  }

  get formattedMigrationDate() {
    return this.intl.formatDate(this.migrationDate, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  get postMigrationPlanIds() {
    if (isPresent(this.planIds)) {
      return this.planIds;
    }

    return this.recommendedPlanIds || [];
  }

  get optIntoDifferentPlans() {
    return (
      this.isConfirmedByCustomer && !isEqual(sortBy(this.planIds), sortBy(this.recommendedPlanIds))
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'billing/stripe-migration': StripeMigration;
  }
}
