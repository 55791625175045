/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import {
  getPrefixAndIdFromCustomAttributeFilterIdentifier,
  propertyIdentifierPrefixes,
} from 'embercom/lib/reporting/custom/filter-helpers';
import { REVERSE_CARDINALITY_NAME_MAP } from 'embercom/models/custom-objects/constants/relationship-cardinalities';
import { ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP } from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import {
  CONVERSATION_OBJECT_TYPE_IDENTIFIER,
  CONVERSATION_OBJECT_TYPE_NAME,
} from 'embercom/models/custom-objects/constants/object-types';
import { isPresent } from '@ember/utils';

export default class ConversationAttributeDescriptor extends Model {
  @service store;
  @service appService;
  @service intl;
  @service customObjectsService;

  @attr('string') name;
  @attr('boolean', { defaultValue: true }) templatable;
  @attr('string') dataType;
  @attr('string') description;
  @hasMany('conversation-attributes/list-option', { async: false }) listOptions;
  @hasMany('conversation-attributes/condition', { inverse: 'descriptorId', async: false })
  conditions;
  @attr('boolean') multiline;
  @attr('boolean') allowMultipleValues;
  @attr('date') createdAt;
  @attr('date') archivedAt;
  @attr('boolean', { defaultValue: false }) archived;
  @attr('boolean', { defaultValue: false }) required;
  @attr('boolean', { defaultValue: false }) requiredToCreate;
  @attr('boolean', { defaultValue: false }) visibleOnCreate;
  @attr('boolean', { defaultValue: false }) visibleToUsers;
  @attr('boolean', { defaultValue: false }) requiredToCreateForUsers;
  @attr('boolean', { defaultValue: false }) isBuiltIn;
  @attr('boolean', { defaultValue: true }) isListedAsAttribute;
  @attr('boolean') isAlwaysVisible;
  @attr('boolean', { defaultValue: false }) systemDefined;
  @attr('boolean', { defaultValue: true }) editable;
  @attr('boolean', { defaultValue: false }) hideFromAutomation;
  @attr('boolean', { defaultValue: false }) hideFromReporting;
  @attr('boolean', { defaultValue: false }) isClassificationAttribute;
  @attr('string', { defaultValue: null }) icon;

  @attr('string', { defaultValue: 'conversation' }) category;
  @attr('string') domainObjectTypeId;
  @attr('string', { defaultValue: null }) ticketTypeId;
  @attr('number', { defaultValue: 0 }) order;
  @attr() visibleToTeamIds;
  @attr('string') relatedObjectAttributeName;
  @attr('string') relatedObjectAttributeDescription;
  @attr() entityTypes;

  @belongsTo('admin', { async: false }) archivedByAdmin;
  @belongsTo('custom-objects/relationship', { async: false }) relationship;
  @belongsTo('objects/reference', { async: false }) reference;

  async save() {
    await super.save(...arguments);
    this._clearUnsavedListOptions();
  }

  _clearUnsavedListOptions() {
    this.listOptions.filterBy('isNew').forEach((option) => this.store.unloadRecord(option));
  }

  get identifier() {
    return `conversation.custom_attribute.${this.id}`;
  }

  get objectTypeIdentifier() {
    return CONVERSATION_OBJECT_TYPE_IDENTIFIER;
  }

  get objectTypeName() {
    return CONVERSATION_OBJECT_TYPE_NAME;
  }

  get unArchivedListOptions() {
    return this.listOptions.filter((option) => !option.archived);
  }

  get isSelectionType() {
    return this.dataType === 'list' || this.dataType === 'boolean';
  }

  getDisplayableValue(value) {
    if (this.dataType === 'list') {
      return this.listOptions.find((o) => o.id === value).label;
    }
    if (this.dataType === 'boolean') {
      if (value === 'true') {
        value = true;
      } else if (value === 'false') {
        value = false;
      } else if (typeof value === 'string') {
        value = parseInt(value, 10);
      }
      return value ? 'True' : 'False';
    }
    return value;
  }

  get isRelationshipDataType() {
    return this.dataType === ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP;
  }

  get displayableDataType() {
    switch (this.dataType) {
      case 'string':
        if (this.multiline) {
          return this.intl.t('settings.conversation-attributes.descriptor.text-multiline');
        } else {
          return this.intl.t('settings.conversation-attributes.descriptor.text');
        }
      case 'integer':
        return this.intl.t('settings.conversation-attributes.descriptor.number');
      case 'decimal':
        return this.intl.t('settings.conversation-attributes.descriptor.decimal');
      case 'list':
        return this.intl.t('settings.conversation-attributes.descriptor.list');
      case 'boolean':
        return this.intl.t('settings.conversation-attributes.descriptor.true-false');
      case 'datetime':
        return this.intl.t('settings.conversation-attributes.descriptor.date-time');
      case 'relationship':
        return this.intl.t('settings.conversation-attributes.descriptor.reference');
      case 'files':
        return this.intl.t('settings.conversation-attributes.descriptor.files');
      default:
        return this.dataType;
    }
  }

  get topTeam() {
    if (this.isVisibilityLimited && this.existingAndVisibleTeamIds !== undefined) {
      return this.existingAndVisibleTeamIds
        .map(
          (id) =>
            `${this.appService.app.teams.find((team) => team.id === id).avatar_emoji} ${
              this.appService.app.teams.find((team) => team.id === id).name
            }`,
        )
        .slice(0, 1);
    }
    return [this.intl.t('settings.conversation-attributes.descriptor.everyone')];
  }

  get remainingTeams() {
    if (!this.existingAndVisibleTeamIds) {
      return '';
    }
    let remainingTeams = this.existingAndVisibleTeamIds.length - 1;
    return remainingTeams > 0 ? ` +${remainingTeams} more` : '';
  }

  get nameLowercase() {
    return this.displayName.toLowerCase();
  }

  get displayName() {
    if (this.isBuiltIn) {
      return this.intl.t(`settings.ticket-data.default-fields.${this.name}`);
    }
    return this.name;
  }

  get isEditable() {
    return !!this.editable;
  }

  get isDeletable() {
    return !this.isBuiltIn;
  }

  get isReorderable() {
    return !this.isBuiltIn;
  }

  get existingAndVisibleTeamIds() {
    return this.visibleToTeamIds?.filter(
      (id) => this.appService.app.teams.find((team) => team.id === id) !== undefined,
    );
  }

  get isVisibilityLimited() {
    return (
      this.existingAndVisibleTeamIds !== undefined && this.existingAndVisibleTeamIds.length !== 0
    );
  }

  isVisibleToTeam(teamAssigneeId) {
    if (!this.isVisibilityLimited) {
      return true;
    }

    if (teamAssigneeId === undefined || teamAssigneeId === '0') {
      return !this.isVisibilityLimited;
    }

    return this.existingAndVisibleTeamIds.includes(teamAssigneeId.toString());
  }

  get isSourceRelationshipAttribute() {
    return (
      isPresent(this.relationship) &&
      this.objectTypeIdentifier === this.relationship.get('sourceObjectTypeIdentifier') &&
      this.id === this.relationship.get('sourceAttributeDescriptorId')
    );
  }

  get isDestinationRelationshipAttribute() {
    return (
      isPresent(this.relationship) &&
      this.objectTypeIdentifier === this.relationship.get('destinationObjectTypeIdentifier') &&
      this.id === this.relationship.get('destinationAttributeDescriptorId')
    );
  }

  get relatedAttribute() {
    if (this.isSourceRelationshipAttribute) {
      return this.relationship.get('destinationAttributeDescriptor');
    } else if (this.isDestinationRelationshipAttribute) {
      return this.relationship.get('sourceAttributeDescriptor');
    }
  }

  get relatedObjectTypeIdentifier() {
    if (this.isSourceRelationshipAttribute) {
      return this.relationship.get('destinationObjectTypeIdentifier');
    } else if (this.isDestinationRelationshipAttribute) {
      return this.relationship.get('sourceObjectTypeIdentifier');
    }
  }

  get referencedObjectTypeIdentifier() {
    return this.reference?.referencedObjectTypeIdentifier;
  }

  get referencedObjectType() {
    if (this.isRelationshipDataType && isPresent(this.reference)) {
      return this.customObjectsService.findObjectTypeByIdentifier(
        this.reference.referencedObjectTypeIdentifier,
      );
    }
  }

  get cardinality() {
    let cardinality = this.relationship?.cardinality;
    if (this.relationship?.destinationAttributeDescriptorId === this.id) {
      cardinality = REVERSE_CARDINALITY_NAME_MAP[cardinality];
    }
    return cardinality;
  }

  get requiredTicketText() {
    if (this.requiredToCreate) {
      return this.intl.t('settings.conversation-attributes.required-to-create-ticket');
    } else {
      return this.intl.t('settings.conversation-attributes.not-required-ticket');
    }
  }

  get isTicketDescriptor() {
    return this.ticketTypeId !== null && this.ticketTypeId !== undefined;
  }

  get isCreatedByDescriptor() {
    return this.identifier === 'conversation.custom_attribute.created_by_teammate_id';
  }

  static peekByIdentifier(identifier) {
    let [prefix, id] = getPrefixAndIdFromCustomAttributeFilterIdentifier(identifier);
    if (prefix !== propertyIdentifierPrefixes.customConversationAttribute || !id) {
      return null;
    }

    // get the model from the store. if it's not there then do a findAll and return
    // a placeholder (which will be automatically updated when the findAll completed)
    // this allow us to have a reactive UI without complex loading logic within the each component

    let store = getEmberDataStore();
    let result = store.peekRecord('conversation-attributes/descriptor', id);
    if (!result) {
      store.findAll('conversation-attributes/descriptor', {
        reload: false,
        backgroundReload: false,
      });
      store.pushPayload({ 'conversation-attributes/descriptor': { id, name: 'Unknown' } });
      result = store.peekRecord('conversation-attributes/descriptor', id);
    }
    return result;
  }

  static peekOrFindById(id) {
    let store = getEmberDataStore();

    return store.hasRecordForId('conversation-attributes/descriptor', id)
      ? store.peekRecord('conversation-attributes/descriptor', id)
      : store.findRecord('conversation-attributes/descriptor', id);
  }

  static peekAllAndMaybeLoad() {
    let store = getEmberDataStore();
    let records = store.peekAll('conversation-attributes/descriptor');

    if (!records.length) {
      records = store.findAll('conversation-attributes/descriptor');
    }
    return records;
  }

  static peekAllWithoutTypes() {
    let store = getEmberDataStore();
    let descriptors = store.peekAll('conversation-attributes/descriptor');
    let types = store.peekAll('domain-objects/type'); // what does this return?

    // filter out polluted type descriptors from the main CvCDA descriptor list
    let typeDescriptorIds = types
      .toArray()
      .flatMap((t) => t.descriptors.toArray())
      .map((d) => d.id);

    return descriptors.reject((d) => typeDescriptorIds.includes(d.id));
  }

  static peekAllRequired(teamAssigneeId) {
    return ConversationAttributeDescriptor.peekAllWithoutTypes().filter(
      (descriptor) =>
        !descriptor.isTicketDescriptor &&
        !descriptor.archived &&
        descriptor.required &&
        descriptor.isVisibleToTeam(teamAssigneeId),
    );
  }
}
