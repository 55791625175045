/* RESPONSIBLE TEAM: team-ai-chatbot */
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';

export default StandardSummaryConfiguration.extend({
  init(inputs) {
    this._super({
      title: inputs.title,
      component: 'content-editor/summaries/custom/resolution-bot-behavior/answers/summary',
      isVisible: true,
    });
  },
});
