/* RESPONSIBLE TEAM: team-help-desk-experience */

import { isEmpty } from '@ember/utils';
import { TrackedObject, tracked } from 'tracked-built-ins';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import { isObject } from 'underscore';
import moment from 'moment-timezone';

export const TEAM_PREDICATE_ID = 'conversation.team_assignee_id';
export const TEAMMATE_PREDICATE_ID = 'conversation.admin_assignee_id';

export type PredicateAbsoluteDate = {
  year: number;
  month: number;
  day: number;
};

export type BooleanComparison = 'true' | 'false';

export type Comparison =
  | 'eq'
  | 'in'
  | 'gt'
  | 'lt'
  | 'known'
  | 'unknown'
  | 'contains'
  | BooleanComparison;

export function toAbsoluteDate(date: moment.Moment): PredicateAbsoluteDate {
  return {
    year: date.year(),
    month: date.month() + 1,
    day: date.date(),
  };
}

export function fromAbsoluteDate(date: PredicateAbsoluteDate): moment.Moment {
  return moment({
    year: date.year,
    month: date.month - 1,
    day: date.day,
  });
}

export type TicketStatePredicate = {
  identifier: 'ticket-state' | 'ticket-category';
  type: 'string';
  attribute: string;
  comparison: 'eq';
  value: string;
};

export type SimplePredicate =
  | {
      identifier: 'teammate' | 'user' | 'linked-to-ticket' | 'company';
      type: string;
      attribute: string;
      comparison: 'eq' | 'in' | 'gt' | 'lt' | 'true' | 'false';
      value: string;
    }
  | {
      identifier: 'state';
      type: 'integer';
      attribute: string;
      comparison: 'eq';
      value: number;
    }
  | TicketStatePredicate
  | {
      identifier: 'ticket-category';
      type: 'string';
      attribute: string;
      comparison: 'eq';
      value: string;
    }
  | {
      identifier: 'conversation-type';
      type: 'integer';
      attribute: string;
      comparison: 'known' | 'unknown';
      value: null;
    }
  | {
      identifier: 'ticket-type';
      type: 'integer';
      attribute: string;
      comparison: 'known' | 'unknown';
      value: null;
    }
  | {
      identifier: 'ticket-title' | 'ticket-description';
      type: string;
      attribute: string;
      comparison: 'contains';
      value: string;
    }
  | {
      identifier: 'ticket-id';
      type: string;
      attribute: string;
      comparison: 'eq';
      value: string;
    }
  | {
      identifier: 'cvda-date-time';
      type: 'datetime';
      attribute: string;
      comparison: 'gt' | 'lt';
      value: {
        value: string;
        unit: 'days' | 'hours' | 'minutes';
        direction: 'past';
      };
    };

export type DateRangePredicate = {
  identifier: 'date-range-start' | 'date-range-end';
  type: string;
  attribute: string;
  comparison: 'eq' | 'in' | 'gt' | 'lt';
  value: ReturnType<typeof toAbsoluteDate>;
};

export type NestedPredicate = {
  identifier:
    | 'tags'
    | 'team'
    | 'ticket-title-description'
    | 'conversation-topic'
    | 'conversation-attribute'
    | 'cvda-date-time';
  type: 'and' | 'or';
  predicates: Omit<SimplePredicate, 'identifier'>[];
};

export type Predicate = SimplePredicate | DateRangePredicate | NestedPredicate;

export default class PredicateGroup {
  @tracked predicates: Predicate[] = [];

  constructor(predicates: Predicate[] = []) {
    this.predicates = predicates;
  }

  static from(filters: string) {
    let group = new PredicateGroup();
    if (isEmpty(filters)) {
      return group;
    }

    let decoded = B64FilterParamEncoder.b64Decode(filters);
    if (!decoded) {
      return group;
    }

    let serialized: unknown;

    try {
      serialized = JSON.parse(decoded) as unknown;
    } catch {
      return group;
    }

    if (!isObject(serialized) || !('predicates' in serialized)) {
      return group;
    }

    group.predicates = serialized.predicates as Predicate[];
    return group;
  }

  add(predicate: Predicate) {
    this.predicates = [...this.predicates, new TrackedObject(predicate)];
  }

  remove(identifier: string) {
    this.predicates = this.predicates.filter((p) => p.identifier !== identifier);
  }

  get isEmpty() {
    return this.predicates.length === 0;
  }

  findPredicate<
    T extends SimplePredicate | NestedPredicate | DateRangePredicate | TicketStatePredicate,
  >(identifier: T['identifier']) {
    return this.predicates.find((p): p is T => p.identifier === identifier);
  }

  toB64() {
    if (this.isEmpty) {
      return '';
    }

    return (
      B64FilterParamEncoder.b64Encode(
        JSON.stringify({
          predicates: this.predicates,
        }),
      ) ?? ''
    );
  }
}
