/* RESPONSIBLE TEAM: team-product-exploration */

import { tracked } from '@glimmer/tracking';
import { setOwner } from '@ember/application';
import type ApplicationInstance from '@ember/application/instance';
import { cached } from 'tracked-toolbox';
import fuzzysort from 'fuzzysort';
import latinize from 'latinize';
import Action from './action';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import scrollIntoViewIfNotVisible from 'embercom/lib/scroll-into-view-if-not-visible';
import { isPresent } from '@ember/utils';

// Bump synonyms down in priority
export const SYNONYM_MATCH_PENALTY = 6;

// Bump partial matches down in priority
export const PARTIAL_MATCH_PENALTY = 3;

export const HIGHLIGHT_SPAN_OPEN =
  '<span data-test-highlight class="rounded bg-yellow font-semibold text-gray-dark bg-opacity-10">';

export default class NavigationAction extends Action {
  @service declare appService: any;

  @tracked parent?: NavigationAction = undefined;
  @tracked children: Array<NavigationAction> = [];

  @service declare router: RouterService;

  component = 'global-search/rows/navigation-action';

  id: string;
  name: string;
  synonyms?: Array<string>;
  route: string;
  routeParams?: Record<string, string>;
  featureFlags: Array<string> = [];
  hideOnFeatureFlags: Array<string> = [];

  constructor(
    owner: ApplicationInstance,
    parent: Action | undefined,
    inputs: {
      id: string;
      name: string;
      synonyms: Array<string>;
      route: string;
      routeParams?: Record<string, string>;
      featureFlags: Array<string>;
      hideOnFeatureFlags: Array<string>;
    },
  ) {
    super(owner, parent);

    let { id, name, synonyms, route, featureFlags, routeParams, hideOnFeatureFlags } = inputs;
    setOwner(this, owner);

    this.id = id;
    this.name = name;
    this.synonyms = synonyms;
    this.route = route;
    this.routeParams = routeParams;
    this.featureFlags = featureFlags;
    this.hideOnFeatureFlags = hideOnFeatureFlags;
  }

  @cached
  get isEnabled(): boolean {
    if (this.hideOnFeatureFlags?.length > 0) {
      let hasHiddenFeatures = this.hideOnFeatureFlags.some((flag) =>
        this.appService.app.canUseFeature(flag),
      );
      if (hasHiddenFeatures) {
        return false;
      }
    }
    if (this.featureFlags.length === 0) {
      return this.parent?.isEnabled ?? true;
    } else {
      // Should also use workspace?
      let hasFeatures = this.featureFlags.every((flag) => this.appService.app.canUseFeature(flag));
      let parentHasFeatures = this.parent?.isEnabled ?? true;
      return hasFeatures && parentHasFeatures;
    }
  }

  get priority() {
    let penalty = 0;

    if (this.termMatches.synonymMatches && !this.termMatches.nameMatches) {
      penalty += SYNONYM_MATCH_PENALTY;
    } else if (this.termMatches.fullWordMatchCount === 0) {
      penalty += PARTIAL_MATCH_PENALTY;
    }

    return this.initialMatchDepth + penalty;
  }

  onQueryChange(_query: string): void {}

  @cached
  get matches() {
    return this.nameMatchesOrImmediateParentMatches || this.termMatches.synonymMatches;
  }

  @cached
  get termMatches(): {
    nameMatches: boolean;
    fullWordMatchCount: number;
    synonymMatches: boolean;
    highlightedMatch?: string;
  } {
    let query = this.currentQuery.toLocaleLowerCase();
    let fullWordMatchCount = 0;

    let fuzzyResult = fuzzysort.go(latinize(query), [fuzzysort.prepare(latinize(this.fullName))], {
      threshold: -1000,
      limit: Infinity,
    });

    let highlightedMatch;
    let nameMatches = fuzzyResult.total > 0;

    if (nameMatches) {
      highlightedMatch =
        fuzzysort.highlight(fuzzyResult[0], HIGHLIGHT_SPAN_OPEN, '</span>') ?? undefined;

      // Count the number of full word matches
      let queryWords = query.split(/\s+/);
      let nameWords = this.fullName.toLocaleLowerCase().split(/\s+/);

      for (let queryWord of queryWords) {
        if (nameWords.includes(queryWord)) {
          fullWordMatchCount++;
        }
      }
    }

    let synonymMatches =
      this.synonyms?.some((synonym) => synonym.toLocaleLowerCase().includes(query)) ?? false;

    return {
      nameMatches,
      fullWordMatchCount,
      synonymMatches,
      highlightedMatch,
    };
  }

  // If this action does not match directly, but it's immediate parent does then we still want to show it.
  // This allows us to do progressive disclosure of the navigation tree as a user drills down.
  @cached
  get nameMatchesOrImmediateParentMatches(): boolean {
    return (this.hasMatchAtDepth || this.parent?.hasMatchAtDepth) ?? false;
  }

  // Check where exactly the match is in the breadcrumbs
  @cached
  get hasMatchAtDepth(): boolean {
    return this.highlightedBreadcrumbs[this.depth].includes('</span>');
  }

  @cached
  get initialMatchDepth(): number {
    if (this.parent?.termMatches.nameMatches) {
      return this.parent.initialMatchDepth;
    } else {
      return this.depth;
    }
  }

  // We always search against the full name of the item in the heirarchy
  // as that's how they're displayed in the UI, e.g. "Settings > General > Change Timezone"
  @cached
  get fullName() {
    return this.breadcrumbs.join('\t');
  }

  @cached
  get breadcrumbs(): Array<string> {
    return [...(this.parent?.breadcrumbs ?? []), this.name];
  }

  @cached
  get highlightedBreadcrumbs(): Array<string> {
    return this.termMatches.highlightedMatch?.split('\t') ?? this.breadcrumbs;
  }

  async onSelect() {
    if (isPresent(this.routeParams)) {
      this.router.transitionTo(this.route, { queryParams: { ...this.routeParams } });
    } else {
      this.router.transitionTo(this.route);
    }
  }

  didBecomeActive(): void {
    let rowElement = document.querySelector(`#${this.interfaceId}`);
    if (rowElement) {
      scrollIntoViewIfNotVisible(document.querySelector(`#${this.interfaceId}`));
    }
  }
}
