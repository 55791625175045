/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { not, readOnly, alias } from '@ember/object/computed';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { isAny } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';
import { userTypeComputedProperty } from './utils/userTypeComputedProperty';

export default Model.extend({
  defaultLocale: attr(),
  availableLocales: fragmentArray('settings/messenger-locale'),
  supportedLocales: fragmentArray('settings/messenger-locale'),
  welcomeMessagesTooLong: isAny('supportedLocales', 'welcomeMessageTooLong'),
  isValid: not('welcomeMessagesTooLong'),
  hasUnsavedChanges: readOnly('hasDirtyAttributes'),
  conversationalIntroduction: attr(),
  isConversationalSettingsUpdate: attr('boolean', { defaultValue: false }),

  messengerSettingsTargetUserProvider: service(),
  targetUserType: alias('messengerSettingsTargetUserProvider.targetUserType'),

  conversationalGreeting: computed(
    'defaultLocale',
    'supportedLocales.@each.{conversationalGreeting,localeId}',
    function () {
      let locale = this.defaultLocale;

      return this.supportedLocales
        .filterBy('localeId', locale)
        .get('firstObject.conversationalGreeting');
    },
  ),

  defaultGreeting: computed(
    'defaultLocale',
    'supportedLocales.@each.{localeId,greeting}',
    function () {
      let locale = this.defaultLocale;

      return this.supportedLocales.filterBy('localeId', locale).get('firstObject.greeting');
    },
  ),
  defaultMobileGreeting: computed(
    'defaultLocale',
    'supportedLocales.@each.{localeId,mobileGreeting}',
    function () {
      let locale = this.defaultLocale;

      return this.supportedLocales.filterBy('localeId', locale).get('firstObject.mobileGreeting');
    },
  ),
  targetedGreeting: userTypeComputedProperty(
    'targetUserType',
    'defaultGreeting',
    'defaultGreeting',
    'defaultMobileGreeting',
  ),

  defaultGreetingSubtitle: computed(
    'defaultLocale',
    'supportedLocales.@each.{localeId,greetingSubtitle}',
    function () {
      let locale = this.defaultLocale;

      return this.supportedLocales.filterBy('localeId', locale).get('firstObject.greetingSubtitle');
    },
  ),
  defaultMobileGreetingSubtitle: computed(
    'defaultLocale',
    'supportedLocales.@each.{localeId,mobileGreetingSubtitle}',
    function () {
      let locale = this.defaultLocale;

      return this.supportedLocales
        .filterBy('localeId', locale)
        .get('firstObject.mobileGreetingSubtitle');
    },
  ),
  targetedGreetingSubtitle: userTypeComputedProperty(
    'targetUserType',
    'defaultGreetingSubtitle',
    'defaultGreetingSubtitle',
    'defaultMobileGreetingSubtitle',
  ),

  defaultWelcomeMessage: computed(
    'defaultLocale',
    'supportedLocales.@each.{localeId,userWelcomeMessage}',
    function () {
      let locale = this.defaultLocale;

      return this.supportedLocales
        .filterBy('localeId', locale)
        .get('firstObject.userWelcomeMessage');
    },
  ),

  defaultLanguageName: computed(
    'defaultLocale',
    'supportedLocales.@each.{localeId,name}',
    function () {
      let locale = this.defaultLocale;

      return this.supportedLocales.filterBy('localeId', locale).get('firstObject.name');
    },
  ),

  permittedLocales: computed('defaultLocale', 'supportedLocales.@each.isPermitted', function () {
    let defaultLocale = this.supportedLocales.findBy('localeId', this.defaultLocale);
    let permittedLocales = this.supportedLocales.filterBy('isPermitted', true);
    return permittedLocales.concat(defaultLocale);
  }),

  refresh() {
    this.rollbackAttributes();
  },
});
