/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr, belongsTo } from '@ember-data/model';

export default class Condition extends Model {
  @attr('string') declare controllingDescriptorId: number;
  @attr('string') declare controllingListOptionId: string;
  @attr('array') declare descriptorListOptionIds: string[];

  @belongsTo('conversation-attributes/descriptor', { inverse: 'conditions', async: false })
  descriptorId: any;
}
