/* RESPONSIBLE TEAM: team-customer-data-platform */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import {
  ONE_TO_ONE,
  ONE_TO_MANY,
  MANY_TO_ONE,
} from 'embercom/models/custom-objects/constants/relationship-cardinalities';
import {
  USER_OBJECT_TYPE_IDENTIFIER,
  CONVERSATION_OBJECT_TYPE_IDENTIFIER,
} from 'embercom/models/custom-objects/constants/object-types';
import AttributeDescriptor from 'embercom/models/objects/attribute-descriptor';
import Attribute from 'embercom/models/attribute';
import type Store from '@ember-data/store';

export default class CustomObjectsRelationship extends Model {
  @attr('string') declare sourceObjectTypeIdentifier: string;
  @attr('string') declare sourceAttributeDescriptorId: string;
  @attr('string') declare destinationObjectTypeIdentifier: string;
  @attr('string') declare destinationAttributeDescriptorId: string;
  @attr('string') declare cardinality: string;
  @attr('boolean') declare archived: boolean;
  @attr('date') declare archivedAt?: Date;

  @service declare store: Store;
  @service declare appService: any;
  @service declare attributeService: any;
  @service declare customObjectsService: any;
  @service declare modelDataCacheService: any;

  get oneToOne() {
    return this.cardinality === ONE_TO_ONE;
  }

  get oneToMany() {
    return this.cardinality === ONE_TO_MANY;
  }

  get manyToOne() {
    return this.cardinality === MANY_TO_ONE;
  }

  get sourceAttributeDescriptor() {
    return this._findAttributeByObjectType(
      this.sourceObjectTypeIdentifier,
      this.sourceAttributeDescriptorId,
    );
  }

  get destinationAttributeDescriptor() {
    return this._findAttributeByObjectType(
      this.destinationObjectTypeIdentifier,
      this.destinationAttributeDescriptorId,
    );
  }

  _findAttributeByObjectType(objectTypeIdentifier: string, attributeId: string) {
    if (objectTypeIdentifier === USER_OBJECT_TYPE_IDENTIFIER) {
      return Attribute.peekOrFindByCdaId(
        this.store,
        this.modelDataCacheService,
        this.appService.app,
        attributeId,
      );
    } else if (objectTypeIdentifier === CONVERSATION_OBJECT_TYPE_IDENTIFIER) {
      return ConversationAttributeDescriptor.peekOrFindById(attributeId);
    } else if (
      this.customObjectsService.customObjectTypes.mapBy('identifier').includes(objectTypeIdentifier)
    ) {
      return AttributeDescriptor.peekOrFindById(attributeId);
    }
  }
}
