/* RESPONSIBLE TEAM: team-ai-chatbot */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';

export default class Cluster extends Model {
  @belongsTo('custom-answers/clusters/example', { async: false }) archetype;
  @hasMany('custom-answers/clusters/example', { async: false }) examples;
  @attr('string') languageAlpha2;

  get analyticsData() {
    return {
      object: 'suggested_answer_cluster',
      language_code: this.languageAlpha2,
      example_count: this.examples.length,
    };
  }
}
