/* RESPONSIBLE TEAM: team-ai-chatbot */
import PanelConfiguration from 'embercom/objects/content-editor/panel-configuration';
import PreHandoverHeaderConfiguration from 'embercom/objects/content-editor/panels/custom/ai-agent-profile/pre-handover/header-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import { assert } from '@ember/debug';
import { contentEditors } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class PreHandoverPanelConfiguration extends PanelConfiguration {
  @service declare contentEditorService: $TSFixMe;

  // @ts-expect-error
  init(inputs) {
    // The container gives us access to Services in these objects. Pass `container: getOwner(this)` as an
    // input when generating these configuration objects.
    assert('You must provide the application container in your panel inputs', inputs.container);
    // @ts-expect-error this is all pre-Octane syntax, TS is not happy
    let header = PreHandoverHeaderConfiguration.create({ container: inputs.container });
    let body = ComponentConfiguration.create({
      // @ts-expect-error
      component: 'content-editor/panels/custom/ai-agent-profile/pre-handover/body',
    });
    super.init({
      name: 'custom/ai-agent-profile/pre-handover',
      header,
      body,
      supportedEditors: [contentEditors.standalone],
    });
  }

  // @ts-expect-error
  get isDisabled() {
    return !this.contentEditorService.activeObject.modeEligibleForGenericTriage;
  }
}
