/* RESPONSIBLE TEAM: team-data-interop */
import Model, { attr } from '@ember-data/model';

export default class InsertableAction extends Model {
  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr('string') declare request_attribute_identifiers: string;
  @attr('string') declare response_attribute_identifiers: string;
  @attr('string') declare created_by: string;
  @attr('string') declare appPackageCode: string;
  @attr('boolean') declare isTemplate: boolean;
  @attr('string') declare url: string;

  // Custom object inputs come with this format: https://github.com/intercom/intercom/blob/055b02f2c89cbb4a1fa81d2ec720924e794264d7/app/lib/custom_objects/commands/related_objects_context.rb#L55
  // For example: conversation.related_objects.<relationship id>.custom_objects.<custom object name>.<id>
  // All other attributes return their normal identifiers
  get requestAttributeIdentifiersArray() {
    return this.request_attribute_identifiers.split(',');
  }
  // Custom object outputs come with this format: custom_object_attribute.<id>
  // All other attributes return their normal identifiers
  get responseAttributeIdentifiersArray() {
    return this.response_attribute_identifiers.split(',');
  }

  get hasInputs() {
    return this.request_attribute_identifiers && this.request_attribute_identifiers.length > 0;
  }

  get hasOutputs() {
    return this.response_attribute_identifiers && this.response_attribute_identifiers.length > 0;
  }
}
