/* RESPONSIBLE TEAM: team-tickets-1 */
import AdminSummary, { type AdminSummaryWireFormat, AdminStatus } from './admin-summary';
import {
  PRICING_5_X_LITE_SEAT_TYPE,
  COLLABORATOR_SEAT_TYPE,
  type ALL_SEAT_TYPES,
} from 'embercom/lib/settings/seats/constants';

export enum ConversationAccessType {
  All = 'all',
  AllExcept = 'all_except',
  OnlyThem = 'only_them',
  OnlyThemOrTheirTeams = 'only_them_or_their_teams',
}

type SeatType = (typeof ALL_SEAT_TYPES)[number];

export interface AdminWithPermissionsWireFormat extends AdminSummaryWireFormat {
  team_ids: Array<number>;
  permissions: {
    conversation_access: {
      access_type?: ConversationAccessType;
      assignee_blocked_list?: Array<number>;
      include_unassigned?: boolean;
    };
    has_inbox_access: boolean;
    manage_teammates: boolean;
    teammates__away_mode__change: boolean;
    inbox__conversation_transcripts__export: boolean;
    manage_tags: boolean;
    manage_saved_replies: boolean;
    has_full_access: boolean;
    manage_inbox_views: boolean;
    access_real_time_dashboard: boolean;
    reassign_conversations: boolean;
    redact_conversation_parts: boolean;
    send_from_custom_addresses: boolean;
    access_workspace_settings: boolean;
    reply_to_inbound_conversations: boolean;
    access_contacts: boolean;
    access_billing_settings: boolean;
    can_access_all_conversations: boolean;
    calling__listening_and_barging: boolean;
    manage_knowledge_base_content: boolean;
  };
  locale: string;
  seats: Array<SeatType | undefined>;
  admin_status?: {
    away_mode_enabled: boolean;
    reassign_conversations: boolean;
    is_auto_changed: boolean;
    channel_availability: string;
  };
  count?: number;
  inbox_2_opt_in?: boolean;
  copilot_waitlist_opt_in: boolean;
  is_operator?: boolean;
}

type ConversationAccess = {
  accessType?: ConversationAccessType;
  assigneeBlockedList?: Array<number>;
  includeUnassigned?: boolean;
};

export class AdminPermissions {
  conversationAccess: ConversationAccess;
  hasInboxAccess: boolean;
  canManageTeammates: boolean;
  canChangeAwayMode: boolean;
  canManageTags: boolean;
  canManageMacros: boolean;
  hasFullAccess: boolean;
  canManageInboxViews: boolean;
  canInboxConversationTranscriptsExport: boolean;
  canAccessRealTimeDashboard: boolean;
  canReassignConversations: boolean;
  canRedactConversationParts: boolean;
  canSendFromCustomAddresses: boolean;
  canAccessWorkspaceSettings: boolean;
  canReplyToInboundConversations: boolean;
  canAccessContacts: boolean;
  canAccessBillingSettings: boolean;
  canAccessAllConversations: boolean;
  canListenAndBargeOnCalls: boolean;
  canManageKnowledgeBaseContent: boolean;

  constructor(permissions: {
    conversationAccess: ConversationAccess;
    hasInboxAccess: boolean;
    canManageTeammates: boolean;
    canChangeAwayMode: boolean;
    canManageTags: boolean;
    canInboxConversationTranscriptsExport: boolean;
    canManageMacros: boolean;
    hasFullAccess: boolean;
    canManageInboxViews: boolean;
    canAccessRealTimeDashboard: boolean;
    canReassignConversations: boolean;
    canRedactConversationParts: boolean;
    canSendFromCustomAddresses: boolean;
    canAccessWorkspaceSettings: boolean;
    canReplyToInboundConversations: boolean;
    canAccessContacts: boolean;
    canAccessBillingSettings: boolean;
    canAccessAllConversations: boolean;
    canListenAndBargeOnCalls: boolean;
    canManageKnowledgeBaseContent: boolean;
  }) {
    this.conversationAccess = permissions.conversationAccess;
    this.hasInboxAccess = permissions.hasInboxAccess;
    this.canManageTeammates = permissions.canManageTeammates;
    this.canChangeAwayMode = permissions.canChangeAwayMode;
    this.canManageTags = permissions.canManageTags;
    this.canInboxConversationTranscriptsExport = permissions.canInboxConversationTranscriptsExport;
    this.canManageMacros = permissions.canManageMacros;
    this.hasFullAccess = permissions.hasFullAccess;
    this.canManageInboxViews = permissions.canManageInboxViews;
    this.canAccessRealTimeDashboard = permissions.canAccessRealTimeDashboard;
    this.canReassignConversations = permissions.canReassignConversations;
    this.canRedactConversationParts = permissions.canRedactConversationParts;
    this.canSendFromCustomAddresses = permissions.canSendFromCustomAddresses;
    this.canAccessWorkspaceSettings = permissions.canAccessWorkspaceSettings;
    this.canReplyToInboundConversations = permissions.canReplyToInboundConversations;
    this.canAccessContacts = permissions.canAccessContacts;
    this.canAccessBillingSettings = permissions.canAccessBillingSettings;
    this.canAccessAllConversations = permissions.canAccessAllConversations;
    this.canListenAndBargeOnCalls = permissions.canListenAndBargeOnCalls;
    this.canManageKnowledgeBaseContent = permissions.canManageKnowledgeBaseContent;
  }
}

export default class AdminWithPermissions extends AdminSummary {
  permissions: AdminPermissions;
  locale: string;
  teamIds: Array<number>;
  seats: Array<SeatType | undefined>;
  optedIntoCopilotWaitlist: boolean;
  count?: number;
  optedIntoInbox2?: boolean | null;
  adminStatus?: AdminStatus;
  isOperator?: boolean;

  constructor(
    id: number,
    name: string,
    imageURL: string | null,
    permissions: AdminPermissions,
    seats: Array<SeatType | undefined>,
    teamIds: Array<number>,
    locale: string,
    optedIntoCopilotWaitlist: boolean,
    isImpersonating?: boolean,
    count?: number | 0,
    optedIntoInbox2?: boolean | null,
    adminStatus?: AdminStatus,
    isOperator?: boolean,
  ) {
    super(id, name, imageURL, isImpersonating);

    this.permissions = permissions;
    this.teamIds = teamIds;
    this.count = count;
    this.locale = locale;
    this.seats = seats;
    this.optedIntoInbox2 = optedIntoInbox2;
    this.optedIntoCopilotWaitlist = optedIntoCopilotWaitlist;
    this.adminStatus = adminStatus;
    this.isOperator = isOperator;
  }

  static deserialize(json: AdminWithPermissionsWireFormat) {
    let permissions = new AdminPermissions({
      conversationAccess: {
        accessType: json.permissions.conversation_access.access_type,
        assigneeBlockedList: json.permissions.conversation_access.assignee_blocked_list,
        includeUnassigned: json.permissions.conversation_access.include_unassigned,
      },
      hasInboxAccess: json.permissions.has_inbox_access,
      canManageTeammates: json.permissions.manage_teammates,
      canChangeAwayMode: json.permissions.teammates__away_mode__change,
      canManageTags: json.permissions.manage_tags,
      canManageMacros: json.permissions.manage_saved_replies,
      canInboxConversationTranscriptsExport:
        json.permissions.inbox__conversation_transcripts__export,
      hasFullAccess: json.permissions.has_full_access,
      canManageInboxViews: json.permissions.manage_inbox_views,
      canAccessRealTimeDashboard: json.permissions.access_real_time_dashboard,
      canReassignConversations: json.permissions.reassign_conversations,
      canRedactConversationParts: json.permissions.redact_conversation_parts,
      canSendFromCustomAddresses: json.permissions.send_from_custom_addresses,
      canAccessWorkspaceSettings: json.permissions.access_workspace_settings,
      canReplyToInboundConversations: json.permissions.reply_to_inbound_conversations,
      canAccessContacts: json.permissions.access_contacts,
      canAccessBillingSettings: json.permissions.access_billing_settings,
      canAccessAllConversations: json.permissions.can_access_all_conversations,
      canListenAndBargeOnCalls: json.permissions.calling__listening_and_barging,
      canManageKnowledgeBaseContent: json.permissions.manage_knowledge_base_content,
    });

    let adminStatus = new AdminStatus(
      json.admin_status?.away_mode_enabled,
      json.admin_status?.reassign_conversations,
      json.admin_status?.is_auto_changed,
      json.admin_status?.channel_availability,
    );

    return new AdminWithPermissions(
      json.id,
      json.name,
      json.image_url,
      permissions,
      json.seats,
      json.team_ids,
      json.locale,
      json.copilot_waitlist_opt_in,
      json.is_impersonating,
      json.count,
      json.inbox_2_opt_in,
      adminStatus,
      json.is_operator,
    );
  }

  get hasVbp2CollaboratorSeat() {
    return (this.seats || []).includes(COLLABORATOR_SEAT_TYPE);
  }

  get hasPricing5LiteSeat() {
    return (this.seats || []).includes(PRICING_5_X_LITE_SEAT_TYPE);
  }
}
