/* RESPONSIBLE TEAM: team-help-desk-experience */

import { ConversationState, TicketSystemState } from 'embercom/objects/inbox/conversation';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
export enum InboxStateOption {
  Open = 'open',
  Submitted = 'submitted',
  InProgress = 'in_progress',
  WaitingOnCustomer = 'waiting_on_customer',
  Resolved = 'resolved',
  Closed = 'closed',
  Snoozed = 'snoozed',
}

export const INBOX_STATE_ICON_MAPPING = {
  [InboxStateOption.Open]: 'reopen-conversation',
  [InboxStateOption.Submitted]: 'ticket',
  [InboxStateOption.InProgress]: 'ticket',
  [InboxStateOption.WaitingOnCustomer]: 'ticket',
  [InboxStateOption.Resolved]: 'ticket',
  [InboxStateOption.Closed]: 'close-conversation',
  [InboxStateOption.Snoozed]: 'snooze',
};

export enum InboxConversationTypeOption {
  All = 'all',
  Conversation = 'conversation',
  Ticket = 'ticket',
}

export enum InboxSortOption {
  Oldest = 'oldest',
  Newest = 'newest',
  StartedFirst = 'started-first',
  StartedLast = 'started-last',
  WaitingLongest = 'waiting-longest',
  NextSlaTarget = 'next-sla-target',
  PriorityNewest = 'priority-newest',
  Relevance = 'relevance',
}

export enum InboxMentionsStatus {
  All = 'all',
  Unread = 'unread',
}

export enum InboxSearchSort {
  Oldest = 'oldest',
  Newest = 'newest',
  Relevance = 'relevance',
}

export function isConversationStateEqual(
  inboxState: InboxStateOption,
  state: ConversationState,
  ticketState?: TicketSystemState,
): boolean {
  return {
    [InboxStateOption.Open]: state === ConversationState.Open,
    [InboxStateOption.Closed]: state === ConversationState.Closed,
    [InboxStateOption.Snoozed]: state === ConversationState.Snoozed,
    [InboxStateOption.WaitingOnCustomer]: ticketState === TicketSystemState.WaitingOnCustomer,
    [InboxStateOption.InProgress]: ticketState === TicketSystemState.InProgress,
    [InboxStateOption.Submitted]: ticketState === TicketSystemState.Submitted,
    [InboxStateOption.Resolved]: ticketState === TicketSystemState.Resolved,
  }[inboxState];
}

export function isTicketState(state: unknown): boolean {
  return Object.values(TicketSystemState).includes(state as TicketSystemState);
}

export function isConversationInInbox(
  inbox: Inbox | undefined,
  conversation: ConversationSummary | ConversationTableEntry,
) {
  if (!inbox) {
    return false;
  }

  // In the All, Mentions, Created By You, or views inbox, conversations are always in context.
  if (
    inbox.id === 'all' ||
    inbox.id === 'mentions' ||
    inbox.id === 'created_by_you' ||
    inbox.type === 'view'
  ) {
    return true;
  }

  if (inbox.id === 'unassigned') {
    return conversation.isNotAssigned;
  }

  return conversation.isAssignedTo(Number(inbox.id));
}

export function stateToInt(state: InboxStateOption): number | undefined {
  if (state === InboxStateOption.Open) {
    return 0;
  }

  if (state === InboxStateOption.Closed) {
    return 1;
  }

  if (state === InboxStateOption.Snoozed) {
    return 2;
  }

  return undefined;
}

export function intToState(n?: unknown): InboxStateOption | undefined {
  if (n === 0) {
    return InboxStateOption.Open;
  }

  if (n === 1) {
    return InboxStateOption.Closed;
  }

  if (n === 2) {
    return InboxStateOption.Snoozed;
  }

  return undefined;
}
