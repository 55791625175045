/* RESPONSIBLE TEAM: team-ai-chatbot */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class ExtractedQuestion extends Fragment {
  @attr('string') declare question: string;
  @attr('number') declare conversationId: number;
  @attr('string') declare formattedConversationPartId: string;
  @attr('string') declare conversationResolutionState: string;
  @attr('date') declare createdAt: Date;
}
