/* RESPONSIBLE TEAM: team-ai-chatbot */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { getProperties } from '@ember/object';
import {
  union,
  gte,
  empty,
  and,
  or,
  not,
  alias,
  filterBy,
  notEmpty,
  mapBy,
} from '@ember/object/computed';
import { A } from '@ember/array';
import { filterByProperty, rejectByProperty } from '@intercom/pulse/lib/computed-properties';

export const SUGGESTED_EXAMPLE_SELECTION_COUNT = 5;
export const EXAMPLES_COMPLETED_THRESHOLD = 10;

function exampleEqual(exampleA, exampleB) {
  return exampleA.identifier === exampleB.identifier && exampleA.text === exampleB.text;
}

function sanitizeExample(example) {
  return getProperties(example, 'identifier', 'text');
}

export default EmberObject.extend({
  isNewFromSuggestion: false,

  recentlyRejectedExamples: undefined,
  currentlyPresentedExamples: undefined,
  rejectedExamples: undefined,
  removedExamples: undefined,
  selectedExamplesWithSource: undefined,
  currentSearchNumber: 1,

  completedThreshold: EXAMPLES_COMPLETED_THRESHOLD,

  addedFromSearchResultsWithSource: filterBy(
    'selectedExamplesWithSource',
    'addedFrom',
    'search_results',
  ),
  addedFromCurrentSearchWithSource: filterByProperty(
    'addedFromSearchResultsWithSource',
    'searchNumber',
    'currentSearchNumber',
  ),
  addedFromPreviousSearchWithSource: rejectByProperty(
    'addedFromSearchResultsWithSource',
    'searchNumber',
    'currentSearchNumber',
  ),

  addedFromSuggestion: filterBy('selectedExamplesWithSource', 'addedFrom', 'suggestion'),
  addedFromSuggestionCurrentSearch: filterByProperty(
    'addedFromSuggestion',
    'searchNumber',
    'currentSearchNumber',
  ),
  addedExamplesFromCurrentSearchOrSuggestion: union(
    'addedFromSuggestionCurrentSearch',
    'addedFromCurrentSearchWithSource',
  ),

  addedExamplesFromCurrentSearch: mapBy('addedFromCurrentSearchWithSource', 'example'),

  presentedExamplesEmpty: empty('currentlyPresentedExamples'),
  isPresentingExamples: not('presentedExamplesEmpty'),

  removedAndRejectedExamples: union('rejectedExamples', 'removedExamples'),

  hasAddedExamples: notEmpty('addedFromSearchResultsWithSource'),
  hasNotAddedExamples: not('hasAddedExamples'),
  hasAddedExamplesInOtherSearch: notEmpty('addedFromPreviousSearchWithSource'),
  hasNotAddedExamplesInOtherSearch: not('hasAddedExamplesInOtherSearch'),

  hasAddedAllExamples: and('presentedExamplesEmpty', 'hasAddedExamples'),

  hasAddedThresholdExamples: gte(
    'addedExamplesFromCurrentSearchOrSuggestion.length',
    SUGGESTED_EXAMPLE_SELECTION_COUNT,
  ),
  hasAddedThresholdExamplesOrAll: or('hasAddedAllExamples', 'hasAddedThresholdExamples'),
  hasNotAddedThresholdExamplesOrAll: not('hasAddedThresholdExamplesOrAll'),

  isCompleted: gte('selectedExamplesWithSource.length', EXAMPLES_COMPLETED_THRESHOLD),
  isNotCompleted: not('isCompleted'),

  tooltipCompleted: alias('isCompleted'),
  tooltipTryNewSearch: and('isNotCompleted', 'hasAddedThresholdExamplesOrAll'),
  initiatedFromClusterBeta: and(
    'isNewFromCluster',
    'isNotCompleted',
    'hasNotAddedThresholdExamplesOrAll',
    'hasNotAddedExamplesInOtherSearch',
    'presentedExamplesEmpty',
  ),

  tooltipFirstSearch: and(
    'isNotCompleted',
    'hasNotAddedThresholdExamplesOrAll',
    'isPresentingExamples',
    'hasNotAddedExamples',
  ),
  tooltipFirstSearchAddMore: and(
    'isNotCompleted',
    'hasNotAddedThresholdExamplesOrAll',
    'isPresentingExamples',
    'hasAddedExamples',
    'hasNotAddedExamplesInOtherSearch',
  ),
  tooltipSubsequentSearch: and(
    'isNotCompleted',
    'hasNotAddedThresholdExamplesOrAll',
    'isPresentingExamples',
    'hasAddedExamples',
    'hasAddedExamplesInOtherSearch',
  ),

  init() {
    this._super(...arguments);

    this.set('currentSearchNumber', 1);

    this.set('recentlyRejectedExamples', A([]));
    this.set('currentlyPresentedExamples', A([]));
    this.set('rejectedExamples', A([]));
    this.set('removedExamples', A([]));
    this.set('selectedExamplesWithSource', A([]));

    if (this.answer !== undefined) {
      let source = this.isNewFromSuggestion ? 'suggestion' : 'loaded_from_answer';
      this.setSelectedExamples(this.get('answer.exampleQuestions'), source);
    }
  },

  addRecentExample(exampleQuestion, addedFrom) {
    let selectedExample = {
      example: sanitizeExample(exampleQuestion),
      addedFrom: addedFrom || 'search_results',
      searchNumber: this.currentSearchNumber,
    };

    this.selectedExamplesWithSource.pushObject(selectedExample);
  },

  removeExampleQuestion(exampleQuestion) {
    let sanitizedExample = sanitizeExample(exampleQuestion);
    this.removedExamples.pushObject(sanitizedExample);

    let selectedExampleItem = this.selectedExamplesWithSource.find((selectedItem) =>
      exampleEqual(selectedItem.example, sanitizedExample),
    );
    this.selectedExamplesWithSource.removeObject(selectedExampleItem);
  },

  rejectExampleQuestion(exampleQuestion) {
    let sanitizedExample = sanitizeExample(exampleQuestion);
    this.rejectedExamples.pushObject(sanitizedExample);
    this.recentlyRejectedExamples.pushObject(sanitizedExample);
  },

  setCurrentlyPresentedExamples(exampleQuestions) {
    this.set('currentlyPresentedExamples', exampleQuestions);
  },

  newSearchPerformed() {
    this.recentlyRejectedExamples.clear();
    this.currentlyPresentedExamples.clear();
    this.removedExamples.clear();
    this.incrementProperty('currentSearchNumber');
  },

  setSelectedExamples(selectedExamples, addedFrom) {
    this.set('selectedExamplesWithSource', A([]));
    selectedExamples.forEach((example) =>
      this.addRecentExample(example, addedFrom || 'loaded_from_answer'),
    );
  },
});
