/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Block from './block';
import { computed } from '@ember/object';
import { isBlank } from '@ember/utils';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { fragment } from 'ember-data-model-fragments/attributes';

export default Block.extend({
  text: attr('string'),
  align: attr('string'),
  class: attr('string'),
  style: fragment('common/blocks/style'),
  isText: true,

  hasContent: computed('text', function () {
    let unescapedText = htmlToTextContent(this.text);
    return !isBlank(unescapedText);
  }),
});
