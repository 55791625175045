/* RESPONSIBLE TEAM: team-customer-data-platform */

const ATTRIBUTE_DESCRIPTOR_TYPE_DECIMAL = 'decimal';
const ATTRIBUTE_DESCRIPTOR_TYPE_FIXNUM = 'fixnum';
const ATTRIBUTE_DESCRIPTOR_TYPE_FLOAT = 'float';
const ATTRIBUTE_DESCRIPTOR_TYPE_DATE = 'date';
const ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME = 'datetime';
const ATTRIBUTE_DESCRIPTOR_TYPE_STRING = 'string';
const ATTRIBUTE_DESCRIPTOR_TYPE_INTEGER = 'integer';
const ATTRIBUTE_DESCRIPTOR_TYPE_OPTIONS = 'options';
const ATTRIBUTE_DESCRIPTOR_TYPE_LIST = 'list';
const ATTRIBUTE_DESCRIPTOR_TYPE_BOOLEAN = 'boolean';
const ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP = 'relationship';

type ATTRIBUTE_DESCRIPTOR_TYPES =
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_STRING
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_FIXNUM
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_INTEGER
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_FLOAT
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_DECIMAL
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_OPTIONS
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_LIST
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_BOOLEAN
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_DATE
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME
  | typeof ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP;

/* @deprecated these strings are untranslated. Use ATTRIBUTE_DESCRIPTOR_TYPE_TRANSLATION_KEY_MAP instead */
/* eslint-disable @intercom/intercom/no-bare-strings */
const ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_MAP = {
  [ATTRIBUTE_DESCRIPTOR_TYPE_STRING]: 'Text',
  [ATTRIBUTE_DESCRIPTOR_TYPE_INTEGER]: 'Number',
  [ATTRIBUTE_DESCRIPTOR_TYPE_DECIMAL]: 'Decimal Number',
  [ATTRIBUTE_DESCRIPTOR_TYPE_LIST]: 'List',
  [ATTRIBUTE_DESCRIPTOR_TYPE_BOOLEAN]: 'Boolean',
  [ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME]: 'Date',
  [ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP]: 'Relationship',
};
/* eslint-enable @intercom/intercom/no-bare-strings */

const ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_TRANSLATION_KEY_MAP: Record<
  ATTRIBUTE_DESCRIPTOR_TYPES,
  string
> = {
  [ATTRIBUTE_DESCRIPTOR_TYPE_STRING]:
    'people.attributes.detail-view.main-component.default-types.text',
  [ATTRIBUTE_DESCRIPTOR_TYPE_FIXNUM]:
    'people.attributes.detail-view.main-component.default-types.number',
  [ATTRIBUTE_DESCRIPTOR_TYPE_INTEGER]:
    'people.attributes.detail-view.main-component.default-types.number',
  [ATTRIBUTE_DESCRIPTOR_TYPE_FLOAT]:
    'people.attributes.detail-view.main-component.default-types.decimal',
  [ATTRIBUTE_DESCRIPTOR_TYPE_DECIMAL]:
    'people.attributes.detail-view.main-component.default-types.decimal',
  [ATTRIBUTE_DESCRIPTOR_TYPE_OPTIONS]:
    'people.attributes.detail-view.main-component.default-types.list',
  [ATTRIBUTE_DESCRIPTOR_TYPE_LIST]:
    'people.attributes.detail-view.main-component.default-types.list',
  [ATTRIBUTE_DESCRIPTOR_TYPE_BOOLEAN]:
    'people.attributes.detail-view.main-component.default-types.true-false',
  [ATTRIBUTE_DESCRIPTOR_TYPE_DATE]:
    'people.attributes.detail-view.main-component.default-types.date',
  [ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME]:
    'people.attributes.detail-view.main-component.default-types.date',
  [ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP]:
    'people.attributes.detail-view.main-component.default-types.relationship',
};

const ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP: Record<ATTRIBUTE_DESCRIPTOR_TYPES, string> = {
  [ATTRIBUTE_DESCRIPTOR_TYPE_DECIMAL]: 'decimal',
  [ATTRIBUTE_DESCRIPTOR_TYPE_FIXNUM]: 'numbers',
  [ATTRIBUTE_DESCRIPTOR_TYPE_FLOAT]: 'decimal',
  [ATTRIBUTE_DESCRIPTOR_TYPE_DATE]: 'calendar',
  [ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME]: 'calendar',
  [ATTRIBUTE_DESCRIPTOR_TYPE_STRING]: 'small-text',
  [ATTRIBUTE_DESCRIPTOR_TYPE_INTEGER]: 'numbers',
  [ATTRIBUTE_DESCRIPTOR_TYPE_OPTIONS]: 'list',
  [ATTRIBUTE_DESCRIPTOR_TYPE_LIST]: 'list',
  [ATTRIBUTE_DESCRIPTOR_TYPE_BOOLEAN]: 'boolean',
  [ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP]: 'transfer',
};

export {
  ATTRIBUTE_DESCRIPTOR_TYPE_DECIMAL,
  ATTRIBUTE_DESCRIPTOR_TYPE_DATETIME,
  ATTRIBUTE_DESCRIPTOR_TYPE_STRING,
  ATTRIBUTE_DESCRIPTOR_TYPE_INTEGER,
  ATTRIBUTE_DESCRIPTOR_TYPE_LIST,
  ATTRIBUTE_DESCRIPTOR_TYPE_BOOLEAN,
  ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP,
  ATTRIBUTE_DESCRIPTOR_TYPES,
  ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_MAP,
  ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_TRANSLATION_KEY_MAP,
  ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP,
};
