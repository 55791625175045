/* RESPONSIBLE TEAM: team-tickets-1 */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import { type CustomActionFinishedWireFormat } from 'embercom/objects/inbox/result-summary';
import CustomBotSummary from '../custom-bot-summary';

export default class CustomActionFinished implements RenderableData {
  renderableType: RenderableType = RenderableType.CustomActionFinished;
  readonly customActionName: string;
  readonly customActionResult: string;
  readonly customBotSummary?: CustomBotSummary;
  readonly customActionId?: number;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(
    customActionName: string,
    result: string,
    customBotSummary?: CustomBotSummary,
    customActionId?: number,
  ) {
    this.customActionName = customActionName;
    this.customActionResult = result;
    this.customBotSummary = customBotSummary;
    this.customActionId = customActionId;
  }

  static deserialize(json: CustomActionFinishedWireFormat): CustomActionFinished {
    return new CustomActionFinished(
      json.custom_action_name,
      json.custom_action_result,
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
      json.custom_action_id,
    );
  }
}
