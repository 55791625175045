/* RESPONSIBLE TEAM: team-ai-chatbot */
import NotificationMessages from 'embercom/objects/content-editor/notification-messages';
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default function generateNotificationMessages(options) {
  let container = options.container;
  let intl = container.lookup('service:intl');

  return NotificationMessages.create({
    activate: NotificationMessage.extend({
      contentEditorService: service(),
      store: service(),
      async confirmation() {
        await this.contentEditorService.ruleset.fetchAudiencePreview();
        let ruleset = this.contentEditorService.ruleset;
        let audienceCount = ruleset.audiencePreview.count;

        let title = intl.t('operator.fin.notification-messages.activate.confirmation.title');
        let body = intl.t('operator.fin.notification-messages.activate.confirmation.body', {
          audienceCount,
        });
        let confirmButtonText = intl.t(
          'operator.fin.notification-messages.activate.confirmation.button-text',
        );

        return { title, body, confirmButtonText };
      },
    }).create({
      success: intl.t('operator.fin.notification-messages.activate.success'),
      failure: intl.t('operator.fin.notification-messages.activate.failure'),
      container,
    }),
    deactivate: NotificationMessage.extend({
      contentEditorService: service(),
      intl: service(),
      async confirmation() {
        let dependencies = await this.contentEditorService.ruleset.loadDependentObjects();

        let title = intl.t('operator.fin.notification-messages.deactivate.confirmation.title');
        let body = intl.t('operator.fin.notification-messages.deactivate.confirmation.body');
        let confirmButtonText = intl.t(
          'operator.fin.notification-messages.deactivate.confirmation.button-text',
        );

        return {
          title,
          body,
          confirmButtonText,
          bodyComponentName: 'content-editor/dependent-content-list',
          confirmContext: {
            dependencies,
            body,
            humanReadableObjectName: intl.t('ai-agent.profiles.config-generator.behavior'),
          },
        };
      },
    }).create({
      success: intl.t('operator.fin.notification-messages.deactivate.success'),
      failure: intl.t('operator.fin.notification-messages.deactivate.failure'),
      container,
    }),
    duplicate: NotificationMessage.create({
      success: intl.t('operator.fin.notification-messages.duplicate.success'),
      failure: intl.t('operator.fin.notification-messages.duplicate.failure'),
      container,
    }),
    cancel: NotificationMessage.create({
      success: null,
      failure: null,
      container,
    }),
    save: NotificationMessage.extend({
      contentEditorService: service(),
      confirmation() {
        let ruleset = this.contentEditorService.ruleset;

        if (ruleset.isLive) {
          let title = intl.t('operator.fin.notification-messages.save.confirmation.title');
          let body = intl.t('operator.fin.notification-messages.save.confirmation.body');
          let confirmButtonText = intl.t(
            'operator.fin.notification-messages.save.confirmation.button-text',
          );

          return { title, body, confirmButtonText };
        }
        return null;
      },
    }).create({
      success: intl.t('operator.fin.notification-messages.save.success'),
      failure: intl.t('operator.fin.notification-messages.save.failure'),
      container,
    }),
    delete: NotificationMessage.extend({
      contentEditorService: service(),
      outboundHomeService: service(),
      appService: service(),
      async confirmation() {
        let dependencies = await this.contentEditorService.ruleset.loadDependentObjects();

        let title = intl.t('operator.fin.notification-messages.delete.confirmation.title');
        let confirmButtonText = intl.t(
          'operator.fin.notification-messages.delete.confirmation.button-text',
        );
        let primaryButtonType = 'primary-destructive';

        let body;
        try {
          let response = await this.outboundHomeService.contentSearchWithFilters({
            object_types: [objectTypes.resolutionBotBehavior],
            app_id: this.appService.app.id,
          });

          let profiles =
            response.contentWrappers.filter(
              (contentWrapper) =>
                !contentWrapper.contents.firstObject?.contentData?.is_fin_setup_behavior,
            ) || [];

          let totalNumberOfProfiles = profiles.length;

          if (totalNumberOfProfiles > 1) {
            body = intl.t('operator.fin.notification-messages.delete.confirmation.body');
          } else if (this.appService.app.canUseFeature('workflows_core_billing_feature')) {
            body = intl.t(
              'operator.fin.notification-messages.delete.confirmation.final-profile-warning-workflows',
              { htmlSafe: true },
            );
          } else {
            body = intl.t(
              'operator.fin.notification-messages.delete.confirmation.final-profile-warning',
              { htmlSafe: true },
            );
          }
        } catch (error) {
          console.error(error);
          body = intl.t('operator.fin.notification-messages.delete.confirmation.body');
        }

        return {
          title,
          body,
          confirmButtonText,
          primaryButtonType,
          bodyComponentName: 'content-editor/dependent-content-list',
          confirmContext: {
            dependencies,
            body,
            humanReadableObjectName: intl.t('ai-agent.profiles.config-generator.behavior'),
          },
        };
      },
    }).create({
      success: intl.t('operator.fin.notification-messages.delete.success'),
      failure: intl.t('operator.fin.notification-messages.delete.failure'),
      container,
    }),
    schedule: NotificationMessage.extend({
      contentEditorService: service(),
      async confirmation() {
        await this.contentEditorService.ruleset.fetchAudiencePreview();
        let ruleset = this.contentEditorService.ruleset;
        let activationDate = ruleset.scheduledActivation.humanReadableChangeAt;

        let title = intl.t('operator.fin.notification-messages.schedule.confirmation.title');
        let body = intl.t('operator.fin.notification-messages.schedule.confirmation.body', {
          activationDate,
        });
        let confirmButtonText = intl.t(
          'operator.fin.notification-messages.schedule.confirmation.button-text',
        );

        return { title, body, confirmButtonText };
      },
    }).create({
      success: intl.t('operator.fin.notification-messages.schedule.success'),
      failure: intl.t('operator.fin.notification-messages.schedule.failure'),
      container,
    }),
    unschedule: NotificationMessage.create({
      success: intl.t('operator.fin.notification-messages.unschedule.success'),
      failure: intl.t('operator.fin.notification-messages.unschedule.failure'),
      confirmation() {
        let title = intl.t('operator.fin.notification-messages.unschedule.confirmation.title');
        let body = intl.t('operator.fin.notification-messages.unschedule.confirmation.body');
        let confirmButtonText = intl.t(
          'operator.fin.notification-messages.unschedule.confirmation.button-text',
        );

        return { title, body, confirmButtonText };
      },
      container,
    }),
  });
}
