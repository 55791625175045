/* RESPONSIBLE TEAM: team-ai-chatbot */
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';

export default class ResolutionBotBehaviorHeaderConfiguration extends StandardPanelHeaderConfiguration {
  @service contentEditorService;
  @service appService;
  @service intl;

  get errors() {
    return this.contentEditorService.activeObject.validations.attrs.botOnlyMessageLocalizations
      .errors;
  }

  get isComplete() {
    return isEmpty(this.errors);
  }

  init(inputs) {
    inputs.component = 'content-editor/panels/custom/ai-agent-profile/bot-mode/header';
    inputs.title = this.intl.t(
      this.appService.app.canUseFinExperience
        ? 'ai-agent.profiles.config-generator-fin.bot-mode'
        : 'ai-agent.profiles.config-generator.bot-mode',
    );
    super.init(...arguments);
  }
}
