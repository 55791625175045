/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import CustomBotSummary, { type CustomBotSummaryWireFormat } from '../custom-bot-summary';

interface ChannelAndReplyTimeExpectationWireFormat {
  renderable_type: RenderableType;
  blocks: Array<object>;
  entity: AdminSummaryWireFormat;
  custom_bot_summary?: CustomBotSummaryWireFormat;
}

export default class ChannelAndReplyTimeExpectation implements RenderableData {
  readonly renderableType = RenderableType.ChannelAndReplyTimeExpectation;
  readonly blocks: Array<object>;
  readonly entity: AdminSummary;
  readonly customBotSummary?: CustomBotSummary;

  get creatingEntity() {
    return { type: EntityType.Bot, id: this.entity.id, object: this.entity };
  }

  get styles() {
    return {
      backgroundColor: 'bg-sky-dark dark:bg-dm-blue-dark',
      textColor: 'text-blue-darkest dark:text-dm-white',
      backgroundColorHover: 'bg-sky-darkest dark:bg-blue-darkest',
    };
  }

  get ticketStyles() {
    return {
      backgroundColor: 'bg-white dark:bg-black',
      textColor: 'text-black dark:text-dm-white',
      backgroundColorHover: 'bg-gray-lightest dark:bg-dm-gray-darker',
    };
  }

  constructor(blocks: Array<object>, entity: AdminSummary, customBotSummary?: CustomBotSummary) {
    this.blocks = blocks;
    this.entity = entity;
    this.customBotSummary = customBotSummary;
  }

  static deserialize(
    json: ChannelAndReplyTimeExpectationWireFormat,
  ): ChannelAndReplyTimeExpectation {
    return new ChannelAndReplyTimeExpectation(
      json.blocks,
      AdminSummary.deserialize(json.entity),
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
    );
  }
}
