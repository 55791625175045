/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type ConversationRecord } from './types/conversation-record';
import ConversationAttributeSummary from './conversation-attribute-summary';
import type TeamSummary from './team-summary';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export enum DataType {
  String = 'string',
  Integer = 'integer',
  List = 'list',
  Decimal = 'decimal',
  Boolean = 'boolean',
  DomainObjectRelationship = 'domain_object_relationship',
  UserRelationship = 'user_relationship',
  Datetime = 'datetime',
  Relationship = 'relationship',
  Files = 'files',
  AdminId = 'admin_id',
}

export interface ConversationAttributeDescriptorConditionWireFormat {
  id: number;
  controlling_descriptor_id: number;
  controlling_list_option_id: string;
  descriptor_list_option_ids: string[];
}

export interface ConversationAttributeDescriptorCondition {
  id: number;
  controllingDescriptorId: number;
  controllingListOptionId: string;
  descriptorListOptionIds: string[];
}

export interface ConversationAttributeDescriptorListOption {
  id: string;
  label: string;
  archived: boolean;
}

export interface ConversationAttributeDescriptorWireFormat {
  id: number | string;
  name: string;
  data_type: DataType;
  order?: number;
  archived?: boolean;
  list_options?: ConversationAttributeDescriptorListOption[];
  required?: boolean;
  visible_to_team_ids?: string[];
  multiline?: boolean;
  editable: boolean;
  hide_from_inbox_sidebar?: boolean;
  icon?: InterfaceIconName;
  category?: string | null;
  conditions?: ConversationAttributeDescriptorConditionWireFormat[];
}

export default class ConversationAttributeDescriptor {
  readonly id: number | string;
  readonly name: string;
  readonly type: DataType;
  readonly order?: number;
  readonly archived?: boolean;
  readonly listOptions?: ConversationAttributeDescriptorListOption[];
  readonly required: boolean | null;
  readonly visibleToTeamIds?: number[];
  readonly multiline?: boolean;
  readonly editable: boolean;
  readonly hideFromInboxSidebar: boolean;
  readonly icon?: InterfaceIconName;
  readonly category?: string | null;
  readonly conditions: ConversationAttributeDescriptorCondition[] = [];

  constructor(
    id: number | string,
    name: string,
    type: DataType,
    editable?: boolean,
    order?: number,
    archived?: boolean,
    listOptions?: ConversationAttributeDescriptorListOption[],
    required?: boolean,
    visibleToTeamIds?: number[],
    multiline?: boolean,
    hideFromInboxSidebar?: boolean,
    icon?: InterfaceIconName,
    category?: string | null,
    conditions: ConversationAttributeDescriptorCondition[] = [],
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.editable = editable === undefined ? true : editable;
    this.order = order;
    this.archived = archived;
    this.listOptions = listOptions;
    this.required = !!required;
    this.visibleToTeamIds = visibleToTeamIds;
    this.multiline = multiline;
    this.hideFromInboxSidebar = hideFromInboxSidebar === undefined ? false : hideFromInboxSidebar;
    this.icon = icon;
    this.category = category;
    this.conditions = conditions;
  }

  isVisibleToTeamId(teamId?: number): boolean {
    if (this.visibleToTeamIds && this.visibleToTeamIds.length) {
      return !!teamId && this.visibleToTeamIds.includes(teamId);
    }

    return true;
  }

  get isRelationshipDataType() {
    return this.type === DataType.Relationship;
  }

  static deserialize(
    json: ConversationAttributeDescriptorWireFormat,
  ): ConversationAttributeDescriptor {
    let id: number | string = Number(json.id);
    if (isNaN(id)) {
      id = json.id;
    }

    return new ConversationAttributeDescriptor(
      id,
      json.name,
      json.data_type,
      json.editable,
      json.order,
      json.archived,
      json.list_options,
      json.required,
      json.visible_to_team_ids?.map((id) => parseInt(id, 10)),
      json.multiline,
      json.hide_from_inbox_sidebar,
      json.icon,
      json.category,
      json?.conditions?.map((condition) => ({
        id: condition.id,
        controllingDescriptorId: condition.controlling_descriptor_id,
        controllingListOptionId: condition.controlling_list_option_id,
        descriptorListOptionIds: condition.descriptor_list_option_ids,
      })),
    );
  }
}

export function requiredAttributesForTeamAssignee(
  descriptors: ConversationAttributeDescriptor[],
  team?: TeamSummary,
): ConversationAttributeDescriptor[] {
  let isRequired = (descriptor: ConversationAttributeDescriptor) =>
    !descriptor.archived && descriptor.required;

  let isVisibleToTeam = (descriptor: ConversationAttributeDescriptor) =>
    descriptor.isVisibleToTeamId(team?.id);

  return descriptors.filter(isVisibleToTeam).filter(isRequired);
}

export function emptyRequiredAttributesForConversation(
  conversation: ConversationRecord,
  descriptors: ConversationAttributeDescriptor[],
): ConversationAttributeSummary[] {
  if (!conversation.isReplyable) {
    return [];
  }

  let matchingAttribute = (descriptor: ConversationAttributeDescriptor) =>
    (conversation.attributes || []).find(
      (attribute: ConversationAttributeSummary) => attribute.descriptor.id === descriptor.id,
    ) || new ConversationAttributeSummary(undefined, descriptor);

  let isValueEmpty = (attribute: ConversationAttributeSummary) => attribute.isValueEmpty;

  return requiredAttributesForTeamAssignee(descriptors, conversation.teamAssignee)
    .map(matchingAttribute)
    .filter(isValueEmpty);
}
