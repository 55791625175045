/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import {
  getApp,
  getAttributeService,
  getIntlService,
  getEmberDataStore,
} from 'embercom/lib/container-lookup';
import type Group from 'embercom/models/operator/visual-builder/group';
import { type CreateStepParams } from 'embercom/models/operator/visual-builder/step';
import INSERTER_MENU_ITEMS_MAP, {
  type InserterMenuItemType,
  inserterMenuItems,
  ADD_CONTENT_GROUP_NAME,
  ADD_CONVERSATION_ACTION_GROUP_NAME,
  ADD_INTEGRATION_ACTION_GROUP_NAME,
  CONNECT_EXISTING_PATH_GROUP_NAME,
  PROCEED_TO_ANOTHER_PATH_GROUP_NAME,
  SET_CONVERSATION_DATA_GROUP_NAME,
  SET_USER_DATA_GROUP_NAME,
  type CustomHandlerFunction,
  type GroupNameType,
} from './inserter-menu-items-map';
import type Step from 'embercom/models/operator/visual-builder/step';
import type Edge from 'embercom/models/operator/visual-builder/edge';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type PathConfig from 'embercom/objects/visual-builder/configuration/path';
import type IntlService from 'embercom/services/intl';
import { cached } from 'tracked-toolbox';

export type StepGroupType = {
  heading?: string;
  isOpenByDefault?: boolean;
  items: InserterMenuItemType[];
  showCountInHeading?: boolean;
};

export type StepParameters = {
  ModelClass: typeof Step;
  stepCreationParams?: CreateStepParams;
  customItemHandler?: CustomHandlerFunction;
  analyticsData?: Record<string, unknown>;
};

export type EdgeParameters = { connectionPoint: ConnectionPoint };

export type InserterMenuConfigurationParams = {
  existingPaths?: Group[];
  excludedItems?: string[];
  pathConfig: PathConfig;
  onSelectItem: (stepParams: StepParameters, edgeParams?: EdgeParameters) => void | Group | Edge;
};

export default class InserterMenuConfiguration {
  app: any;
  intl: IntlService;
  store: any;
  attributeService: any;
  onSelectItem: InserterMenuConfigurationParams['onSelectItem'];
  existingPaths: Group[];
  pathConfig: PathConfig;
  allAvailableStepGroups: StepGroupType[];

  /*
   * The Fin Standalone product introduces the concept of having workspace level constraints on what can be used in workflows.
   * Standalone supports a much smaller number of steps compared to the full Intercom product. To ensure that we don't show
   * new steps options added in the future by default, we ensure that only the specified steps that can be used.
   *
   * For regular Intercom workspaces, this list will include all the steps available in the inserter menu.
   */
  @cached
  get appLevelAllowlist(): string[] {
    if (this.app.canUseFinStandalone) {
      return [
        inserterMenuItems.fin,
        inserterMenuItems.attributeCollector,
        inserterMenuItems.chatMessage,
        inserterMenuItems.conditionalBranches,
        inserterMenuItems.freeInput,
        inserterMenuItems.replyButtons,
        inserterMenuItems.setUserDataAttribute,
        inserterMenuItems.note,
        inserterMenuItems.summarizeConversation,
        inserterMenuItems.classifyConversationAttribute,
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.existingPaths,
      ];
    } else {
      return Object.values(inserterMenuItems);
    }
  }

  constructor({
    existingPaths = [],
    excludedItems = [],
    pathConfig,
    onSelectItem,
  }: InserterMenuConfigurationParams) {
    this.app = getApp();
    this.intl = getIntlService();
    this.store = getEmberDataStore();
    this.attributeService = getAttributeService();
    this.onSelectItem = onSelectItem;
    this.existingPaths = existingPaths;
    this.pathConfig = pathConfig;

    let defaultGroups = {
      [CONNECT_EXISTING_PATH_GROUP_NAME]: {
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${CONNECT_EXISTING_PATH_GROUP_NAME}`,
        ),
        showCountInHeading: true,
        items: [],
      },
      [ADD_CONTENT_GROUP_NAME]: {
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${ADD_CONTENT_GROUP_NAME}`,
        ),
        isOpenByDefault: true, // accordion is open
        items: [],
      },
      [PROCEED_TO_ANOTHER_PATH_GROUP_NAME]: {
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${PROCEED_TO_ANOTHER_PATH_GROUP_NAME}`,
        ),
        isOpenByDefault: true, // accordion is open
        items: [],
      },
      [ADD_CONVERSATION_ACTION_GROUP_NAME]: {
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${ADD_CONVERSATION_ACTION_GROUP_NAME}`,
        ),
        isOpenByDefault: true, // accordion is open
        items: [],
      },
      [SET_CONVERSATION_DATA_GROUP_NAME]: {
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${SET_CONVERSATION_DATA_GROUP_NAME}`,
        ),
        showCountInHeading: true,
        items: [],
      },
      [SET_USER_DATA_GROUP_NAME]: {
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${SET_USER_DATA_GROUP_NAME}`,
        ),
        showCountInHeading: true,
        items: [],
      },
      [ADD_INTEGRATION_ACTION_GROUP_NAME]: {
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${ADD_INTEGRATION_ACTION_GROUP_NAME}`,
        ),
        items: [],
      },
    } as Record<GroupNameType, StepGroupType>;

    let groupByGroupNameUnlessExcluded = (stepGroups: typeof defaultGroups, itemName: string) => {
      if (!excludedItems.includes(itemName) && this.appLevelAllowlist.includes(itemName)) {
        let steps = INSERTER_MENU_ITEMS_MAP[itemName](this);
        if (steps) {
          steps.forEach((step) => step.groupName && stepGroups[step.groupName].items.push(step));
        }
      }

      return stepGroups;
    };

    this.allAvailableStepGroups = [
      ...Object.values(
        Object.keys(INSERTER_MENU_ITEMS_MAP).reduce(groupByGroupNameUnlessExcluded, defaultGroups),
      ),
    ].filter((group) => group.items.length > 0);
  }
}
