/* RESPONSIBLE TEAM: team-workflows */
import Model, { attr, type SyncHasMany } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { createIntervalGroupsFromIntervals } from 'embercom/lib/office-hours/interval-group';
import { formattedTimezone } from 'embercom/lib/time-zone';
import { isEmpty } from '@ember/utils';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import type TimeInterval from './matching-system/timetable/time-interval';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type OfficeHoursLabelService from 'embercom/services/office-hours-label-service';

export default class OfficeHoursSchedule extends Model {
  @attr('string') declare name: string;
  @attr('boolean') declare isDefault: boolean;
  @attr({ defaultValue: () => [] }) declare linkedTeamIds: string[];
  @attr('number') declare teamsCount: number;
  @attr('string') declare timeZoneName: string;
  @service declare intl: IntlService;
  @service declare officeHoursLabelService: OfficeHoursLabelService;

  @fragmentArray('matching-system/timetable/time-interval')
  declare timeIntervals: SyncHasMany<TimeInterval>;

  @computed('timeZoneName')
  get formattedTimezone() {
    return formattedTimezone(this.timeZoneName);
  }

  @computed('timeIntervals')
  get label() {
    let intervalGroups = createIntervalGroupsFromIntervals(this.timeIntervals);
    if (isEmpty(intervalGroups)) {
      return this.intl.t('components.settings.office-hours.modal.always-on');
    }
    return intervalGroups
      .map((group) =>
        this.officeHoursLabelService.getTranslatedLabelWithTime(group.untranslatedLabel),
      )
      .join(', ');
  }

  get labelWithTimezone() {
    return `${this.label} • ${this.formattedTimezone}`;
  }

  @computed('timeIntervals.@each.isInvalid')
  get hasInvalidInterval() {
    return this.timeIntervals.isAny('isInvalid');
  }
}
