/* RESPONSIBLE TEAM: team-help-desk-experience */
import type RenderablePart from './renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type UserComment from './renderable/user-comment';
import type AdminComment from './renderable/admin-comment';
import type QuickReplyResponse from './renderable/quick-reply-response';
import type UserEmailComment from './renderable/user-email-comment';
import type Chat from './renderable/chat';
import type Email from './renderable/email';
import type Post from './renderable/post';
import type Tag from './tag';
import type CustomBot from './renderable/custom-bot';

export default interface TaggablePart extends RenderablePart {
  renderableType:
    | RenderableType.UserComment
    | RenderableType.AdminComment
    | RenderableType.QuickReplyResponse
    | RenderableType.UserEmailComment
    | RenderableType.Chat
    | RenderableType.Email
    | RenderableType.Post
    | RenderableType.CustomBot;
  renderableData:
    | UserComment
    | AdminComment
    | QuickReplyResponse
    | UserEmailComment
    | Chat
    | Email
    | Post
    | CustomBot;
}

export function isTaggable(part: RenderablePart) {
  return [
    RenderableType.UserComment,
    RenderableType.AdminComment,
    RenderableType.QuickReplyResponse,
    RenderableType.UserEmailComment,
    RenderableType.Chat,
    RenderableType.Email,
    RenderableType.Post,
    RenderableType.CustomBot,
  ].includes(part.renderableType);
}

export function isPartTaggedWith(part: TaggablePart, tag: Tag): boolean {
  let { tags }: { tags: Array<Tag> } = part.renderableData;
  return tags.some((t) => isSameTag(tag, t));
}

export function includesTag(tags: Tag[], tag: Tag): boolean {
  return tags.any((t) => isSameTag(tag, t));
}

export function isSameTag(a: Tag, b: Tag): boolean {
  return a.id === b.id || a.name === b.name;
}

export function assertTaggablePart(part: RenderablePart): asserts part is TaggablePart {
  if (!isTaggable(part)) {
    throw new Error('Part is not taggable');
  }
}
