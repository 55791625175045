/* RESPONSIBLE TEAM: team-ai-chatbot */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model from '@ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { action, computed } from '@ember/object';
import {
  not,
  equal,
  filterBy,
  mapBy,
  and,
  notEmpty,
  or,
  alias,
  readOnly,
  bool,
} from '@ember/object/computed';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { validator, buildValidations } from 'ember-cp-validations';
import { isEmpty, isBlank } from '@ember/utils';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import { inject as service } from '@ember/service';
import { put } from 'embercom/lib/ajax';

import TaggingMixin from 'embercom/models/mixins/tagging-mixin';
import Workflowable from 'embercom/models/mixins/operator/workflowable';

import { contentWrapperTypes } from 'embercom/models/data/custom-answers/constants';
import { UPDATE_TAGGING_URL } from 'embercom/lib/knowledge-hub/constants';

const ARABIC_COMMA = '،';
const COMMA = ',';

const STAMP_STYLE = {
  live: 'green',
  draft: 'gray',
  paused: 'gray',
};

const Validations = buildValidations({
  title: validator('presence', {
    presence: true,
    message: 'Please enter a title.',
    disabled: not('model.isLive'),
  }),
  'exampleQuestionsWithContent.length': validator('number', {
    message: 'Please select some example questions.',
    gt: 0,
    disabled: not('model.isLive'),
  }),
  firstPathConstraints: {
    description: 'First Path',
    validators: [validator('custom-bot-first-path-constraints')],
    disabled: bool('model.canUseVisualBuilderEditor'),
  },
  paths: {
    description: 'Paths',
    validators: [validator('presence', true), validator('has-many')],
    disabled: bool('model.canUseVisualBuilderEditor'),
  },
  visualBuilderObject: validator('belongs-to', {
    description: 'Workflow',
    disabled: not('model.canUseVisualBuilderEditor'),
  }),
});

export default Model.extend(TaggingMixin, Workflowable, Validations, {
  appService: service(),
  app: readOnly('appService.app'),

  analyticsData: computed('id', 'languageCode', function () {
    return {
      object: 'answer',
      answer_id: this.id,
      language_code: this.languageCode,
    };
  }),

  tags: hasMany('tag', { async: true }),
  taggings: hasMany('tagging'),

  visualBuilderObject: belongsTo('operator/visual-builder/workflow', { async: false }),

  status: attr('string'),
  title: attr('string'),
  createdAt: attr('date'),
  updatedAt: attr('date'),
  firstWentLiveAt: attr('date'),
  lastPausedAt: attr('date'),
  lastInterjectionAt: attr('date'),
  stats: fragment('custom-answers/answer-stats'),
  fromTemplate: attr(),
  disabledInPredictiveContext: attr('boolean'),
  languageCode: attr('string'),
  targetChannels: attr('array', { defaultValue: () => [] }),

  contentType: 'answer',
  allowsActionsInTerminalStep: true,
  ruleset: belongsTo('matching-system/ruleset', {
    async: false,
  }),

  selectedUserTypes: computed('ruleset.rolePredicateGroup.userPredicate.userTypes', function () {
    return this.get('ruleset.rolePredicateGroup.userPredicate.userTypes') || [];
  }),

  displayTitle: computed('title', function () {
    return this.title || '(untitled)';
  }),

  stampColor: computed('status', function () {
    return STAMP_STYLE[this.status];
  }),

  exampleQuestions: fragmentArray('custom-answers/example-question'),
  rejectedExampleQuestions: fragmentArray('custom-answers/rejected-example-question'),
  controlCriteria: fragmentArray('custom-answers/control-criteria'),
  hasUnsavedChanges: or(
    'hasDirtyAttributes',
    'ruleset.hasDirtyAttributes',
    'visualBuilderObject.hasUnsavedChanges',
  ),

  isDraft: equal('status', 'draft'),
  isLive: equal('status', 'live'),
  isPaused: equal('status', 'paused'),
  isNotLive: not('isLive'),

  isValid: or('isNotLive', 'validations.isValid'),
  canUseVisualBuilderEditor: computed('paths.length', 'visualBuilderObject', function () {
    return !this.paths.length && this.visualBuilderObject;
  }),

  hasMultiplePaths: computed(
    'canUseVisualBuilderEditor',
    'paths.length',
    'visualBuilderObject.groups.length',
    function () {
      if (this.canUseVisualBuilderEditor) {
        return this.visualBuilderObject.groups.length > 1;
      } else {
        return this.paths.length > 1;
      }
    },
  ),

  titleIsComplete: computed('title', function () {
    return !isBlank(this.title);
  }),
  languageIsComplete: computed('languageCode', function () {
    return !isBlank(this.languageCode);
  }),
  exampleQuestionsIsComplete: alias('hasExampleQuestionsWithContent'),
  hasBeenUpdated: computed('createdAt', 'updatedAt', function () {
    return this.updatedAt.getTime() !== this.createdAt.getTime();
  }),
  contentIsValid: computed(
    'canUseVisualBuilderEditor',
    'validations.attrs.{paths.isValid,visualBuilderObject.isValid}',
    function () {
      return this.canUseVisualBuilderEditor
        ? this.validations.attrs.visualBuilderObject.isValid
        : this.validations.attrs.paths.isValid;
    },
  ),
  answerCanBeSetLive: and('contentIsValid', 'exampleQuestionsIsComplete'),
  firstPathHasTextContent: computed(
    'canUseVisualBuilderEditor',
    'visualBuilderObject.groups.firstObject.steps.firstObject.blocks.firstObject.hasContent',
    'paths.firstObject.steps.firstObject.blocks.firstObject.hasContent',
    function () {
      if (this.canUseVisualBuilderEditor) {
        return this.visualBuilderObject.groups.firstObject?.steps.firstObject?.blocks.firstObject
          ?.hasContent;
      } else {
        return this.paths.firstObject?.steps.firstObject?.blocks.firstObject?.hasContent;
      }
    },
  ),

  hasContent: or('titleIsComplete', 'firstPathHasTextContent', 'hasExampleQuestionsWithContent'),

  hasTitle: notEmpty('title'),

  exampleQuestionsWithContent: filterBy('exampleQuestions', 'hasContent', true),
  hasExampleQuestionsWithContent: notEmpty('exampleQuestionsWithContent'),

  exampleQuestionSourceConversationIds: computed(
    'exampleQuestions.@each.sourceConversationId',
    function () {
      return this.exampleQuestions
        .map((exampleQuestion) => exampleQuestion.sourceConversationId)
        .filter(Boolean);
    },
  ),

  triggerWordsList: mapBy('controlCriteria', 'text'),
  triggerWordsCommaSeparatedList: computed('triggerWordsList.[]', function () {
    let comma = this.languageCode === 'ar' ? ARABIC_COMMA : COMMA;
    return this.triggerWordsList.join(`${comma} `);
  }),
  hasTriggerWords: notEmpty('triggerWordsList'),

  suggestedCluster: null,

  removeTagging: action(async function (tag) {
    let admin = this.app.currentAdmin;
    let addedTags = [];
    let removedTags = [tag];
    await this.updateTaggings(admin, addedTags, removedTags).catch((e) => {});
    return this.refreshTaggings(admin, addedTags, removedTags);
  }),

  taggable: computed('tags', 'taggings', 'updateTaggingsTask', function () {
    return {
      tags: this.tags,
      taggings: this.taggings,
      type: 'answer',
      updateTaggings: (admin, addedTags, removedTags, initialTags) => {
        return this.updateTaggings(admin, addedTags, removedTags);
      },
    };
  }),

  async updateTaggings(admin, addedTags, removedTags) {
    try {
      await put(UPDATE_TAGGING_URL, {
        app_id: this.app.id,
        added_tag_ids: addedTags.map((tag) => tag.id),
        removed_tag_ids: removedTags.map((tag) => tag.id),
        content_wrapper_id: this.id,
        content_wrapper_type: contentWrapperTypes.answers,
      });
    } catch (error) {
      this.refreshTaggings(admin, removedTags, addedTags);
      throw error;
    }
  },

  setControlCriteriaFromTriggerWordsList(triggerWordsList) {
    let newTriggerWords = triggerWordsList
      .map((text) => text.trim().replace(/^["']+|["']+$/g, '')) // Remove leading and trailing quotes
      .filter((text) => !isEmpty(text));
    this.set(
      'controlCriteria',
      newTriggerWords.map((text) => ({ text })),
    );
  },

  applySuggestedCluster(cluster) {
    let archetype = cluster.archetype;
    this.exampleQuestions.createFragment({
      text: archetype,
      addedFrom: 'cluster_example',
    });
    if (!this.hasTitle) {
      this.set('title', archetype);
    }
    this.set('suggestedCluster', cluster);
    this.set('languageCode', cluster.languageAlpha2);
  },

  applyExample(cluster, examples) {
    this.set('languageCode', cluster.languageAlpha2);
    this.set('title', examples[0].text);

    let exampleQuestions = this.exampleQuestions;
    examples.forEach(function (example) {
      exampleQuestions.createFragment({
        identifier: example.id,
        text: example.text,
        addedFrom: 'cluster_example',
      });
    });
  },

  save() {
    return this._super()
      .then(() => {
        if (this.suggestedCluster) {
          this.suggestedCluster.unloadRecord();
        }
      })
      .then(() => {
        // Until we resolve predicateGroup and rolePredicateGroup are not marked as not dirty
        // after ruleset is deserialized, we have to manually call rollbackAttributes on the ruleset.
        // It does not really rollback, just cleans the state.
        // Remove after https://github.com/intercom/intercom/issues/151064 gets closed.
        if (this.ruleset) {
          this.ruleset.rollbackAttributes();
        }
        return this;
      })
      .then(() => this);
  },

  rollbackAttributes() {
    this._super();
    this.ruleset.rollbackAttributes();
    this.visualBuilderObject?.rollbackAttributes();
  },
}).reopenClass({
  loadFullAnswer(answerId) {
    let store = getEmberDataStore();
    return store.findRecord('custom-answers/custom-answer', answerId, {
      reload: true,
      include:
        'example_questions,control_criteria,stats,ruleset,visual_builder_object,target_channels',
    });
  },
});
