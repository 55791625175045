/* RESPONSIBLE TEAM: team-ai-chatbot */

export interface ResolutionBotBehaviorVersionSummaryWireFormat {
  id: string;
  title: string;
}

export default class ResolutionBotBehaviorVersionSummary {
  readonly id: string;
  readonly title: string;

  constructor(id: string, title: string) {
    this.id = id;
    this.title = title;
  }

  static deserialize(
    json: ResolutionBotBehaviorVersionSummaryWireFormat,
  ): ResolutionBotBehaviorVersionSummary {
    return new ResolutionBotBehaviorVersionSummary(json.id, json.title);
  }
}
