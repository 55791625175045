/* RESPONSIBLE TEAM: team-phone */

import Model, { attr } from '@ember-data/model';

export enum PhoneNumberType {
  TollFree = 0,
  Local = 1,
  Mobile = 2,
}

export const PhoneNumberTypeToTwilioString = new Map<PhoneNumberType, string>([
  [PhoneNumberType.TollFree, 'toll-free'],
  [PhoneNumberType.Local, 'local'],
  [PhoneNumberType.Mobile, 'mobile'],
]);

export const TwilioStringToPhoneNumberType = new Map<string, PhoneNumberType>([
  ['toll-free', PhoneNumberType.TollFree],
  ['local', PhoneNumberType.Local],
  ['mobile', PhoneNumberType.Mobile],
]);

export const REGULATORY_BUNDLE_COUNTRY_CODES = new Map<string, { flag: string; prefix: string }>([
  ['AU', { flag: '🇦🇺', prefix: '+61' }],
  ['AT', { flag: '🇦🇹', prefix: '+43' }],
  ['CA', { flag: '🇨🇦', prefix: '+1' }],
  ['DE', { flag: '🇩🇪', prefix: '+49' }],
  ['FR', { flag: '🇫🇷', prefix: '+33' }],
  ['GB', { flag: '🇬🇧', prefix: '+44' }],
  ['IE', { flag: '🇮🇪', prefix: '+353' }],
  ['NL', { flag: '🇳🇱', prefix: '+31' }],
  ['NZ', { flag: '🇳🇿', prefix: '+64' }],
  ['SE', { flag: '🇸🇪', prefix: '+46' }],
  ['US', { flag: '🇺🇸', prefix: '+1' }],
  ['NO', { flag: '🇳🇴', prefix: '+47' }],
  ['FI', { flag: '🇫🇮', prefix: '+358' }],
  ['DK', { flag: '🇩🇰', prefix: '+45' }],
  ['BE', { flag: '🇧🇪', prefix: '+32' }],
  ['CH', { flag: '🇨🇭', prefix: '+41' }],
  ['LU', { flag: '🇱🇺', prefix: '+352' }],
]);

export interface PhoneNumberCountryDetails {
  flag: string;
  prefix: string;
  supportedNumberTypes: Array<PhoneNumberType>;
  privateOfferingTypes: Array<PhoneNumberType>;
  requiringBundleTypes: Array<PhoneNumberType>;
  requiringAddressTypes: Array<PhoneNumberType>;
  requiringLocalAddressTypes: Array<PhoneNumberType>;
}

export const PHONE_NUMBER_COUNTRY_DETAILS = new Map<string, PhoneNumberCountryDetails>([
  [
    'AU',
    {
      flag: '🇦🇺',
      prefix: '+61',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [],
      requiringBundleTypes: [PhoneNumberType.Mobile],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.Mobile,
        PhoneNumberType.TollFree,
      ],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
    },
  ],
  [
    'AT',
    {
      flag: '🇦🇹',
      prefix: '+43',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [],
      requiringBundleTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
    },
  ],
  [
    'BE',
    {
      flag: '🇧🇪',
      prefix: '+32',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.Mobile],
      requiringBundleTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
    },
  ],
  [
    'CA',
    {
      flag: '🇨🇦',
      prefix: '+1',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [],
      requiringBundleTypes: [],
      requiringAddressTypes: [],
      requiringLocalAddressTypes: [],
    },
  ],
  [
    'CH',
    {
      flag: '🇨🇭',
      prefix: '+41',
      supportedNumberTypes: [PhoneNumberType.Local],
      privateOfferingTypes: [],
      requiringBundleTypes: [PhoneNumberType.Local],
      requiringAddressTypes: [PhoneNumberType.Local],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
    },
  ],
  [
    'DE',
    {
      flag: '🇩🇪',
      prefix: '+49',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [PhoneNumberType.TollFree, PhoneNumberType.Mobile],
      requiringBundleTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
    },
  ],
  [
    'DK',
    {
      flag: '🇩🇰',
      prefix: '+45',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [PhoneNumberType.Local],
      requiringBundleTypes: [PhoneNumberType.Local],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
    },
  ],
  [
    'FI',
    {
      flag: '🇫🇮',
      prefix: '+358',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [PhoneNumberType.Local],
      requiringBundleTypes: [],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
    },
  ],
  [
    'FR',
    {
      flag: '🇫🇷',
      prefix: '+33',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
    },
  ],
  [
    'GB',
    {
      flag: '🇬🇧',
      prefix: '+44',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [],
      requiringBundleTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [],
    },
  ],
  [
    'IE',
    {
      flag: '🇮🇪',
      prefix: '+353',
      supportedNumberTypes: [PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.TollFree],
      requiringAddressTypes: [PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.TollFree],
    },
  ],
  [
    'LU',
    {
      flag: '🇱🇺',
      prefix: '+352',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
    },
  ],
  [
    'NL',
    {
      flag: '🇳🇱',
      prefix: '+31',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.Mobile],
      privateOfferingTypes: [PhoneNumberType.Local],
      requiringBundleTypes: [PhoneNumberType.Local, PhoneNumberType.Mobile],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.Mobile],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
    },
  ],
  [
    'NO',
    {
      flag: '🇳🇴',
      prefix: '+47',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
    },
  ],
  [
    'NZ',
    {
      flag: '🇳🇿',
      prefix: '+64',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.Local],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
    },
  ],
  [
    'SE',
    {
      flag: '🇸🇪',
      prefix: '+46',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.Local],
      requiringAddressTypes: [PhoneNumberType.Local],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
    },
  ],
  [
    'US',
    {
      flag: '🇺🇸',
      prefix: '+1',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [],
      requiringBundleTypes: [],
      requiringAddressTypes: [],
      requiringLocalAddressTypes: [],
    },
  ],
]);

export const COUNTRIES_LIST = Array.from(PHONE_NUMBER_COUNTRY_DETAILS.keys());

export const REGULATORY_BUNDLE_REQUIREMENTS_BY_COUNTRY = new Map<string, PhoneNumberType[]>([
  ['US', []],
  ['CA', []],
  ['GB', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['AU', [PhoneNumberType.Mobile]],
  ['FR', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['DE', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['IE', [PhoneNumberType.TollFree]],
  ['NZ', [PhoneNumberType.Local]],
  ['AT', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['NL', [PhoneNumberType.Local, PhoneNumberType.Mobile]],
  ['SE', [PhoneNumberType.Local]],
  ['NO', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['FI', []],
  ['DK', [PhoneNumberType.Local]],
  ['BE', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['CH', [PhoneNumberType.Local]],
  ['LU', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
]);

export const REGULATORY_ADDRESS_REQUIREMENTS_BY_COUNTRY = new Map<string, PhoneNumberType[]>([
  ['US', []],
  ['CA', []],
  ['GB', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['AU', [PhoneNumberType.Local, PhoneNumberType.Mobile, PhoneNumberType.TollFree]],
  ['FR', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['DE', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['IE', [PhoneNumberType.TollFree]],
  ['NZ', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['AT', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['NL', [PhoneNumberType.Local, PhoneNumberType.Mobile]],
  ['SE', [PhoneNumberType.Local]],
  ['NO', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['FI', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['DK', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['BE', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['CH', [PhoneNumberType.Local]],
  ['LU', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
]);

export const SUPPORTED_PHONE_NUMBER_TYPES_BY_COUNTRY = new Map<string, PhoneNumberType[]>([
  ['AU', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['AT', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['CA', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['DE', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['FR', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['GB', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['IE', [PhoneNumberType.TollFree]],
  ['NL', [PhoneNumberType.Local, PhoneNumberType.Mobile]],
  ['NZ', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['SE', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['US', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['NO', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['FI', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['DK', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['BE', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['CH', [PhoneNumberType.Local]],
  ['LU', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
]);

export const LOCAL_ADDRESS_REQUIREMENTS_BY_COUNTRY = new Map<string, PhoneNumberType[]>([
  ['US', []],
  ['CA', []],
  ['GB', []],
  ['AU', [PhoneNumberType.Local]],
  ['FR', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['DE', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['IE', [PhoneNumberType.TollFree]],
  ['NZ', [PhoneNumberType.Local]],
  ['AT', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['NL', [PhoneNumberType.Local]],
  ['SE', [PhoneNumberType.Local]],
  ['NO', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['FI', [PhoneNumberType.Local]],
  ['DK', [PhoneNumberType.Local, PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['BE', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['CH', [PhoneNumberType.Local]],
  ['LU', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
]);

export const PRIVATE_OFFERING_NUMBER_TYPES = new Map<string, PhoneNumberType[]>([
  ['DE', [PhoneNumberType.TollFree, PhoneNumberType.Mobile]],
  ['FR', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['IE', [PhoneNumberType.TollFree]],
  ['NL', [PhoneNumberType.Local]],
  ['SE', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['NO', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
  ['FI', [PhoneNumberType.Local]],
  ['DK', [PhoneNumberType.Local]],
  ['BE', [PhoneNumberType.Local, PhoneNumberType.Mobile]],
  ['LU', [PhoneNumberType.Local, PhoneNumberType.TollFree]],
]);

export type PhoneNumber = {
  country_iso_code: string;
  phone_number: string;
  twilio_sid: string;
  status: string;
};

export default class Calling extends Model {
  @attr('array') declare phoneNumbers: PhoneNumber[];
  @attr('boolean') declare recordingEnabled: boolean;
  @attr('string') declare recordingWarningMessage: string;
  @attr('boolean') declare inboundEnabled: boolean;
  @attr('boolean') declare outboundEnabled: boolean;
  @attr('string') declare welcomeMessage: string;
  @attr('string') declare assignTeamId: string;
  @attr('string') declare routingMethod: string;
  @attr('boolean') declare voicemailDeflectionEnabled: boolean;
  @attr('string') declare voicemailDeflectionGreeting: string;
  @attr('boolean') declare voicemailOooTriggerEnabled: boolean;
  @attr('boolean') declare voicemailMaxQueueSizeTriggerEnabled: boolean;
  @attr('number') declare voicemailMaxQueueSize: number;
  @attr('boolean') declare voicemailNoTeammatesAvailableTriggerEnabled: boolean;
  @attr('boolean') declare wrapUpTimeEnabled: boolean;
  @attr('string') declare wrapUpTimeUnit: string;
  @attr('number') declare wrapUpTimeAmount: number;
  @attr('boolean') declare messengerCallingEnabled: boolean;
  @attr('boolean') declare deleteRecordingAndTranscriptionEnabled: boolean;
  @attr('number') declare recordingAndTranscriptionRetentionPeriodInDays: number;
  @attr('number') declare trialCreditBalance: number;
  @attr('boolean') declare callingAllowed: boolean;
  @attr('boolean') declare unpaidCustomerExceededCallingLimit: boolean;
  @attr('boolean') declare callListeningEnabled: boolean;
}
