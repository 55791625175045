/* RESPONSIBLE TEAM: team-workflows */
import {
  createConversationDataAttributeItems,
  createUserDataAttributeItems,
} from 'embercom/lib/inbox/constants';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type Group from 'embercom/models/operator/visual-builder/group';
import type Step from 'embercom/models/operator/visual-builder/step';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { stepModelClasses, stepTypes } from '../configuration-list';
import type PathConfig from 'embercom/objects/visual-builder/configuration/path';
import { NewPhoneCallTargetPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import type IntlService from 'embercom/services/intl';
import { type CreateStepParams } from 'embercom/models/operator/visual-builder/step';

const DEFAULT_COMPONENT_TEMPLATE = 'workflows/graph-editor/popover-menu/popover-menu-item' as const;
const CATEGORY_CONFIG = {
  action: {
    iconBGClass: 'workflows__graph-editor__node-items__steps__action-icon-bg',
    iconColour: 'white',
  },
  message: {
    iconBGClass: 'workflows__graph-editor__node-items__steps__message-icon-bg',
    iconColour: 'black',
  },
  condition: {
    iconBGClass: 'workflows__graph-editor__node-items__steps__conditional-branches__if-branch-icon',
    iconColour: 'black',
  },
  existingPath: {
    iconBGClass: 'rounded-full shadow',
  },
  fin: {
    iconBGClass: 'workflows__graph-editor__node-items__steps__fin-icon-bg',
    iconColour: 'white',
  },
};

const inserterMenuItems = {
  ...stepTypes,
  // extra inserter-menu categories
  endBot: 'inserter-menu-item/end-bot',
  endHandover: 'inserter-menu-item/end-handover',
  existingPaths: 'inserter-menu-item/existing-paths',
};

const ADD_CONTENT_GROUP_NAME = 'add-content-to-path';
const ADD_CONVERSATION_ACTION_GROUP_NAME = 'add-action';
const ADD_INTEGRATION_ACTION_GROUP_NAME = 'add-integration-action';
const CONNECT_EXISTING_PATH_GROUP_NAME = 'connect-existing-path';
const PROCEED_TO_ANOTHER_PATH_GROUP_NAME = 'proceed-to-another-path';
const SET_CONVERSATION_DATA_GROUP_NAME = 'set-conversation-data';
const SET_USER_DATA_GROUP_NAME = 'set-user-data';

type GroupNameType =
  | typeof ADD_CONTENT_GROUP_NAME
  | typeof ADD_CONVERSATION_ACTION_GROUP_NAME
  | typeof ADD_INTEGRATION_ACTION_GROUP_NAME
  | typeof CONNECT_EXISTING_PATH_GROUP_NAME
  | typeof PROCEED_TO_ANOTHER_PATH_GROUP_NAME
  | typeof SET_CONVERSATION_DATA_GROUP_NAME
  | typeof SET_USER_DATA_GROUP_NAME;

type CustomHandlerFunction = (args: {
  connectionPoint: ConnectionPoint;
  editorState: EditorState;
}) => any;

type InserterMenuItemType = {
  text: string;
  value:
    | {
        customItemHandler?: CustomHandlerFunction;
        ModelClass?: typeof Step;
        stepCreationParams?: CreateStepParams;
      }
    | string;
  component: typeof DEFAULT_COMPONENT_TEMPLATE;
  groupConfig: { iconBGClass: string; iconColour?: string };
  groupName?: GroupNameType;
  icon?: string;
  svg?: string;
  isNew?: boolean; // Displays a 'New' tag in the inserter menu
  tooltip?: string; // Displays a Tooltip in the inserter menu
  onSelectItem?: () => void;
};

export {
  ADD_CONTENT_GROUP_NAME,
  ADD_CONVERSATION_ACTION_GROUP_NAME,
  ADD_INTEGRATION_ACTION_GROUP_NAME,
  CONNECT_EXISTING_PATH_GROUP_NAME,
  PROCEED_TO_ANOTHER_PATH_GROUP_NAME,
  SET_CONVERSATION_DATA_GROUP_NAME,
  SET_USER_DATA_GROUP_NAME,
  DEFAULT_COMPONENT_TEMPLATE,
  CATEGORY_CONFIG,
  GroupNameType,
  inserterMenuItems,
  CustomHandlerFunction,
  InserterMenuItemType,
};

type InserterMenuRecord = Record<
  string,
  (args: {
    app: any;
    intl: IntlService;
    store: any;
    attributeService: any;
    existingPaths: Group[];
    pathConfig: PathConfig;
  }) => InserterMenuItemType[] | undefined
>;

const INSERTER_MENU_ITEMS_MAP: InserterMenuRecord = {
  [inserterMenuItems.callback]: ({ intl }) => {
    return [
      {
        text: intl.t('operator.workflows.visual-builder.step-menu.callback'),
        icon: 'phone-missed',
        value: { ModelClass: stepModelClasses[stepTypes.callback] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.fin]: ({ app, intl }) => {
    return [
      {
        text: intl.t('operator.workflows.visual-builder.step-menu.fin'),
        icon: <boolean>app.canUseFinExperience ? 'fin' : 'bot-filled',
        value: { ModelClass: stepModelClasses[stepTypes.fin] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.fin,
        groupName: ADD_CONTENT_GROUP_NAME,
        'data-intercom-target': <boolean>app.canUseFinExperience
          ? 'vbb:let-fin-answer'
          : 'vbb:let-rb-answer',
      },
    ];
  },
  [inserterMenuItems.applyRules]: ({ intl }) => {
    return [
      {
        text: intl.t('operator.workflows.visual-builder.step-menu.apply-rules'),
        icon: 'left',
        value: { ModelClass: stepModelClasses[stepTypes.applyRules] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
        'data-intercom-target': 'vbb:apply-rules',
      },
    ];
  },
  [inserterMenuItems.addTagToConversation]: ({ intl }) => {
    return [
      {
        text: intl.t('operator.workflows.visual-builder.step-menu.add-tag-to-conversation'),
        icon: 'tag',
        value: { ModelClass: stepModelClasses[stepTypes.addTagToConversation] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.removeTagFromConversation]: ({ intl }) => {
    return [
      {
        text: intl.t('operator.workflows.visual-builder.step-menu.remove-conversation-tag'),
        icon: 'tag',
        value: { ModelClass: stepModelClasses[stepTypes.removeTagFromConversation] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.tagUser]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.tag-user'),
      icon: 'tag',
      value: { ModelClass: stepModelClasses[stepTypes.tagUser] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
    },
  ],
  [inserterMenuItems.removeTagFromUser]: ({ intl }) => {
    return [
      {
        text: intl.t('operator.workflows.visual-builder.step-menu.remove-person-tag'),
        icon: 'tag',
        value: { ModelClass: stepModelClasses[stepTypes.removeTagFromUser] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.setLanguageOverride]: ({ intl }) => {
    return [
      {
        text: intl.t('operator.workflows.visual-builder.step-menu.set-language-override'),
        icon: 'language',
        value: { ModelClass: stepModelClasses[stepTypes.setLanguageOverride] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.assignConversation]: ({ intl, pathConfig }) => {
    let text =
      pathConfig instanceof NewPhoneCallTargetPathConfig
        ? intl.t('operator.workflows.visual-builder.step-menu.hold-and-assign')
        : intl.t('operator.workflows.visual-builder.step-menu.assign-conversation');

    return [
      {
        text,
        icon: 'assignment',
        value: { ModelClass: stepModelClasses[stepTypes.assignConversation] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.snooze]: ({ app, intl }) => {
    return (
      (<boolean>app.canUseInboxRules && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.snooze'),
          icon: 'snooze',
          value: { ModelClass: stepModelClasses[stepTypes.snooze] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.wait]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.wait'),
      icon: 'wait',
      value: { ModelClass: stepModelClasses[stepTypes.wait] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
    },
  ],
  [inserterMenuItems.changeConversationPriority]: ({ app, intl }) => {
    return (
      (<boolean>app.canUsePriorityRules && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.change-conversation-priority'),
          icon: 'star',
          value: { ModelClass: stepModelClasses[stepTypes.changeConversationPriority] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
        } as InserterMenuItemType,
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.applyConversationSla]: ({ app, intl }) => {
    return (
      (<boolean>app.canUseInboxSlas && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.apply-conversation-sla'),
          icon: 'sla',
          value: { ModelClass: stepModelClasses[stepTypes.applyConversationSla] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.workflowConnector]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.workflow-connector'),
      icon: 'webhook',
      value: { ModelClass: stepModelClasses[stepTypes.workflowConnector] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
      'data-intercom-target': 'vbb:custom-action',
    },
  ],
  [inserterMenuItems.note]: ({ intl }) => {
    return [
      {
        text: intl.t('operator.workflows.visual-builder.step-menu.note'),
        icon: 'note',
        value: { ModelClass: stepModelClasses[stepTypes.note] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.hangUp]: ({ app, intl }) => {
    return (
      (<boolean>app.canUseInboundPhoneCall && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.hang-up'),
          icon: 'phone-end',
          value: { ModelClass: stepModelClasses[stepTypes.hangUp] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.phoneHoldAndAssign]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.new-hold-and-assign'),
      icon: 'assignment',
      value: { ModelClass: stepModelClasses[stepTypes.phoneHoldAndAssign] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
    },
  ],
  [inserterMenuItems.forwardCall]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.forward-call'),
      icon: 'phone-transfer',
      value: { ModelClass: stepModelClasses[stepTypes.forwardCall] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
    },
  ],
  [inserterMenuItems.phoneCallCsatRequest]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.phone-call-csat-request'),
      icon: 'lwr-happy',
      value: { ModelClass: stepModelClasses[stepTypes.phoneCallCsatRequest] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
    },
  ],
  [inserterMenuItems.voicemail]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.voicemail'),
      icon: 'phone-voicemail',
      value: { ModelClass: stepModelClasses[stepTypes.voicemail] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
    },
  ],
  [inserterMenuItems.disableComposer]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.disable-composer'),
      icon: 'remove',
      value: { ModelClass: stepModelClasses[stepTypes.disableComposer] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
      'data-intercom-target': 'vbb:disable-customer-reply',
    },
  ],
  [inserterMenuItems.closeConversation]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.close-conversation'),
      icon: 'check',
      value: { ModelClass: stepModelClasses[stepTypes.closeConversation] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
    },
  ],
  [inserterMenuItems.setTicketCustomState]: ({ intl }) => {
    return [
      {
        text: intl.t('operator.workflows.visual-builder.step-menu.set-ticket-custom-state'),
        icon: 'ticket',
        value: { ModelClass: stepModelClasses[stepTypes.setTicketCustomState] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.summarizeConversation]: ({ app, intl }) => {
    let isAutoSummarizeAvailable =
      app.hasSummarizationSettingEnabled || app.hasTextTransformationSettingEnabled;

    return (
      (isAutoSummarizeAvailable && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.summarize-conversation'),
          icon: 'ai',
          value: { ModelClass: stepModelClasses[stepTypes.summarizeConversation] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
          'data-intercom-target': 'vbb:summarize-conversation',
          tooltip: intl.t(
            'operator.workflows.visual-builder.step-menu.summarize-conversation-tooltip',
          ),
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.classifyConversationAttribute]: ({ app, intl }) => {
    let isClassificationAvailabile = app.canUseAiConversationClassification;

    return (
      (isClassificationAvailabile && [
        {
          text: intl.t(
            'operator.workflows.visual-builder.step-menu.classify-conversation-attribute',
          ),
          icon: 'ai',
          value: { ModelClass: stepModelClasses[stepTypes.classifyConversationAttribute] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
          tooltip: intl.t(
            'operator.workflows.visual-builder.step-menu.classify-conversation-attribute-tooltip',
          ),
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.chatMessage]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.chat-message'),
      icon: 'chat-filled',
      value: { ModelClass: stepModelClasses[stepTypes.chatMessage] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.message,
      groupName: ADD_CONTENT_GROUP_NAME,
    },
  ],
  [inserterMenuItems.convertToTicket]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.convert-to-ticket'),
      icon: 'ticket',
      value: { ModelClass: stepModelClasses[stepTypes.convertToTicket] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.message,
      groupName: ADD_CONTENT_GROUP_NAME,
    },
  ],
  [inserterMenuItems.attributeCollector]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.attribute-collector'),
      icon: 'chat-bubble',
      value: { ModelClass: stepModelClasses[stepTypes.attributeCollector] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.message,
      groupName: ADD_CONTENT_GROUP_NAME,
    },
  ],
  [inserterMenuItems.triggerWorkflow]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.trigger-workflow'),
      icon: 'bot-filled',
      value: { ModelClass: stepModelClasses[stepTypes.triggerWorkflow] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.message,
      groupName: ADD_CONTENT_GROUP_NAME,
      'data-intercom-target': 'vbb:pass-to-another-bot',
    },
  ],
  [inserterMenuItems.setExpectations]: ({ intl, pathConfig }) => {
    let text =
      pathConfig instanceof NewPhoneCallTargetPathConfig
        ? intl.t('operator.workflows.visual-builder.step-menu.expected-hold-time')
        : intl.t('operator.workflows.visual-builder.step-menu.set-expectations');

    return [
      {
        text,
        icon: 'clock',
        value: { ModelClass: stepModelClasses[stepTypes.setExpectations] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.message,
        groupName: ADD_CONTENT_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.freeInput]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.free-input'),
      icon: 'chat-bubble',
      value: { ModelClass: stepModelClasses[stepTypes.freeInput] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.message,
      groupName: ADD_CONTENT_GROUP_NAME,
    },
  ],
  [inserterMenuItems.conversationRatings]: ({ intl, app }) => {
    let tooltip = intl.t('operator.workflows.visual-builder.step-menu.workflow-ratings-tooltip');
    let dataTarget = 'vbb:workflows-csat';

    return (
      (<boolean>app.canUseConversationRatings && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.conversation-ratings'),
          icon: 'lwr-happy',
          value: { ModelClass: stepModelClasses[stepTypes.conversationRatings] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.message,
          groupName: ADD_CONTENT_GROUP_NAME,
          tooltip,
          'data-intercom-target': dataTarget,
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.sendTicket]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.send-ticket'),
      icon: 'ticket',
      value: { ModelClass: stepModelClasses[stepTypes.sendTicket] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.message,
      groupName: ADD_CONTENT_GROUP_NAME,
    },
  ],
  [inserterMenuItems.messengerApp]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.messenger-app'),
      icon: 'app-store',
      value: { ModelClass: stepModelClasses[stepTypes.messengerApp] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.message,
      groupName: ADD_CONTENT_GROUP_NAME,
    },
  ],
  [inserterMenuItems.replyButtons]: ({ intl, pathConfig }) => {
    let isForPhoneChannel = pathConfig instanceof NewPhoneCallTargetPathConfig;
    let text = isForPhoneChannel
      ? intl.t('operator.workflows.visual-builder.step-menu.reply-options')
      : intl.t('operator.workflows.visual-builder.step-menu.reply-buttons');
    let icon = pathConfig instanceof NewPhoneCallTargetPathConfig ? 'phone-keypad' : 'button-pill';

    return [
      {
        text,
        icon,
        value: {
          ModelClass: stepModelClasses[stepTypes.replyButtons],
          stepCreationParams: { createEmptyBlock: isForPhoneChannel },
        },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.condition,
        groupName: PROCEED_TO_ANOTHER_PATH_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.customObjectSelector]: ({ intl, pathConfig }) => {
    let text =
      pathConfig instanceof NewPhoneCallTargetPathConfig
        ? intl.t('operator.workflows.visual-builder.step-menu.reply-custom-object-options')
        : intl.t('operator.workflows.visual-builder.step-menu.custom-object-selector');
    let icon = pathConfig instanceof NewPhoneCallTargetPathConfig ? 'phone-keypad' : 'button-pill';

    return [
      {
        text,
        icon,
        value: { ModelClass: stepModelClasses[stepTypes.customObjectSelector] },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.condition,
        groupName: PROCEED_TO_ANOTHER_PATH_GROUP_NAME,
      },
    ];
  },
  [inserterMenuItems.conditionalBranches]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.conditional-branches'),
      icon: 'branch',
      value: { ModelClass: stepModelClasses[stepTypes.conditionalBranches] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.condition,
      groupName: PROCEED_TO_ANOTHER_PATH_GROUP_NAME,
      'data-intercom-target': 'vbb:conditional-branch',
    },
  ],
  [inserterMenuItems.endBot]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.end-bot'),
      icon: 'stop',
      value: {
        customItemHandler: ({ connectionPoint }) => (connectionPoint.isTerminal = true),
      },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
    },
  ],
  [inserterMenuItems.endHandover]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.end-handover'),
      icon: 'stop',
      value: {
        customItemHandler: ({ connectionPoint }) => (connectionPoint.isTerminal = true),
      },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
    },
  ],
  [inserterMenuItems.answerTerminal]: ({ intl }) => [
    {
      text: intl.t('operator.workflows.visual-builder.step-menu.answer-terminal'),
      icon: 'respond',
      value: { ModelClass: stepModelClasses[stepTypes.answerTerminal] },
      component: DEFAULT_COMPONENT_TEMPLATE,
      groupConfig: CATEGORY_CONFIG.action,
      groupName: ADD_CONVERSATION_ACTION_GROUP_NAME,
    },
  ],
  [inserterMenuItems.sendToSalesforce]: ({ app, intl }) => {
    return (
      (<boolean>app.hasSalesforceIntegration && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.send-to-salesforce'),
          icon: 'salesforce',
          value: { ModelClass: stepModelClasses[stepTypes.sendToSalesforce] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_INTEGRATION_ACTION_GROUP_NAME,
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.createSalesforceCase]: ({ app, intl }) => {
    return (
      (<boolean>app.hasSalesforceIntegration && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.create-salesforce-case'),
          icon: 'salesforce',
          value: { ModelClass: stepModelClasses[stepTypes.createSalesforceCase] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_INTEGRATION_ACTION_GROUP_NAME,
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.sendToHubspot]: ({ app, intl }) => {
    return (
      (<boolean>app.hasHubSpotIntegration && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.send-to-hubspot'),
          icon: 'hubspot',
          value: { ModelClass: stepModelClasses[stepTypes.sendToHubspot] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_INTEGRATION_ACTION_GROUP_NAME,
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.customActionTemplate]: ({ store }) => {
    let customActions = store.peekAll('workflow-connector/insertable-action');
    let customActionTemplates = customActions.filter((action: any) => action.isTemplate);

    return customActionTemplates.map((template: any) => {
      return {
        text: template.name,
        icon: 'webhook',
        value: {
          ModelClass: stepModelClasses[stepTypes.workflowConnector],
          stepCreationParams: { customAction: template, listTemplates: true },
        },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: ADD_INTEGRATION_ACTION_GROUP_NAME,
      };
    }, this);
  },
  [inserterMenuItems.sendToMarketo]: ({ app, intl }) => {
    return (
      (<boolean>app.hasMarketoEnrichmentIntegration && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.send-to-marketo'),
          icon: 'marketo',
          value: { ModelClass: stepModelClasses[stepTypes.sendToMarketo] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_INTEGRATION_ACTION_GROUP_NAME,
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.triggerMarketoCampaign]: ({ app, intl }) => {
    return (
      (<boolean>app.hasMarketoEnrichmentIntegration && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.trigger-marketo-campaign'),
          icon: 'marketo',
          value: { ModelClass: stepModelClasses[stepTypes.triggerMarketoCampaign] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_INTEGRATION_ACTION_GROUP_NAME,
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.notifySlackChannel]: ({ app, intl }) => {
    return (
      (<boolean>app.hasSlackNotificationsIntegration && [
        {
          text: intl.t('operator.workflows.visual-builder.step-menu.notify-slack-channel'),
          icon: 'slack',
          value: { ModelClass: stepModelClasses[stepTypes.notifySlackChannel] },
          component: DEFAULT_COMPONENT_TEMPLATE,
          groupConfig: CATEGORY_CONFIG.action,
          groupName: ADD_INTEGRATION_ACTION_GROUP_NAME,
        },
      ]) ||
      undefined
    );
  },
  [inserterMenuItems.setConversationDataAttribute]: ({ intl, attributeService }) => {
    let conversationCustomAttributeItems = createConversationDataAttributeItems(
      attributeService.nonArchivedNonSystemDefinedConversationCustomAttributes,
    ) as { name: string; attribute_identifier: string }[];
    return conversationCustomAttributeItems.map((attr) => {
      return {
        text: intl.t(
          'operator.workflows.visual-builder.step-menu.set-conversation-data-attribute',
          {
            name: attr.name,
          },
        ),
        icon: 'conversation',
        value: {
          ModelClass: stepModelClasses[stepTypes.setConversationDataAttribute],
          stepCreationParams: { attributeIdentifier: attr.attribute_identifier },
        },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: SET_CONVERSATION_DATA_GROUP_NAME,
      };
    }, this);
  },
  [inserterMenuItems.setUserDataAttribute]: ({ app, intl, attributeService }) => {
    if (!app.canUseSetUserDataAttribute) {
      return undefined;
    }

    let userDataAttributeItems = createUserDataAttributeItems(
      attributeService.visualBotBuilderSetUserDataAttributes,
    ) as { name: string; attribute_identifier: string }[];
    return userDataAttributeItems.map((attr) => {
      return {
        text: intl.t('operator.workflows.visual-builder.step-menu.set-user-data-attribute', {
          name: attr.name,
        }),
        icon: 'person',
        value: {
          ModelClass: stepModelClasses[stepTypes.setUserDataAttribute],
          stepCreationParams: { attributeIdentifier: attr.attribute_identifier },
        },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        groupName: SET_USER_DATA_GROUP_NAME,
      };
    }, this);
  },
  [inserterMenuItems.existingPaths]: ({ existingPaths }) => {
    return existingPaths.reduce((existingPathItems, group) => {
      return existingPathItems.concat({
        text: group.fullPathTitle,
        svg: 'series/edge-add-arrow',
        value: {
          customItemHandler: ({ connectionPoint, editorState }) =>
            editorState.createAndRenderEdge({
              toGroup: group,
              outwardConnectionPoint: connectionPoint,
            }),
        },
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.existingPath,
        groupName: CONNECT_EXISTING_PATH_GROUP_NAME,
      });
    }, [] as InserterMenuItemType[]);
  },
};

export default INSERTER_MENU_ITEMS_MAP;
