/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import TeamSummary, { type TeamSummaryWireFormat } from 'embercom/objects/inbox/team-summary';
import { EntityType } from 'embercom/models/data/entity-types';
import { OutboundInitialPartSenderType } from 'embercom/objects/inbox/outbound-initial-part-sender-types';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';
import { tracked } from '@glimmer/tracking';

export type SenderSummary = AdminSummary | TeamSummary;
type SenderSummaryWireFormat = AdminSummaryWireFormat | TeamSummaryWireFormat;
interface PostWireFormat {
  renderable_type: RenderableType;
  sender_summary: SenderSummaryWireFormat;
  sender_type: OutboundInitialPartSenderType;
  blocks: Array<object>;
  tags: Array<TagWireFormat>;
  ruleset_id?: number;
  selection_set_id?: number;
}

export default class Post implements RenderableData {
  readonly renderableType = RenderableType.Post;
  readonly senderSummary: SenderSummary;
  readonly senderType: OutboundInitialPartSenderType;
  readonly blocks: Array<object>;
  @tracked tags: Array<Tag>;
  readonly rulesetId?: number;
  readonly selectionSetId?: number;

  get teammate() {
    if (this.senderType === OutboundInitialPartSenderType.Admin) {
      return this.senderSummary;
    } else {
      return;
    }
  }

  get team() {
    if (this.senderType === OutboundInitialPartSenderType.Team) {
      return this.senderSummary;
    } else {
      return;
    }
  }

  get creatingEntity() {
    if (this.team) {
      return { type: EntityType.Team, id: this.senderSummary.id, object: this.team };
    } else {
      return { type: EntityType.Admin, id: this.senderSummary.id, object: this.teammate };
    }
  }

  get styles() {
    return {
      backgroundColor: 'bg-sky-dark dark:bg-dm-blue-dark',
      textColor: 'text-blue-darkest dark:text-dm-white',
      backgroundColorHover: 'bg-sky-darkest dark:bg-blue-darkest',
    };
  }

  constructor(
    blocks: Array<object>,
    senderSummary: SenderSummary,
    senderType: OutboundInitialPartSenderType,
    tags?: Array<Tag>,
    rulesetId?: number,
    selectionSetId?: number,
  ) {
    this.blocks = blocks;
    this.senderSummary = senderSummary;
    this.senderType = senderType;
    this.tags = tags || [];
    this.rulesetId = rulesetId;
    this.selectionSetId = selectionSetId;
  }

  static deserialize(json: PostWireFormat): Post {
    let senderSummary;
    if (json.sender_type === OutboundInitialPartSenderType.Team) {
      senderSummary = TeamSummary.deserialize(json.sender_summary as TeamSummaryWireFormat);
    } else {
      senderSummary = AdminSummary.deserialize(json.sender_summary as AdminSummaryWireFormat);
    }
    return new Post(
      json.blocks,
      senderSummary,
      json.sender_type,
      json.tags && json.tags.map(Tag.deserialize),
      json.ruleset_id,
      json.selection_set_id,
    );
  }
}
