/* RESPONSIBLE TEAM: team-frontend-tech */
import { getOwner } from '@ember/application';
import Model, { attr, hasMany } from '@ember-data/model';
import BannerValidations from 'embercom/validations/banner';
import bannerEditorConfigGenerator from 'embercom/objects/content-editor/configuration-generators/banner';
import { dependentKeyCompat } from '@ember/object/compat';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export const actionOptions = {
  no_action: 0,
  open_a_url: 1,
  reaction: 2,
  email_collector: 3,
  product_tour: 4,
};

export const actionTargetOptions = {
  current_tab: 0,
  new_tab: 1,
};

export const defaultReactions = [
  {
    index: 0,
    unicodeEmoticon: '👍',
  },
  {
    index: 1,
    unicodeEmoticon: '👎',
  },
];

export const positionOptions = {
  top: 0,
  bottom: 1,
};

export const styleOptions = {
  inline: 0,
  floating: 1,
};

export default class Banner extends Model.extend(BannerValidations) {
  @attr('number') action;
  @attr('number') actionTarget;
  @attr('string') color;
  @attr('boolean') dismissOnAction;
  @hasMany('banners/localized-banner-content', { inverse: 'banner' }) localizedBannerContents;
  @attr('number') position;
  @fragmentArray('messages/reaction') reactionSet;
  @attr('boolean') showDismissButton;
  @attr('number') style;

  @dependentKeyCompat
  get contentHash() {
    return this.localizedBannerContents.map(
      (localizedBannerContent) => localizedBannerContent.contentHash,
    );
  }

  editorConfig() {
    let container = getOwner(this);
    return bannerEditorConfigGenerator({ container, banner: this });
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.localizedBannerContents.any((localizedContent) => localizedContent.hasUnsavedChanges)
    );
  }

  get isBottomPositioned() {
    return this.position === positionOptions.bottom;
  }

  get isTopPositioned() {
    return this.position === positionOptions.top;
  }

  get isOpenAURLAction() {
    return this.action === actionOptions.open_a_url;
  }

  get isReactionAction() {
    return this.action === actionOptions.reaction;
  }

  get isEmailCollectorAction() {
    return this.action === actionOptions.email_collector;
  }

  get isProductTourAction() {
    return this.action === actionOptions.product_tour;
  }

  get localizedContents() {
    return this.localizedBannerContents;
  }

  get bannerPositionAsString() {
    return Object.keys(positionOptions).find(
      (position) => this.position === positionOptions[position],
    );
  }

  get bannerStyleAsString() {
    return Object.keys(styleOptions).find((style) => this.style === styleOptions[style]);
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this.localizedBannerContents.forEach((content) => content.rollbackAttributes());
  }
}
