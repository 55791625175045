/* RESPONSIBLE TEAM: team-ai-chatbot */
import { filterBy, notEmpty } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default Fragment.extend({
  words: fragmentArray('custom-answers/control-word'),

  emptyWords: filterBy('words', 'isEmpty', true),
  hasMissingWords: notEmpty('emptyWords'),
});
