/* RESPONSIBLE TEAM: team-workflows */

// Step Model Classes
import Callback from 'embercom/models/operator/visual-builder/step/callback';
import RemoveTagFromConversation from 'embercom/models/operator/visual-builder/step/remove-tag-from-conversation';
import SetTicketState from 'embercom/models/operator/visual-builder/step/set-ticket-state';
import SetTicketCustomState from 'embercom/models/operator/visual-builder/step/set-ticket-custom-state';
import SummarizeConversation from 'embercom/models/operator/visual-builder/step/summarize-conversation';
import ClassifyConversationAttribute from 'embercom/models/operator/visual-builder/step/classify-conversation-attribute';
import Wait from 'embercom/models/operator/visual-builder/step/wait';
import Snooze from 'embercom/models/operator/visual-builder/step/snooze';
import ConversationRatings from 'embercom/models/operator/visual-builder/step/conversation-ratings';
import SetExpectations from 'embercom/models/operator/visual-builder/step/set-expectations';
import AddTagToConversation from 'embercom/models/operator/visual-builder/step/add-tag-to-conversation';
import AnswerBot from 'embercom/models/operator/visual-builder/step/answer-bot';
import Fin from 'embercom/models/operator/visual-builder/step/fin';
import AnswerTerminal from 'embercom/models/operator/visual-builder/step/answer-terminal';
import ApplyConversationSla from 'embercom/models/operator/visual-builder/step/apply-conversation-sla';
import AssignConversation from 'embercom/models/operator/visual-builder/step/assign-conversation';
import AssignConversationToOwner from 'embercom/models/operator/visual-builder/step/assign-conversation-to-owner';
import AttributeCollector from 'embercom/models/operator/visual-builder/step/attribute-collector';
import ChangeConversationPriority from 'embercom/models/operator/visual-builder/step/change-conversation-priority';
import ChatMessage from 'embercom/models/operator/visual-builder/step/chat-message';
import CloseConversation from 'embercom/models/operator/visual-builder/step/close-conversation';
import ConditionalBranches from 'embercom/models/operator/visual-builder/step/conditional-branches';
import CreateSalesforceCase from 'embercom/models/operator/visual-builder/step/create-salesforce-case';
import CustomObjectSelector from 'embercom/models/operator/visual-builder/step/custom-object-selector';
import DisableComposer from 'embercom/models/operator/visual-builder/step/disable-composer';
import FreeInput from 'embercom/models/operator/visual-builder/step/free-input';
import MessengerApp from 'embercom/models/operator/visual-builder/step/messenger-app';
import ReplyButtons from 'embercom/models/operator/visual-builder/step/reply-buttons';
import SendTicket from 'embercom/models/operator/visual-builder/step/send-ticket';
import SendToHubspot from 'embercom/models/operator/visual-builder/step/send-to-hubspot';
import SendToMarketo from 'embercom/models/operator/visual-builder/step/send-to-marketo';
import SendToSalesforce from 'embercom/models/operator/visual-builder/step/send-to-salesforce';
import SetConversationDataAttribute from 'embercom/models/operator/visual-builder/step/set-conversation-data-attribute';
import SetLanguageOverride from 'embercom/models/operator/visual-builder/step/set-language-override';
import TagUser from 'embercom/models/operator/visual-builder/step/tag-user';
import TriggerMarketoCampaign from 'embercom/models/operator/visual-builder/step/trigger-marketo-campaign';
import TriggerWorkflow from 'embercom/models/operator/visual-builder/step/trigger-workflow';
import WorkflowConnector from 'embercom/models/operator/visual-builder/step/workflow-connector';
import ApplyRules from 'embercom/models/operator/visual-builder/step/apply-rules';
import Note from 'embercom/models/operator/visual-builder/step/note';
import NotifySlackChannel from 'embercom/models/operator/visual-builder/step/notify-slack-channel';
import HangUp from 'embercom/models/operator/visual-builder/step/hang-up';
import HoldAndAssign from 'embercom/models/operator/visual-builder/step/hold-and-assign';
import PhoneHoldAndAssign from 'embercom/models/operator/visual-builder/step/phone-hold-and-assign';
import ForwardCall from 'embercom/models/operator/visual-builder/step/forward-call';
import GithubCreateIssue from 'embercom/models/operator/visual-builder/step/github-create-issue';
import CustomActionTemplate from 'embercom/models/operator/visual-builder/step/custom-action-template';
import ConvertToTicket from 'embercom/models/operator/visual-builder/step/convert-to-ticket';
import PhoneCallCsatRequest from 'embercom/models/operator/visual-builder/step/phone-call-csat-request';
import Voicemail from 'embercom/models/operator/visual-builder/step/voicemail';
import RemoveTagFromUser from 'embercom/models/operator/visual-builder/step/remove-tag-from-user';
import SetUserDataAttribute from 'embercom/models/operator/visual-builder/step/set-user-data-attribute';

export type StepNames = ValueOf<typeof stepTypes>;

type KeysOfStepTypes = keyof typeof stepTypes;
export type StepType = { [K in KeysOfStepTypes]: string };

export const stepTypes = {
  callback: 'callback',
  wait: 'wait',
  snooze: 'snooze',
  conversationRatings: 'conversation-ratings',
  addTagToConversation: 'add-tag-to-conversation',
  answerBot: 'answer-bot',
  fin: 'fin',
  answerTerminal: 'answer-terminal',
  applyConversationSla: 'apply-conversation-sla',
  assignConversation: 'assign-conversation',
  assignConversationToOwner: 'assign-conversation-to-owner',
  attributeCollector: 'attribute-collector',
  changeConversationPriority: 'change-conversation-priority',
  chatMessage: 'chat-message',
  closeConversation: 'close-conversation',
  conditionalBranches: 'conditional-branches',
  applyRules: 'apply-rules',
  createSalesforceCase: 'create-salesforce-case',
  customObjectSelector: 'custom-object-selector',
  disableComposer: 'disable-composer',
  freeInput: 'free-input',
  messengerApp: 'messenger-app',
  replyButtons: 'reply-buttons',
  sendTicket: 'send-ticket',
  sendToHubspot: 'send-to-hubspot',
  sendToMarketo: 'send-to-marketo',
  sendToSalesforce: 'send-to-salesforce',
  setConversationDataAttribute: 'set-conversation-data-attribute',
  setUserDataAttribute: 'set-user-data-attribute',
  setExpectations: 'set-expectations',
  setLanguageOverride: 'set-language-override',
  tagUser: 'tag-user',
  triggerMarketoCampaign: 'trigger-marketo-campaign',
  triggerWorkflow: 'trigger-workflow',
  workflowConnector: 'workflow-connector',
  note: 'note',
  setTicketState: 'set-ticket-state',
  setTicketCustomState: 'set-ticket-custom-state',
  summarizeConversation: 'summarize-conversation',
  classifyConversationAttribute: 'classify-conversation-attribute',
  notifySlackChannel: 'notify-slack-channel',
  hangUp: 'hang-up',
  holdAndAssign: 'hold-and-assign',
  phoneHoldAndAssign: 'phone-hold-and-assign',
  forwardCall: 'forward-call',
  githubCreateIssue: 'github-create-issue',
  customActionTemplate: 'custom-action-template',
  removeTagFromConversation: 'remove-tag-from-conversation',
  removeTagFromUser: 'remove-tag-from-user',
  convertToTicket: 'convert-to-ticket',
  phoneCallCsatRequest: 'phone-call-csat-request',
  voicemail: 'voicemail',
} as const;

export const stepModelClasses = {
  [stepTypes.callback]: Callback,
  [stepTypes.wait]: Wait,
  [stepTypes.snooze]: Snooze,
  [stepTypes.conversationRatings]: ConversationRatings,
  [stepTypes.addTagToConversation]: AddTagToConversation,
  [stepTypes.answerBot]: AnswerBot,
  [stepTypes.fin]: Fin,
  [stepTypes.answerTerminal]: AnswerTerminal,
  [stepTypes.applyConversationSla]: ApplyConversationSla,
  [stepTypes.assignConversation]: AssignConversation,
  [stepTypes.assignConversationToOwner]: AssignConversationToOwner,
  [stepTypes.attributeCollector]: AttributeCollector,
  [stepTypes.changeConversationPriority]: ChangeConversationPriority,
  [stepTypes.chatMessage]: ChatMessage,
  [stepTypes.closeConversation]: CloseConversation,
  [stepTypes.conditionalBranches]: ConditionalBranches,
  [stepTypes.applyRules]: ApplyRules,
  [stepTypes.createSalesforceCase]: CreateSalesforceCase,
  [stepTypes.customObjectSelector]: CustomObjectSelector,
  [stepTypes.disableComposer]: DisableComposer,
  [stepTypes.freeInput]: FreeInput,
  [stepTypes.messengerApp]: MessengerApp,
  [stepTypes.replyButtons]: ReplyButtons,
  [stepTypes.sendTicket]: SendTicket,
  [stepTypes.sendToHubspot]: SendToHubspot,
  [stepTypes.sendToMarketo]: SendToMarketo,
  [stepTypes.sendToSalesforce]: SendToSalesforce,
  [stepTypes.setConversationDataAttribute]: SetConversationDataAttribute,
  [stepTypes.setUserDataAttribute]: SetUserDataAttribute,
  [stepTypes.setExpectations]: SetExpectations,
  [stepTypes.setLanguageOverride]: SetLanguageOverride,
  [stepTypes.summarizeConversation]: SummarizeConversation,
  [stepTypes.classifyConversationAttribute]: ClassifyConversationAttribute,
  [stepTypes.tagUser]: TagUser,
  [stepTypes.triggerMarketoCampaign]: TriggerMarketoCampaign,
  [stepTypes.triggerWorkflow]: TriggerWorkflow,
  [stepTypes.workflowConnector]: WorkflowConnector,
  [stepTypes.note]: Note,
  [stepTypes.setTicketState]: SetTicketState,
  [stepTypes.setTicketCustomState]: SetTicketCustomState,
  [stepTypes.notifySlackChannel]: NotifySlackChannel,
  [stepTypes.hangUp]: HangUp,
  [stepTypes.convertToTicket]: ConvertToTicket,
  [stepTypes.githubCreateIssue]: GithubCreateIssue,
  [stepTypes.customActionTemplate]: CustomActionTemplate,
  [stepTypes.holdAndAssign]: HoldAndAssign,
  [stepTypes.phoneHoldAndAssign]: PhoneHoldAndAssign,
  [stepTypes.forwardCall]: ForwardCall,
  [stepTypes.removeTagFromConversation]: RemoveTagFromConversation,
  [stepTypes.phoneCallCsatRequest]: PhoneCallCsatRequest,
  [stepTypes.voicemail]: Voicemail,
  [stepTypes.removeTagFromUser]: RemoveTagFromUser,
};
