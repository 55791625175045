/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { isEqual } from '@ember/utils';
import { computed } from '@ember/object';
import { alias, and, bool, sort, readOnly, equal, not, notEmpty, or } from '@ember/object/computed';
import { post } from 'embercom/lib/ajax';
import promise from 'embercom/lib/promise';
import { inject as service } from '@ember/service';

export default Model.extend({
  appService: service(),
  app: readOnly('appService.app'),
  intl: service(),

  helpCenterService: service(),

  name: attr('string'),
  description: attr('string'),
  icon: attr('string', { defaultValue: 'folder' }),
  externalIconUrl: attr('string'),
  order: attr('number'),
  helpCenterId: attr('string'),
  count: attr('number'),
  readOnly: attr('boolean'),
  read_only: readOnly('readOnly'),
  siteProvider: attr('string'),

  children: hasMany('articles/article-group', { inverse: 'parent' }),
  parent: belongsTo('articles/article-group', { inverse: 'children' }),

  articles: hasMany('article-multilingual', { inverse: 'folder', async: true }),
  containedArticles: hasMany('article-multilingual', { inverse: 'inCollections', async: true }),
  localizedContent: fragmentArray('articles/article-group-content'),

  sortedArticles: sort('articles', 'sortOrder'),
  sortedChildren: sort('children', 'sortOrder'),
  sortedSections: readOnly('sortedChildren'),
  sortedSiblings: readOnly('parent.sortedChildren'),
  sortOrder: ['order'],

  isHome: equal('id', 'home'),
  isNotHome: not('isHome'),
  hasChildren: notEmpty('children'),
  hasArticles: notEmpty('articles'),
  hasContent: or('hasChildren', 'hasArticles'),
  shouldFocusName: false,

  allArticles: computed('articles', 'children.[]', function () {
    let allArticles = this.articles.toArray();
    this.children.forEach((section) => {
      allArticles = allArticles.concat(section.get('articles').toArray());
    });
    return allArticles;
  }),

  isFirstSection: computed('parent.sortedSections.[]', function () {
    return isEqual(this.get('sortedSiblings.firstObject.id'), this.id);
  }),

  isLastSection: computed('parent.sortedSections.[]', function () {
    return isEqual(this.get('sortedSiblings.lastObject.id'), this.id);
  }),

  isCollection: equal('parent.id', 'home'),
  isSection: not('isCollection'),
  isANonHomeSection: and('isNotHome', 'isSection'),

  type: computed('isCollection', function () {
    if (this.isHome) {
      return 'home';
    }

    if (this.isCollection) {
      return 'collection';
    }

    if (this.parent.get('parent.id') === 'home') {
      return 'section';
    }

    return 'sub-section';
  }),

  titleizedLocale: computed('defaultLocale', function () {
    return this.defaultLocaleId
      .split('-')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1, part.length))
      .join('-');
  }),

  defaultLocaleId: alias('helpCenterService.site.locale'),
  defaultLocalizedContent: computed('localizedContent.[]', 'defaultLocaleId', function () {
    return this.localizedContentByLocaleId(this.defaultLocaleId);
  }),
  hasDefaultLocalizedName: bool('defaultLocalizedContent.name'),
  hasDefaultLocalizedDescription: bool('defaultLocalizedContent.description'),
  defaultNameWithFallback: computed('defaultLocalizedContent.name', function () {
    return this.hasDefaultLocalizedName
      ? this.defaultLocalizedContent.name
      : this.missingNamePlaceholder;
  }),

  selectedLocaleId: alias('helpCenterService.currentlySelectedLocaleId'),
  selectedLocalizedContent: computed('localizedContent.[]', 'selectedLocaleId', function () {
    return this.localizedContentByLocaleId(this.selectedLocaleId);
  }),
  hasSelectedLocalizedName: bool('selectedLocalizedContent.name'),
  hasSelectedLocalizedDescription: bool('selectedLocalizedContent.description'),

  missingNamePlaceholder: computed('intl.locale', function () {
    return this.intl.t('articles.collections.list.placeholder.missing_name');
  }),
  missingDescriptionPlaceholder: computed('intl.locale', function () {
    return this.intl.t('articles.collections.list.placeholder.missing_description');
  }),

  localizedContentByLocaleId(localeId) {
    return this.localizedContent.findBy('localeId', localeId);
  },

  reorder(newIndex, positionMap = {}, helpCenterId = null) {
    return post(`/ember/article_folders/${this.id}/reorder`, {
      order: newIndex,
      position_map: positionMap,
      help_center_id: helpCenterId,
      app_id: this.app.id,
    });
  },

  saveIfDirty() {
    if (this.hasDirtyAttributes && !this.isSaving) {
      return this.save();
    } else {
      return promise();
    }
  },

  createContent(localeId) {
    if (!this.localizedContentByLocaleId(localeId)) {
      this.localizedContent.createFragment({
        localeId,
      });
    }
  },

  initLocalizedContent() {
    this.helpCenterService.site.selectedLocales.map((locale) => {
      this.createContent(locale.localeId);
    });
  },
}).reopenClass({
  createNewFolder(parent, prefix = 'Section heading', helpCenterId = null) {
    let newFolderName = `${prefix}`;
    let existingFolders = parent.get('children');
    let nameInUse = false;
    let nextIndex = 1;

    do {
      nameInUse = existingFolders.findBy('name', newFolderName);
      if (nameInUse) {
        newFolderName = `${prefix} (${nextIndex++})`;
      }
    } while (nameInUse);

    let maxOrder = Math.max(...existingFolders.map((f) => f.get('order')));
    let folder = parent.store.createRecord('articles/article-group', {
      name: newFolderName,
      order: maxOrder + 1,
      helpCenterId,
    });
    folder.set('parent', parent);
    return folder;
  },
});
