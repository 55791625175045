/* RESPONSIBLE TEAM: team-tickets-1 */

import {
  type ConversationAttributeDescriptorListOption,
  DataType,
} from 'embercom/objects/inbox/conversation-attribute-descriptor';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export type TeamId = string;
export type TicketCustomStateId = number;

export interface TicketDescriptorWireFormat {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly data_type: DataType;
  readonly list_options: Array<ConversationAttributeDescriptorListOption> | null;
  readonly multiline: boolean | null;
  readonly allow_multiple_values: boolean | null;
  readonly archived: boolean;
  readonly archived_at: Date | null;
  readonly archived_by_admin_id: number | null;
  readonly created_at: Date;
  readonly domain_object_type_id: number | null;
  readonly is_always_visible: number;
  readonly order: number;
  readonly required: boolean | null;
  readonly required_to_create: boolean | null;
  readonly ticket_type_id: number;
  readonly updated_at: Date;
  readonly visible_to_team_ids: Array<TeamId>;
  readonly visible_on_create: boolean | null;
  readonly visible_to_users: boolean | null;
  readonly is_built_in: boolean | false;
  readonly is_listed_as_attribute: boolean | true;
  readonly icon?: InterfaceIconName | undefined;
}

export interface TicketTypeWireFormat {
  readonly id: number;
  readonly name: string;
  readonly description: string | null;
  readonly descriptors: Array<TicketDescriptorWireFormat> | null;
  readonly emoji: string | null;
  readonly archived: boolean;
  readonly internal: boolean;
  readonly disable_ai_assist: boolean;
  readonly category: TicketCategory;
  readonly icon?: string;
  readonly ticket_custom_state_ids: Array<TicketCustomStateId>;
}

export class TicketDescriptor {
  readonly id: number;
  readonly name: string;
  readonly description: string | null;
  readonly dataType: DataType;
  readonly type: DataType;
  readonly listOptions: Array<ConversationAttributeDescriptorListOption> | null;
  readonly multiline: boolean | null;
  readonly allowMultipleValues: boolean | null;
  readonly archived: boolean;
  readonly archivedAt: Date | null;
  readonly archivedByAdminId: number | null;
  readonly createdAt: Date;
  readonly domainObjectTypeId: number | null;
  readonly isAlwaysVisible: number;
  readonly order: number;
  readonly required: boolean | null;
  readonly requiredToCreate: boolean | null;
  readonly ticketTypeId: number;
  readonly updatedAt: Date;
  readonly visibleToTeamIds: Array<TeamId>;
  readonly visibleOnCreate: boolean | null;
  readonly visibleToUsers: boolean | null;
  readonly isBuiltIn: boolean | false;
  readonly isListedAsAttribute: boolean | true;
  readonly icon?: InterfaceIconName;

  constructor(inputs: {
    id: number;
    name: string;
    description: string | null;
    dataType: DataType;
    listOptions: Array<ConversationAttributeDescriptorListOption> | null;
    multiline: boolean | null;
    allowMultipleValues: boolean | null;
    archived: boolean;
    archivedAt: Date | null;
    archivedByAdminId: number | null;
    createdAt: Date;
    domainObjectTypeId: number | null;
    isAlwaysVisible: number;
    order: number;
    required: boolean | null;
    requiredToCreate: boolean | null;
    updatedAt: Date;
    ticketTypeId: number;
    visibleToTeamIds: Array<TeamId>;
    visibleOnCreate: boolean | null;
    visibleToUsers: boolean | null;
    isBuiltIn: boolean | false;
    isListedAsAttribute: boolean | true;
    icon?: InterfaceIconName;
  }) {
    this.id = inputs.id;
    this.name = inputs.name;
    this.description = inputs.description;
    this.dataType = inputs.dataType;
    this.listOptions = inputs.listOptions;
    this.multiline = inputs.multiline;
    this.allowMultipleValues = inputs.allowMultipleValues;
    this.archived = inputs.archived;
    this.archivedAt = inputs.archivedAt;
    this.archivedByAdminId = inputs.archivedByAdminId;
    this.createdAt = inputs.createdAt;
    this.domainObjectTypeId = inputs.domainObjectTypeId;
    this.isAlwaysVisible = inputs.isAlwaysVisible;
    this.order = inputs.order;
    this.required = inputs.required;
    this.requiredToCreate = inputs.requiredToCreate;
    this.updatedAt = inputs.updatedAt;
    this.ticketTypeId = inputs.ticketTypeId;
    this.visibleToTeamIds = inputs.visibleToTeamIds;
    this.visibleOnCreate = inputs.visibleOnCreate;
    this.type = inputs.dataType;
    this.visibleToUsers = inputs.visibleToUsers;
    this.isBuiltIn = inputs.isBuiltIn;
    this.isListedAsAttribute = inputs.isListedAsAttribute;
    this.icon = inputs.icon;
  }

  static deserialize(json: TicketDescriptorWireFormat) {
    return new TicketDescriptor({
      id: json.id,
      name: json.name,
      description: json.description,
      dataType: json.data_type,
      listOptions: json.list_options,
      multiline: json.multiline,
      allowMultipleValues: json.allow_multiple_values,
      archived: json.archived,
      archivedAt: json.archived_at,
      archivedByAdminId: json.archived_by_admin_id,
      createdAt: json.created_at,
      domainObjectTypeId: json.domain_object_type_id,
      isAlwaysVisible: json.is_always_visible,
      order: json.order,
      required: json.required,
      requiredToCreate: json.required_to_create,
      ticketTypeId: json.ticket_type_id,
      updatedAt: json.updated_at,
      visibleToTeamIds: json.visible_to_team_ids,
      visibleOnCreate: json.visible_on_create,
      visibleToUsers: json.visible_to_users,
      isBuiltIn: json.is_built_in,
      isListedAsAttribute: json.is_listed_as_attribute,
      icon: json.icon,
    });
  }

  get isFilesType() {
    return this.type === DataType.Files;
  }

  get isDynamicHeight(): boolean {
    return this.multiline || this.type === DataType.Files;
  }
}

export enum TicketCategory {
  Task = 'task',
  Request = 'request',
  Tracker = 'tracker',
}

export class TicketType {
  readonly id: number;
  readonly name: string;
  readonly description: string | null;
  readonly descriptors: Array<TicketDescriptor> | null;
  readonly emoji: string | null;
  readonly archived: boolean;
  readonly internal: boolean;
  readonly disableAiAssist: boolean;
  readonly category: TicketCategory;
  readonly ticketCustomStateIds: Array<TicketCustomStateId>;

  constructor(inputs: {
    id: number;
    name: string;
    description: string | null;
    emoji: string | null;
    descriptors: Array<TicketDescriptor> | null;
    archived: boolean;
    internal: boolean;
    disableAiAssist: boolean;
    category: TicketCategory;
    ticketCustomStateIds: Array<TicketCustomStateId>;
  }) {
    this.id = inputs.id;
    this.name = inputs.name;
    this.description = inputs.description;
    this.descriptors = inputs.descriptors;
    this.emoji = inputs.emoji;
    this.archived = inputs.archived;
    this.internal = inputs.internal;
    this.disableAiAssist = inputs.disableAiAssist;
    this.category = inputs.category;
    this.ticketCustomStateIds = inputs.ticketCustomStateIds;
  }

  static deserialize(json: TicketTypeWireFormat) {
    return new TicketType({
      id: json.id,
      name: json.name,
      description: json.description,
      descriptors: json.descriptors?.map(TicketDescriptor.deserialize) || null,
      emoji: json.emoji,
      archived: json.archived,
      internal: json.internal,
      category: json.category,
      disableAiAssist: json.disable_ai_assist,
      ticketCustomStateIds: json.ticket_custom_state_ids,
    });
  }

  get isTrackerTicketType() {
    return this.category === TicketCategory.Tracker;
  }

  get isCustomerTicketType() {
    return this.category === TicketCategory.Request;
  }

  get isTaskTicketType() {
    return this.category === TicketCategory.Task;
  }

  get emojiUri() {
    if (!this.emoji) {
      return undefined;
    }

    return intermoji.twemojiSVGUri(this.emoji);
  }
}
