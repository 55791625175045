/* RESPONSIBLE TEAM: team-growth-opportunities */

import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import {
  CORE_ACCELERATE_ID,
  CORE_ENGAGE_PRO_ID,
  CORE_SCALE_ID,
  CORE_SUPPORT_PRO_ID,
  VALUE_BASED_PRICING_FEB2022_PRICING_MODEL,
  VBP_1_X_PRICING_MODELS,
  VBP_2_X_EARLY_STAGE_PRICING_MODELS,
  VBP_2_X_PRICING_MODELS,
  PRICING_5_1_PRICING_MODEL,
  PRICING_5_EARLY_STAGE_YEAR_2_PRICING_MODEL,
  PRICING_5_EARLY_STAGE_YEAR_3_PRICING_MODEL,
  PRICING_5_X_PRICING_MODELS,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_EARLY_STAGE_BUNDLE,
  FIN_AI_COPILOT_BASE_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  PRODUCT_TOURS_ESSENTIAL_ID,
} from 'embercom/lib/billing';
import ImmutableProduct from 'embercom/lib/immutable-product';

export default class EarlyStageGraduation extends Model {
  @attr() app_id;
  @attr('date') graduationDate;
  @attr('date') graduationProcessBeganAt;
  @attr('date') migratedAt;
  @attr('array') planIds;
  @attr('array') recommendedPlanIds;
  @attr('string') priceSetIdentifier;
  @attr('number') confirmedByAdminId;
  @attr('date') confirmedAt;
  @hasMany('products') productsForGraduatingPricingModel;
  @belongsTo('price', { async: false, inverse: null }) futurePrice;
  @fragment('billing/early-stage-step') earlyStageStep;
  @fragmentArray('billing/early-stage-program-data') programOverviewData;
  @service customerService;

  get isOnSteppedGraduation() {
    return !!this.earlyStageStep?.isOnSteppedGraduation;
  }

  get isOnSteppedGraduationVbp1_X() {
    return this.isOnSteppedGraduation && this.customerService.onVbp1_X;
  }

  get isOnSteppedGraduationVbp2_X() {
    return this.isOnSteppedGraduation && this.customerService.onVbp2_X_EarlyStage;
  }

  get pricingModelForNonEarlyStagePlans() {
    if (this.pricing5_X_Graduation) {
      return PRICING_5_1_PRICING_MODEL;
    } else if (this.vbp2_0_EarlyStageGraduation) {
      return VALUE_BASED_PRICING_FEB2022_PRICING_MODEL;
    } else {
      return this.priceSetIdentifier;
    }
  }

  get pricingModelForEarlyStagePlan() {
    if (this.pricing5_X_Graduation) {
      if (this.nextStepYearNumber === 2) {
        return PRICING_5_EARLY_STAGE_YEAR_2_PRICING_MODEL;
      } else {
        return PRICING_5_EARLY_STAGE_YEAR_3_PRICING_MODEL;
      }
    } else {
      return this.customerService.currentPricingModelIdentifier;
    }
  }

  get vbpGraduation() {
    return this.vbp1_X_Graduation || this.allVbp2_X_Graduation || this.pricing5_X_Graduation;
  }

  get allVbp2_X_Graduation() {
    return this.vbp2_X_Graduation || this.vbp2_0_EarlyStageGraduation;
  }

  get vbp2_X_Graduation() {
    // VBP 2.0, VBP 2.1,
    return VBP_2_X_PRICING_MODELS.includes(this.priceSetIdentifier);
  }

  get pricing5_X_Graduation() {
    return PRICING_5_X_PRICING_MODELS.includes(this.priceSetIdentifier);
  }

  get vbp2_0_EarlyStageGraduation() {
    return VBP_2_X_EARLY_STAGE_PRICING_MODELS.includes(this.priceSetIdentifier);
  }

  get vbp1_X_Graduation() {
    return VBP_1_X_PRICING_MODELS.includes(this.priceSetIdentifier);
  }

  get immutableProductsForGraduatingPricingModel() {
    return this.productsForGraduatingPricingModel.map((product) =>
      ImmutableProduct.create({ content: product }),
    );
  }

  get plans() {
    return this.immutableProductsForGraduatingPricingModel.mapBy('plans').flat();
  }

  get addons() {
    return this.immutableProductsForGraduatingPricingModel.filterBy('addon');
  }

  get earlyStagePlanIds() {
    if (this.pricing5_X_Graduation) {
      return PRICING_5_EARLY_STAGE_BUNDLE.map((planId) => Number(planId));
    }
    return this.customerService.activePlanIds;
  }

  get salesRecommendedPlans() {
    if (this.isOnSteppedGraduationVbp1_X) {
      return this.recommendedPlanIds.includes(parseInt(CORE_SCALE_ID, 10));
    } else if (this.customerService.onVbp2_X_EarlyStage || this.pricing5_X_Graduation) {
      return false;
    } else {
      return this.recommendedPlanIds.some((planId) => this.corePlanIds.includes(planId));
    }
  }

  get inProgress() {
    return this.graduationProcessBeganAt && !this.migratedAt;
  }

  get isGraduatingToEarlyStage() {
    if (this.isOnSteppedGraduation) {
      if (this.pricing5_X_Graduation) {
        return this.priceSetIdentifier === this.pricingModelForEarlyStagePlan;
      } else {
        return this.corePlanIsSelectedForGraduation;
      }
    }
  }

  get progressionConfirmedByCustomer() {
    return this.confirmedByAdminId && this.confirmedAt;
  }

  get corePlanIsSelectedForGraduation() {
    return this.planIds.some((planId) => this.corePlanIds.includes(planId));
  }

  get corePlanIds() {
    if (this.pricing5_X_Graduation) {
      return [parseInt(PRICING_5_X_CORE_ADVANCED_ID, 10)];
    } else if (this.customerService.onVbp1_X) {
      return [parseInt(CORE_ACCELERATE_ID, 10)];
    } else {
      return [parseInt(CORE_SUPPORT_PRO_ID, 10), parseInt(CORE_ENGAGE_PRO_ID, 10)];
    }
  }

  get downgradeAddons() {
    if (this.pricing5_X_Graduation) {
      return [PROACTIVE_SUPPORT_PLUS_BASE_ID, FIN_AI_COPILOT_BASE_ID];
    }
    return [PRODUCT_TOURS_ESSENTIAL_ID];
  }

  get nextStepYearNumber() {
    return this.earlyStageStep?.nextStepConfig?.yearNumber;
  }

  get nextStepPercentageDiscount() {
    return this.earlyStageStep?.nextStepConfig?.couponPercentOff;
  }

  get currentStepYearNumber() {
    return this.earlyStageStep?.stepConfig?.yearNumber;
  }

  get currentStepPercentageDiscount() {
    return this.earlyStageStep?.stepConfig?.couponPercentOff;
  }
}
