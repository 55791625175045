/* RESPONSIBLE TEAM: team-product-exploration */
/* eslint-disable @intercom/intercom/no-bare-strings */

// This file powers search within Settings area of the product.
// The data here is used to generate the list of settings routes that are searchable within global search,
// along with search synonyms, and their children.
//
// If you have added a new route to the Settings you should add it to the correct part of this data structure to ensure it
// appears in search. Routes are grouped by how they appear in the nav in the UI.
// Example:
//   {
//     route: 'apps.app.settings.my-cool-new-feature-settings',
//     name: 'Cool New Feature',
//     synonyms: ['Excellent new Feature', 'Amazing new feature'],
//     requiredFeatureFlags: ['my-cool-new-feature'], // Optional - if the route requires a feature flag to be accessed
//     children: [
//       ...
//     ],
//   },
//
// NOTE: If you don't want your route to appear in search, you can add it to the exclusion list at the bottom of this file.

import type SearchableRoute from './searchable-route';

const SettingsRoutes: Array<SearchableRoute> = [
  {
    route: 'apps.app.settings',
    name: 'Settings',
    synonyms: ['Configuration', 'Preferences', 'Options'],
    children: [
      {
        route: 'apps.app.settings.general',
        name: 'General',
        synonyms: ['Essentials'],
        children: [
          {
            route: 'apps.app.settings.general',
            name: 'Workspace Settings',
            synonyms: ['App Settings'],
            children: [
              {
                name: 'Change Workspace Name',
                synonyms: [],
                route: 'apps.app.settings.general',
              },
              {
                name: 'Change Timezone',
                synonyms: [],
                route: 'apps.app.settings.general',
              },
              {
                name: 'Manage Test Workspace',
                synonyms: [],
                route: 'apps.app.settings.general',
              },
              {
                name: 'Delete Workspace',
                synonyms: [],
                route: 'apps.app.settings.general',
              },
            ],
          },
          {
            route: 'apps.app.settings.teammates',
            name: 'Teammates',
            synonyms: ['Team Members', 'Coworkers', 'Colleagues'],
            children: [
              {
                name: 'Add a teammate',
                synonyms: ['invite a teammate'],
                route: 'apps.app.settings.teammates.invite',
              },
              {
                name: 'Toggle seats',
                synonyms: [],
                route: 'apps.app.settings.teammates',
              },
              {
                name: 'Change permissions',
                synonyms: [],
                route: 'apps.app.settings.teammates',
              },
              {
                name: 'Export CSV of teammates',
                synonyms: [],
                route: 'apps.app.settings.teammates',
              },
            ],
          },
          {
            route: 'apps.app.settings.teams',
            name: 'Teams',
            synonyms: ['Groups'],
            children: [
              {
                name: 'Create a team',
                synonyms: [],
                route: 'apps.app.settings.teams',
              },
              {
                name: 'Delete a team',
                synonyms: [],
                route: 'apps.app.settings.teams',
              },
            ],
          },
          {
            route: 'apps.app.settings.roles',
            name: 'Roles',
            synonyms: ['Permissions', 'Access Levels', 'User Roles'],
            children: [
              {
                name: 'Create a role',
                synonyms: [],
                route: 'apps.app.settings.roles',
              },
              {
                name: 'Delete a role',
                synonyms: [],
                route: 'apps.app.settings.roles',
              },
            ],
          },
          {
            route: 'apps.app.settings.logs',
            name: 'Logs',
            synonyms: ['Records', 'History', 'Data Logs', 'Activity Logs'],
            children: [
              {
                name: 'Export logs',
                synonyms: [],
                route: 'apps.app.settings.logs',
              },
            ],
          },
          {
            route: 'apps.app.settings.office-hours',
            name: 'Office Hours',
            synonyms: ['Business Hours', 'Work Schedule', 'Availability'],
            children: [
              {
                name: 'Change default office hours',
                synonyms: [],
                route: 'apps.app.settings.office-hours',
              },
              {
                name: 'Manage custom office hours',
                synonyms: [],
                route: 'apps.app.settings.office-hours',
              },
            ],
          },
          {
            route: 'apps.app.settings.blocked-users',
            name: 'Blocked Users',
            synonyms: ['Blacklist', 'Blocked Contacts', 'Blocked Members', 'Banned'],
          },
          {
            route: 'apps.app.settings.ai-assist',
            name: 'Fin AI',
            synonyms: [
              'Artificial Intelligence',
              'AI Support',
              'AI Assistant',
              'Bot',
              'Chatbot',
              'AI Chatbot',
              'Fin AI Agent',
            ],
            children: [
              {
                name: 'AI compose settings',
                synonyms: [],
                route: 'apps.app.settings.ai-assist',
              },
              {
                name: 'AI autofill settings',
                synonyms: [],
                route: 'apps.app.settings.ai-assist',
              },
            ],
          },
        ],
      },

      {
        route: 'apps.app.settings.tags-new',
        name: 'Workspace Data',
        synonyms: ['Workspace Data'],
        children: [
          {
            route: 'apps.app.settings.audiences',
            name: 'Audiences',
            synonyms: ['Segments', 'Content'],
          },
          {
            route: 'apps.app.settings.tags-new',
            name: 'Tags',
            synonyms: ['Labels', 'Categories', 'Tagging'],
          },
          {
            route: 'apps.app.settings.people-segments',
            name: 'People Segments',
            synonyms: ['Groups', 'Lead Segments'],
          },
          {
            route: 'apps.app.settings.company-segments',
            name: 'Company Segments',
            synonyms: ['Business Groups'],
          },
          {
            route: 'apps.app.settings.people-data',
            name: 'People Data',
            synonyms: ['User Data', 'Customer Details'],
          },
          {
            route: 'apps.app.settings.company-data',
            name: 'Company Data',
            synonyms: ['Organization Data', 'Business Information'],
          },
          {
            route: 'apps.app.settings.conversation-data',
            name: 'Conversation Data',
            synonyms: ['Conversation attributes', 'Custom attributes'],
          },
          {
            route: 'apps.app.settings.custom-object-data',
            name: 'Custom Object Data',
            synonyms: ['Custom Data', 'Custom Entities', 'Custom Records', 'CDAs'],
          },
          {
            route: 'apps.app.settings.ticket-data',
            name: 'Ticket Types',
            synonyms: ['Issue Types', 'Request Types', 'Tickets'],
          },

          {
            route: 'apps.app.settings.ticket-states',
            name: 'Ticket States',
            synonyms: ['Issue Status', 'Request States'],
          },
          {
            route: 'apps.app.settings.qualification-settings',
            name: 'Qualification Settings',
            synonyms: ['Lead Qualification', 'Customer Profiling', 'Scoring Settings'],
          },
          {
            route: 'apps.app.settings.saved-replies',
            name: 'Macros (Saved Replies)',
            synonyms: ['Predefined Replies', 'Quick Replies'],
          },
          {
            route: 'apps.app.settings.events',
            name: 'Events',
            synonyms: ['Analytics', 'Tracking'],
          },
          {
            route: 'apps.app.settings.slas',
            name: 'SLAs',
            synonyms: ['Service Level Agreements', 'Guarantees', 'Service Quality'],
          },
          {
            route: 'apps.app.settings.imports',
            name: 'Import history',
          },
          {
            route: 'apps.app.settings.delete-user',
            name: 'Deleting people data',
            synonyms: ['Delete User', 'Delete lead', 'Erase Member', 'User Deletion'],
          },
          {
            route: 'apps.app.settings.data-export',
            name: 'Data Export',
            synonyms: ['Export Data', 'Data Transfer', 'Backup'],
          },
        ],
      },

      {
        route: 'apps.app.settings.workload-management',
        name: 'Help Desk',
        synonyms: ['Inbox'],
        children: [
          {
            route: 'apps.app.settings.workload-management',
            name: 'Workload Management',
            synonyms: ['Inbound management'],
            children: [
              {
                route: 'apps.app.settings.workload-management',
                name: 'Conversations sorting',
                synonyms: ['Balancing', 'Load Balancing', 'Distribution', 'Balanced assignment'],
              },
              {
                route: 'apps.app.settings.workload-management',
                name: 'Assignment limit',
              },
              {
                route: 'apps.app.settings.workload-management',
                name: 'Tickets assignment',
              },
              {
                route: 'apps.app.settings.workload-management',
                name: 'Pull conversation',
              },
            ],
          },
          {
            route: 'apps.app.settings.inbox-rules.list',
            name: 'Rules',
            synonyms: ['Inbound routing', 'Inbound rules', 'Inbound settings'],
          },
          {
            route: 'apps.app.settings.automatic-away-mode',
            name: 'Automatic Away Mode',
            synonyms: ['Auto Unavailable', 'Offline mode'],
          },
          {
            route: 'apps.app.settings.assignment-preferences',
            name: 'Assignment Preferences',
            synonyms: ['Task Allocation', 'Distribution Settings', 'Work Assignment'],
          },
          {
            route: 'apps.app.settings.teammate-presence',
            name: 'Teammate Presence',
            synonyms: ['Status'],
          },
        ],
      },

      {
        route: 'apps.app.settings.domains',
        name: 'Email',
        synonyms: [],
        children: [
          {
            route: 'apps.app.settings.domains',
            name: 'Domains',
            synonyms: ['Domain Management', 'Web Domains', 'URLs'],
          },
          {
            route: 'apps.app.settings.senders',
            name: 'Sender email',
            synonyms: ['Message Senders', 'Identity', 'From'],
          },
          {
            route: 'apps.app.settings.email-forwarding',
            name: 'Email Forwarding',
            synonyms: ['Message Redirection', 'Mail Relay', 'Email Routing'],
          },
          {
            route: 'apps.app.settings.email-notifications',
            name: 'Email Notifications',
            synonyms: ['Delivery Alerts', 'Recipient Notices', 'Email Status'],
          },
          {
            route: 'apps.app.settings.email-custom-assets-domains',
            name: 'Link Branding',
            synonyms: ['Domain Customization', 'URL customization', 'Custom domains'],
          },
          {
            route: 'apps.app.settings.email-dedicated-ips',
            name: 'Email Dedicated IPs',
            synonyms: ['IP Management', 'Email Servers', 'Dedicated Email'],
          },

          {
            route: 'apps.app.settings.email-signature',
            name: 'Signature',
            synonyms: ['Signoff'],
          },
          {
            route: 'apps.app.settings.email-other-settings',
            name: 'Other Settings',
            synonyms: ['Email Preferences', 'Email Customization', 'Email Options'],
            children: [
              {
                route: 'apps.app.settings.email-other-settings',
                name: 'Inbound email ticket settings',
                synonyms: ['Convert all inbound emails to tickets'],
              },
              {
                route: 'apps.app.settings.email-other-settings',
                name: 'Conversation history',
              },
              {
                route: 'apps.app.settings.email-other-settings',
                name: 'Strip links',
              },
              {
                route: 'apps.app.settings.email-other-settings',
                name: 'Auto Responders',
                synonyms: ['auto-responders', 'spam', 'junk'],
              },
              {
                route: 'apps.app.settings.email-other-settings',
                name: 'Replying to closed email conversations',
              },
            ],
          },
        ],
      },

      {
        route: 'apps.app.settings.calling',
        name: 'Phone',
        synonyms: ['Voice', 'Calling'],
      },

      {
        route: 'apps.app.settings.switch',
        name: 'Switch',
        synonyms: ['Handoff'],
        children: [
          {
            route: 'apps.app.settings.switch.setup',
            name: 'Setup',
          },
          {
            route: 'apps.app.settings.switch.monitor',
            name: 'Monitor',
          },
        ],
      },

      {
        route: 'apps.app.settings.installation',
        name: 'Installation',
        synonyms: ['Setup', 'Messenger installation', 'Messenger setup'],
        children: [
          {
            route: 'apps.app.settings.web',
            name: 'Web',
            synonyms: ['Website', 'Online Presence', 'Internet'],
          },
          {
            route: 'apps.app.settings.ios',
            name: 'iOS',
            synonyms: ['Apple Devices', 'iPhone', 'iPad'],
          },
          {
            route: 'apps.app.settings.android',
            name: 'Android',
            synonyms: ['Google Devices', 'Android OS', 'Mobile'],
          },
        ],
      },

      {
        route: 'apps.app.settings.security',
        name: 'Security',
        synonyms: ['Protection', 'Safeguards', 'Secure Access'],
        children: [
          {
            route: 'apps.app.settings.compliance-documents',
            name: 'Compliance Documents',
            synonyms: ['Legal Information', 'Regulations', 'Guidelines'],
          },
          {
            route: 'apps.app.settings.attachment-settings',
            name: 'Attachments & Links',
            synonyms: ['File Management', 'Attachment Preferences', 'Document Options'],
          },
          {
            route: 'apps.app.settings.identity-verification.web',
            name: 'Enforce identity',
            synonyms: ['Identity verification', 'Authentication'],
            children: [
              {
                route: 'apps.app.settings.identity-verification.web',
                name: 'Web',
                synonyms: [],
              },
              {
                route: 'apps.app.settings.identity-verification.ios',
                name: 'iOS',
                synonyms: ['apple'],
              },
              {
                route: 'apps.app.settings.identity-verification.android',
                name: 'Android',
                synonyms: [],
              },
            ],
          },
        ],
      },

      {
        route: 'apps.app.settings.custom-authentication-tokens',
        name: 'Apps & Integrations',
        synonyms: ['Apps', 'Integrations', 'Extensions', 'Add-ons', 'Apps and integrations'],
        children: [
          {
            route: 'apps.app.settings.custom-authentication-tokens',
            name: 'Custom Authentication Tokens',
            synonyms: ['API Tokens', 'Access Tokens', 'Security Tokens'],
          },
          {
            route: 'apps.app.settings.workflow-connector-actions',
            name: 'Custom Actions',
            synonyms: ['Custom functions', 'Workflow actions'],
          },
          {
            route: 'apps.app.settings.webhooks',
            name: 'Legacy Webhooks',
            synonyms: ['API Triggers', 'HTTP Callbacks', 'Automated Requests'],
          },
        ],
      },

      {
        route: 'apps.app.messenger',
        name: 'Messenger',
        synonyms: ['Chat bubble'],
        children: [
          {
            route: 'apps.app.settings.appearance',
            name: 'Appearance',
            synonyms: ['Design', 'Look and Feel', 'Theme'],
          },
        ],
      },
    ],
  },
];

export default SettingsRoutes;

// NOTE: Child routes will also be ignored
const IGNORED_SETTINGS_ROUTES = [
  'apps.app.settings.tickets-onboarding', // We don't want onboarding in search
  'apps.app.settings.custom-object-data.edit', // We can't search for an specific custom object data
  'apps.app.settings.ticket-data.detail', // We can't search for an specific ticket type
  'apps.app.settings.roles.new', // Not linking to specific roles yet.
  'apps.app.settings.roles.edit', // Not linking to specific roles yet.
  'apps.app.settings.team',
  // Cannot navigate to these routes as the apply to a specific person
  'apps.app.settings.teammates.invite.invite',
  'apps.app.settings.teammates.invite.edit',
  'apps.app.settings.teammates.invite.csv-import',
  'apps.app.settings.teammates.invite.permissions',
  'apps.app.settings.teammates.teammate.permissions',
  'apps.app.settings.teammates.teammate.remove',
  'apps.app.settings.teammates.teammate',
  'apps.app.settings.teammates.permissions',
  'apps.app.settings.teammates.remove',
  // Moved to the messenger route
  'apps.app.settings.messenger.web',
  'apps.app.settings.messenger.mobile-sdk',
  'apps.app.settings.messenger.settings',
  'apps.app.settings.messenger.conversations',
  'apps.app.settings.messenger',
  'apps.app.settings.inbox-rules.new',
  'apps.app.settings.inbox-rules.edit',
  'apps.app.settings.calling-old',
  // Not sure how we get to this regulatory bundle page
  'apps.app.settings.calling.phone-regulatory-bundles',
  'apps.app.settings.calling.phone-regulatory-bundles.new',
  'apps.app.settings.calling.phone-regulatory-bundles.show',
  'apps.app.settings.calling.phone-regulatory-bundles.edit',
  'apps.app.settings.identity-verification.identity-verification',
  'apps.app.settings.identity-verification',
  'apps.app.settings.migrate-from-zendesk',
  // Seems to be a legacy route
  'apps.app.settings.workflow-connector-actions.custom-action',
  'apps.app.settings.workflow-connector-actions',
  'apps.app.settings.custom-authentication-tokens.edit',
  'apps.app.settings.custom-authentication-tokens.new',
  // These settings live on the Email > Other settings page
  'apps.app.settings.email-conversation-history-settings',
  'apps.app.settings.strip-inbound-email-links-settings',
  'apps.app.settings.email-spam-settings',
  'apps.app.settings.email-auto-reply',
  // Handled by the messenger settings page at app.apps.messenger
  'apps.app.settings.new-messenger',
  // Links directly to apps.app.settings.inbox-rules.list
  'apps.app.settings.inbox-rules',
  // new messenger installation instruction route, adding here for now as its in development
  'apps.app.settings.installation-new',
  // new settings routes that are already part of the new settings global search
  'apps.app.settings.ai-automation.fin-ai-agent',
  'apps.app.settings.ai-automation.helpdesk-ai',
  'apps.app.settings.ai-automation.automation',
  'apps.app.settings.ai-automation',
  'apps.app.settings.proactive-support.subscriptions',
  'apps.app.settings.proactive-support.subscriptions.edit',
  'apps.app.settings.proactive-support.subscriptions.new',
  'apps.app.settings.proactive-support.newsfeeds',
  'apps.app.settings.proactive-support.news-labels',
  'apps.app.settings.proactive-support.customization',
  'apps.app.settings.proactive-support',
  'apps.app.settings.app-settings.custom-actions',
  'apps.app.settings.app-settings.custom-actions.custom-action',
  'apps.app.settings.app-settings.legacy-webhooks',
  'apps.app.settings.app-settings.authentication',
  'apps.app.settings.app-settings.authentication.edit',
  'apps.app.settings.app-settings.authentication.new',
  'apps.app.settings.app-settings.developer-hub',
  'apps.app.settings.app-settings',
  'apps.app.settings.channels.messenger.web',
  'apps.app.settings.channels.messenger.mobile-sdk',
  'apps.app.settings.channels.messenger.conversations',
  'apps.app.settings.channels.messenger.general',
  'apps.app.settings.channels.messenger.install',
  'apps.app.settings.channels.messenger',
  'apps.app.settings.channels.email',
  'apps.app.settings.channels.email.new-template',
  'apps.app.settings.channels.email.new-visual-template',
  'apps.app.settings.channels.email.edit-template',
  'apps.app.settings.channels.phone',
  'apps.app.settings.channels.phone.phone-regulatory-bundles',
  'apps.app.settings.channels.phone.phone-regulatory-bundles.new',
  'apps.app.settings.channels.phone.phone-regulatory-bundles.show',
  'apps.app.settings.channels.phone.phone-regulatory-bundles.edit',
  'apps.app.settings.channels.switch',
  'apps.app.settings.channels.sms',
  'apps.app.settings.channels.social-channels',
  'apps.app.settings.channels',
  'apps.app.settings.data.tags',
  'apps.app.settings.data.people',
  'apps.app.settings.data.audiences',
  'apps.app.settings.data.companies',
  'apps.app.settings.data.conversation-tickets',
  'apps.app.settings.data.custom-objects',
  'apps.app.settings.data.custom-objects.edit',
  'apps.app.settings.data.imports-exports',
  'apps.app.settings.data',
  'apps.app.settings.helpdesk.teams',
  'apps.app.settings.helpdesk.assignments',
  'apps.app.settings.helpdesk.macros',
  'apps.app.settings.helpdesk.tickets',
  'apps.app.settings.helpdesk.tickets.ticket-types.detail',
  'apps.app.settings.helpdesk.tickets.ticket-types',
  'apps.app.settings.helpdesk.tickets.tickets-onboarding',
  'apps.app.settings.helpdesk.sla',
  'apps.app.settings.helpdesk.rules',
  'apps.app.settings.helpdesk.rules.new',
  'apps.app.settings.helpdesk.rules.edit',
  'apps.app.settings.helpdesk',
  'apps.app.settings.workspace.general',
  'apps.app.settings.workspace.billing.invoices.invoice',
  'apps.app.settings.workspace.billing.invoices',
  'apps.app.settings.workspace.billing.details',
  'apps.app.settings.workspace.billing.summary',
  'apps.app.settings.workspace.billing.cancel.confirm',
  'apps.app.settings.workspace.billing.cancel.complete',
  'apps.app.settings.workspace.billing.cancel',
  'apps.app.settings.workspace.billing.settings',
  'apps.app.settings.workspace.billing.usage',
  'apps.app.settings.workspace.billing.migration',
  'apps.app.settings.workspace.billing.manage-usage',
  'apps.app.settings.workspace.billing.manage-subscription',
  'apps.app.settings.workspace.billing.update-subscription',
  'apps.app.settings.workspace.billing',
  'apps.app.settings.workspace.teammates',
  'apps.app.settings.workspace.teammates.permissions',
  'apps.app.settings.workspace.teammates.invite.new',
  'apps.app.settings.workspace.teammates.invite.edit',
  'apps.app.settings.workspace.teammates.invite.permissions',
  'apps.app.settings.workspace.teammates.invite.csv-import',
  'apps.app.settings.workspace.teammates.invite',
  'apps.app.settings.workspace.teammates.roles.new',
  'apps.app.settings.workspace.teammates.roles.edit',
  'apps.app.settings.workspace.teammates.roles',
  'apps.app.settings.workspace.teammates.teammate.permissions',
  'apps.app.settings.workspace.teammates.teammate.remove',
  'apps.app.settings.workspace.teammates.teammate',
  'apps.app.settings.workspace.office-hours',
  'apps.app.settings.workspace.brands',
  'apps.app.settings.workspace.security',
  'apps.app.settings.workspace',
  'apps.app.settings.helpcenter.workspace-helpcenter.collections',
  'apps.app.settings.helpcenter.workspace-helpcenter.settings',
  'apps.app.settings.helpcenter.workspace-helpcenter',
  'apps.app.settings.helpcenter.all',
  'apps.app.settings.helpcenter',
];

export { IGNORED_SETTINGS_ROUTES };
