/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr } from '@ember-data/model';
import { post } from 'embercom/lib/ajax';

import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

enum SalesforceConfigurationStatus {
  Pending = -1,
  Created = 0,
  Paused = 1,
  Enabled = 2,
}

const API_ROOT = '/ember/standalone/salesforce_configurations';

export default class SalesforceConfiguration extends Model {
  @service declare finStandaloneService: FinStandaloneService;

  @attr('string') declare instanceUrl: string;
  @attr('string') declare clientId: string;
  @attr('string') declare clientSecret: string;
  @attr('boolean') declare hasClientSecret: boolean;
  @attr('number') declare workflowRulesetId: number;

  @attr('string')
  declare finSalesforceUserId: string | undefined;

  @attr('string')
  declare finSalesforceHandoverId: string | undefined;

  @attr('number', { defaultValue: SalesforceConfigurationStatus.Pending })
  declare status: SalesforceConfigurationStatus;

  get isPendingSetup() {
    return this.status === SalesforceConfigurationStatus.Pending;
  }

  get isConnected() {
    return !this.isPendingSetup;
  }

  get isEnabled() {
    return this.status === SalesforceConfigurationStatus.Enabled;
  }

  async setFinUser(finSalesforceUserId: string) {
    await post(`${API_ROOT}/${this.id}/set_fin_salesforce_user`, {
      fin_salesforce_user_id: finSalesforceUserId,
    });

    this.finSalesforceUserId = finSalesforceUserId;
  }

  async setHandoverId(finSalesforceHandoverId: string) {
    await post(`${API_ROOT}/${this.id}/set_fin_salesforce_handover_id`, {
      fin_salesforce_handover_id: finSalesforceHandoverId,
    });

    this.finSalesforceHandoverId = finSalesforceHandoverId;
  }

  async activateForCases() {
    await post(`${API_ROOT}/${this.id}/activate`);
    await this.reload();
  }

  async deactivateForCases() {
    await post(`${API_ROOT}/${this.id}/deactivate`);
    await this.reload();
  }

  async disconnectApp() {
    await post(`${API_ROOT}/${this.id}/disconnect`);
    this.instanceUrl = '';
    this.clientId = '';
    this.clientSecret = '';
    this.status = SalesforceConfigurationStatus.Pending;
    this.hasClientSecret = false;
  }
}
