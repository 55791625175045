/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Model, { attr } from '@ember-data/model';
import { humanize } from 'ember-cli-string-helpers/helpers/humanize';

export default class Invite extends Model {
  @attr('string') declare token: string;
  @attr('string') declare appName: string;
  @attr('string') declare appIdCode: string;
  @attr('boolean') declare hasInboxAccess: boolean;
  @attr('string') declare senderName: string;
  @attr('string') declare senderEmail: string;
  @attr('string') declare senderAvatar: string;
  @attr('array') declare authorizedSignInMethods: string[];
  @attr('boolean') declare active: boolean;
  @attr('string') declare createdAt: string;
  @attr('boolean') declare isEmailInvite: boolean;
  @attr('string') declare email: string;
  @attr('boolean') declare revoked: boolean;
  @attr('boolean') declare guideTaskCompleted: boolean;
  @attr('string') declare guideChecklistKey: string;
  @attr('string') declare guideChecklistProductId: string;
  @attr('string') declare guideChecklistName: string;
  @attr('string') declare guideTaskKey: string;
  @attr('string') declare guideTaskName: string;
  @attr('string') declare guideTaskDescription: string;
  @attr('boolean') declare hasCustomDataAttributes: boolean;
  @attr('boolean') declare signedUpWithSolutionId: boolean;
  @attr('boolean') declare onboardingTaskCompleted: boolean;
  @attr('string') declare onboardingTaskKey: string;
  @attr('string') declare onboardingUseCaseKey: string;
  @attr('string') declare onboardingHomeStepIdentifier: string;
  @attr('boolean') declare appAtMaxTeammates: boolean;
  @attr('boolean') declare appIsSalesforceContracted: boolean;
  @attr('array') declare seatTypes: string[];
  @attr('boolean') declare completed: boolean;
  @attr('string') declare samlAccountName: string;
  @attr('boolean') declare forMessengerInstallation: boolean;

  get onboardingHomeStepName() {
    if (this.onboardingHomeStepIdentifier) {
      return humanize([this.onboardingHomeStepIdentifier]);
    }
    return undefined;
  }

  get analyticsData() {
    return {
      object: 'teammate_invite',
      invite_token: this.token,
    } as const;
  }
}
