/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr, belongsTo } from '@ember-data/model';
import generateUUID from 'embercom/lib/uuid-generator';
import { fragment } from 'ember-data-model-fragments/attributes';
import { buildValidations, validator } from 'ember-cp-validations';

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import type Step from './step';
import type Group from './group';
import type Edge from './edge';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';

const Validations = buildValidations({
  label: [
    validator('presence', {
      presence: true,
      disabled: computed(
        'model.{type,step.type,group}',
        function (this: { model: ConnectionPoint }) {
          return (
            this.model.group ||
            this.model?.step.type !== 'operator/visual-builder/step/reply-buttons' ||
            this.model?.type === 'capture_composer_input'
          );
        },
      ),
      messageKey: 'operator.workflows.visual-builder.validations.content-is-missing',
    }),
  ],
  edge: [
    validator('visual-builder/connection-points-are-valid', {
      message: 'operator.workflows.visual-builder.validations.all-paths-are-connected',
    }),
  ],
  predicateGroup: [
    validator('visual-builder/predicate-is-valid', {
      disabled: computed('model.{step.type,group}', function (this: { model: ConnectionPoint }) {
        return (
          this.model.group ||
          this.model.step.type !== 'operator/visual-builder/step/conditional-branches'
        );
      }),
      dependentKeys: ['model.predicateGroup.predicates.[]'],
      noPredicatesMessage:
        'operator.workflows.visual-builder.validations.conditional-branches-no-conditions-error',
      finishPredicatesMessage:
        'operator.workflows.visual-builder.validations.conditions-not-complete',
    }),
  ],
});

type ConnectionPointType =
  | 'follow_up'
  | 'fallback'
  | 'conditional_branch'
  | 'success'
  | 'capture_composer_input'
  | 'fin_triage';

export default class ConnectionPoint extends Model.extend(Validations) {
  @belongsTo('operator/visual-builder/step', { async: false, inverse: 'outwardConnectionPoints' })
  declare step: Step;
  @belongsTo('operator/visual-builder/group', { async: false, inverse: 'outwardConnectionPoint' })
  declare group: Group;
  @belongsTo('operator/visual-builder/edge', { async: false }) declare edge?: Edge;
  @attr('string') declare label: string;
  @attr('string') declare type: ConnectionPointType;
  @attr('boolean', { defaultValue: false }) declare isTerminal: boolean;
  @attr('boolean', { defaultValue: false }) declare isStartOver: boolean;
  @fragment('predicates/predicate-group') declare predicateGroup: any;

  @readOnly('step.editorConfig') declare editorConfig?: EditorConfig;

  get analyticsData() {
    return {
      ...(this.step?.group?.workflow?.analyticsData ?? {}),
      object: 'visual_builder_connection_point',
      connection_point_id: this.id,
      connection_point_type: this.type,
      is_terminal: this.isTerminal,
      step_id: this.step?.id,
      step_type: this.step?.type,
    };
  }

  @computed('edge.isDeleted')
  get isConnected(): boolean {
    return !!this.edge && !this.edge.isDeleted;
  }

  @computed('hasDirtyAttributes', 'edge.hasDirtyAttributes')
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.edge?.hasDirtyAttributes;
  }

  get isGroupLevelConnectionPoint() {
    return !!this.group;
  }

  rollbackAttributes() {
    this.edge?.rollbackAttributes();
    super.rollbackAttributes();
  }

  // exists for debugging but we will keep it for now
  toString() {
    return this.id || this._uuid;
  }

  // private - only to be used by serializers
  @attr('string', { defaultValue: () => generateUUID() }) declare _uuid: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'operator/visual-builder/connection-point': ConnectionPoint;
  }
}
