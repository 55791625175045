/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
import { attr } from '@ember-data/model';
import { equal, readOnly, not } from '@ember/object/computed';
import { computed } from '@ember/object';
import Fragment from 'ember-data-model-fragments/fragment';
import Admin from 'embercom/models/admin';
import { inject as service } from '@ember/service';

export default Fragment.extend({
  intl: service(),
  appService: service(),
  app: readOnly('appService.app'),
  attributeService: service(),
  _id: attr('string'),
  type: attr('string'),

  actionData: attr(),
  assignee: computed('actionData.assignee_id', function () {
    return Admin.peekAndMaybeLoad(this.store, this.get('actionData.assignee_id'));
  }),
  assignByRoundRobin: equal('actionData.round_robin', true),

  defaultComponentPath: computed('type', function () {
    return `rules/actions/${this.type}`;
  }),
  componentPath: computed('type', 'defaultComponentPath', function () {
    let type = this.type;

    switch (type) {
      case 'send-follow-up-reply':
      case 'close-conversation-with':
        return 'rules/actions/send-follow-up-reply';
      case 'send-app-without-introduction':
        return 'rules/actions/send-app';
    }

    return this.defaultComponentPath;
  }),
  isCloseConversationWithAction: equal('type', 'close-conversation-with'),

  justAdded: false,

  isValid: readOnly('info.isValid'),
  isInvalid: not('isValid'),

  info: computed('intl.locale', 'type', 'actionData', 'actionData.value', function () {
    let type = this.type;

    let reassignmentDescription = this.intl.t(
      'settings.inbox-rules.pick-assignee.reassignment-description',
    );
    let assignmentDescription = this.get('actionData.skip_assigned')
      ? this.intl.t('settings.inbox-rules.pick-assignee.assignment-description-skipped-assigned')
      : reassignmentDescription;

    switch (type) {
      case 'assign-conversation':
        return {
          actionDescription: assignmentDescription,
          isValid: Boolean(this.get('actionData.assignee_id')),
        };
      case 'reassign-conversation':
        return {
          actionDescription: reassignmentDescription,
          isValid: false, // Will be converted to a regular assignment before save
        };
      case 'send-follow-up-reply':
        return {
          icon: 'chat-bubble',
          description: 'Send bot message',
          actionDescription:
            'Send a custom reply to customers who provide their email after reading your message.',
          placeholder: 'e.g. Thanks',
          isValid: Boolean(this.get('actionData.reply')),
        };
      case 'snooze-conversation':
        return {
          icon: 'clock',
          description: 'Snooze conversation',
          overlayPrompt: 'Have Operator snooze the conversation.',
          actionDescription: 'Close the conversation.',
          isValid: Boolean(this.get('actionData.snoozed_until')),
        };
      case 'close-conversation':
        return {
          icon: 'check',
          description: 'Close conversation',
          overlayPrompt: 'Have Operator close the conversation.',
          actionDescription: 'Close the conversation.',
          isValid: true,
        };
      case 'close-conversation-with':
        return {
          icon: 'check',
          description: 'Send bot message and close conversation with',
          overlayPrompt: 'Have Operator send a final response and close the conversation.',
          placeholder:
            'e.g. Thanks for that info. We don’t have a MacOS option right now, but check back soon.',
          actionDescription: 'Send a custom reply and close the conversation.',
          isValid: Boolean(this.get('actionData.reply')),
        };
      case 'send-to-salesforce':
        return {
          icon: 'salesforce',
          description: 'Lead is created in Salesforce',
          actionDescription:
            'Automatically create a lead in Salesforce via the Salesforce integration.',
          isValid: true,
          placeholder: 'Intercom',
        };
      case 'create-salesforce-case':
        return {
          icon: 'salesforce',
          description: 'Case is created in Salesforce',
          actionDescription:
            'Automatically create a case in Salesforce via the Salesforce integration.',
          isValid: true,
        };
      case 'send-to-hubspot':
        return {
          icon: 'hubspot',
          description: 'Contact is created in HubSpot',
          actionDescription:
            'Automatically create a contact in HubSpot via the HubSpot integration.',
          isValid: true,
        };
      case 'send-to-marketo':
        return {
          icon: 'marketo',
          description: 'Lead is enriched in Marketo',
          actionDescription:
            'Automatically creates or enriches a lead in Marketo via the Marketo Enrichment integration.',
          isValid: true,
        };
      case 'trigger-marketo-campaign':
        return {
          icon: 'marketo',
          description: 'Trigger a Marketo campaign',
          overlayPrompt: "Enter the Marketo campaign's URL to fetch its details.",
          placeholder: 'https://example.marketo.com/#SC1234A1',
          actionDescription: 'Trigger a Marketo campaign for the current lead',
          isValid: Boolean(this.get('actionData.marketo_campaign_id')),
        };
      case 'tag-user':
        return {
          icon: 'tag',
          description: this.intl.t('settings.inbox-rules.rule-action-map.tag-user'),
          actionDescription:
            'Apply a tag to each customer so you can group them and more easily manage and message them.',
          isValid: (() => {
            let tagId = this.get('actionData.tag_id');
            let tagName = this.get('actionData.tag');

            if (!tagId && !tagName) {
              return false;
            } else if (tagId) {
              let tag = this.app.tags.findBy('id', tagId);
              return Boolean(tag && !tag.archived);
            } else if (tagName) {
              let tag = this.app.tags.findBy('name', tagName);
              return Boolean(tag && !tag.archived);
            }
          })(),
        };
      case 'send-app-without-introduction':
        return {
          icon: 'gallery',
          description: 'App',
          actionDescription: 'Send an app',
          isValid: Boolean(this.get('actionData.appName')),
        };
      case 'send-app':
        return {
          icon: 'gallery',
          description: 'App',
          actionDescription: 'Send an app',
          isValid: Boolean(this.get('actionData.appName')),
        };
      case 'send-app-with-introduction':
        return {
          icon: 'app-store',
          description: 'App',
          actionDescription: 'Send an app',
          isValid:
            Boolean(this.get('actionData.appName')) && Boolean(this.get('actionData.introduction')),
          isOpen: false,
        };
      case 'assign-conversation-to-owner':
        return {
          icon: 'assignment',
          description: 'Assign to owner from Salesforce',
          actionDescription: 'Automatically assign conversation to owner from salesforce.',
          isValid: true,
        };
      case 'change-conversation-priority':
        return {
          icon: 'star',
          description: 'Mark as priority',
          actionDescription: 'Mark as priority',
          isValid: true,
        };
      case 'apply-conversation-sla':
        return {
          icon: 'sla',
          description: 'Apply conversation SLA description',
          actionDescription: 'Apply conversation SLA action description',
          isValid: Boolean(this.get('actionData.conversation_sla_id')),
        };
      case 'add-tag-to-conversation':
        return {
          icon: 'intercom',
          description: this.intl.t('settings.inbox-rules.pick-assignee.add-tag-to-conversation'),
          actionDescription: this.intl.t(
            'settings.inbox-rules.pick-assignee.add-tag-to-conversation',
          ),
          isValid: Boolean(this.get('actionData.tag_id')),
        };
      case 'remove-tag-from-conversation':
        return {
          icon: 'intercom',
          description: this.intl.t(
            'settings.inbox-rules.pick-assignee.remove-tag-from-conversation',
          ),
          actionDescription: this.intl.t(
            'settings.inbox-rules.pick-assignee.remove-tag-from-conversation',
          ),
          isValid: Boolean(this.get('actionData.tag_id')),
        };
      case 'remove-tag-from-user':
        return {
          icon: 'tag',
          description: this.intl.t('settings.inbox-rules.remove-person-tag.description'),
          actionDescription: this.intl.t('settings.inbox-rules.remove-person-tag.description'),
          isValid: Boolean(this.get('actionData.tag_id')),
        };
      case 'set-conversation-data-attribute':
        return {
          icon: 'transfer',
          isValid:
            typeof this.get('actionData.value') !== 'undefined' &&
            this.get('actionData.value') !== '' &&
            typeof this.get('actionData.attribute_identifier') !== 'undefined' &&
            Boolean(
              this.get(
                'attributeService.nonArchivedNonSystemDefinedConversationCustomAttributes',
              ).findBy('identifier', this.get('actionData.attribute_identifier')),
            ),
        };
      case 'disable-end-user-follow-up-replies':
        return {
          icon: 'remove',
          description: 'Disable composer',
          overlayPrompt: 'Prevent follow up replies.',
          actionDescription: 'Disables the composer.',
          isValid: true,
        };
      case 'workflow-connector-action':
        return {
          icon: 'webhook',
          description: 'Custom action',
          actionDescription: 'Trigger a Custom action',
          isValid: Boolean(this.get('actionData.workflow_connector_action_id')),
        };
      case 'notify-slack-channel':
        return {
          icon: 'slack',
          description: this.intl.t('settings.inbox-rules.actions.notify-slack-channel.name'),
          actionDescription: this.intl.t(
            'settings.inbox-rules.actions.notify-slack-channel.description',
          ),
          isValid: Boolean(this.get('actionData.slack_channel_id')),
        };
      case 'notify-private-slack-channel':
        return {
          icon: 'slack',
          description: this.intl.t(
            'settings.inbox-rules.actions.notify-private-slack-channel.name',
          ),
          actionDescription: this.intl.t(
            'settings.inbox-rules.actions.notify-private-slack-channel.description',
          ),
          isValid: Boolean(this.get('actionData.slack_channel_id')),
        };
      case 'set-ticket-state':
        return {
          icon: 'ticket',
          description: 'Set ticket state',
          actionDescription: 'Set ticket state',
          isValid: this.get('actionData.ticket_state_id') !== undefined,
        };
      case 'set-ticket-custom-state':
        return {
          icon: 'ticket',
          description: 'Set ticket state',
          actionDescription: 'Set ticket state',
          isValid: this.get('actionData.ticket_custom_state_id') !== undefined,
        };
      case 'add-conversation-topic':
        return {
          icon: 'insights',
          description: 'Add conversation topic',
          actionDescription: 'Add conversation topic',
          isValid: this.get('actionData.topic_id') !== undefined,
        };
      case 'summarize-conversation':
        return {
          icon: 'ai',
          description: 'Add summary note',
          actionDescription: 'Add summary note',
          isValid: true,
        };
      case 'classify-conversation-attribute':
        return {
          icon: 'ai',
          description: 'Automatically classify conversation topic',
          actionDescription: 'Automatically classify conversation topic',
          isValid: true,
        };
      case 'set-language-override':
        return {
          icon: 'language',
          description: this.intl.t('settings.inbox-rules.actions.set-language-override.name'),
          actionDescription: this.intl.t(
            'settings.inbox-rules.actions.set-language-override.description',
          ),
          isValid: this.get('actionData.language_code') !== undefined,
        };
      case 'set-user-data-attribute':
        return {
          icon: 'transfer',
          isValid:
            typeof this.get('actionData.value') !== 'undefined' &&
            this.get('actionData.value') !== '' &&
            Boolean(
              this.get('attributeService.visualBotBuilderSetUserDataAttributes').findBy(
                'identifier',
                this.get('actionData.attribute_identifier'),
              ),
            ),
        };
      default:
        throw new Error(`"${type}" is an unsupported action type`);
    }
  }),
});
