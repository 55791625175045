/* RESPONSIBLE TEAM: team-ai-chatbot */

export interface ResolutionBotGenericTriageSummaryWireFormat {
  id: string;
  title: string;
}

export default class ResolutionBotGenericTriageSummary {
  readonly id: string;
  readonly title: string;

  constructor(id: string, title: string) {
    this.id = id;
    this.title = title;
  }

  static deserialize(
    json: ResolutionBotGenericTriageSummaryWireFormat,
  ): ResolutionBotGenericTriageSummary {
    return new ResolutionBotGenericTriageSummary(json.id, json.title);
  }
}
