/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { REQUIRED_CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES } from 'embercom/models/custom-objects/constants/standard-attribute-names';
import type Action from './action';
import type ActionAttributeMapping from './action-attribute-mapping';
import type ActionReferenceMapping from './action-reference-mapping';

export default class ActionObjectMapping extends Fragment {
  @service declare customObjectsService: any;
  @service declare appService: any;

  @attr('string') declare responseObjectPath: string;
  @attr('string') declare intercomObjectType: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @fragmentArray('workflow-connector/action-attribute-mapping')
  declare attributeMappings: ActionAttributeMapping[];
  @fragmentArray('workflow-connector/action-reference-mapping')
  declare referenceMappings: ActionReferenceMapping[];

  @tracked declare workflowAction: Action;

  get hasAttributeMappings() {
    return isPresent(this.attributeMappings);
  }

  get hasRequiredAttributeMappings() {
    let attributeMappingIdentifiers = this.attributeMappings.mapBy('intercomAttributeIdentifier');
    return REQUIRED_CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES.every((requiredAttributeIdentifier) =>
      attributeMappingIdentifiers.includes(requiredAttributeIdentifier),
    );
  }

  get apiMappableObject() {
    return this.workflowAction.apiMappableObjects.find(
      (e: any) => e.fullPath === this.responseObjectPath,
    );
  }

  get apiMappableObjectPresent() {
    return isPresent(this.apiMappableObject);
  }

  get apiMappableAttributes() {
    if (!this.apiMappableObject || !this.apiMappableObjectPresent) {
      return [];
    }

    return this.apiMappableObject.apiMappableAttributes;
  }

  get intercomMappableObject() {
    // TODO archived
    return this.customObjectsService.objectTypes.findBy('identifier', this.intercomObjectType);
  }

  get intercomMappableObjectPresent() {
    return isPresent(this.intercomMappableObject);
  }

  get isCustomObjectMapping() {
    return this.customObjectsService.customObjectTypes.includes(this.intercomMappableObject);
  }

  get isInvalidArrayMapping() {
    if (!this.apiMappableObjectPresent) {
      return false;
    }

    if (!this.intercomMappableObjectPresent) {
      return false;
    }

    return !this.isCustomObjectMapping && this.apiMappableObject?.isArray;
  }

  get validationErrors() {
    if (!this.workflowAction.testResult.testedAction) {
      return [];
    }

    if (!this.apiMappableObjectPresent) {
      return ['api_object_missing'];
    }

    if (!this.intercomMappableObjectPresent) {
      return ['intercom_object_missing'];
    }

    if (this.isInvalidArrayMapping) {
      return ['intercom_object_cant_be_mapped_to_api_array'];
    }

    let attributeMappingsValidationResult = this.attributeMappings
      .map((attributeMapping) => attributeMapping.validationErrors)
      .flat();

    if (isPresent(attributeMappingsValidationResult)) {
      return ['invalid_attribute_mappings'];
    }

    let referenceMappingsValidationResult = this.referenceMappings
      .map((referenceMapping) => referenceMapping.validationErrors)
      .flat();

    if (isPresent(referenceMappingsValidationResult)) {
      return ['invalid_reference_mappings'];
    }

    return [];
  }

  get missingRequiredAttributeMappingWarnings() {
    if (this.isCustomObjectMapping && !this.hasRequiredAttributeMappings) {
      return ['required_custom_object_attribute_missing'];
    } else {
      return [];
    }
  }

  hydrateParent(workflowAction: Action) {
    this.workflowAction = workflowAction;

    this.attributeMappings.forEach((attributeMapping) => {
      attributeMapping.hydrateParent(this);
    });
  }
}
