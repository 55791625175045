/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';

export default Model.extend({
  webPushForAssignedToMe: attr(),
  webPushForAssignedToTeam: attr(),
  webPushForUnassigned: attr(),
  webPushForOtherTeamsAndTeammates: attr(),
  webPushForMentions: attr(),
  webPushForMessagesYouSent: attr(),
  webPushForOwnedConversations: attr(),
  webPushForOwnedAccountRevisits: attr(),

  hasAnyWebPush: or(
    'webPushForAssignedToMe',
    'webPushForAssignedToTeam',
    'webPushForUnassigned',
    'webPushForOtherTeamsAndTeammates',
    'webPushForMentions',
    'webPushForMessagesYouSent',
    'webPushForOwnedConversations',
    'webPushForOwnedAccountRevisits',
  ),

  mobileForAssignedToMe: attr(),
  mobileForAssignedToTeam: attr(),
  mobileForUnassigned: attr(),
  mobileForOtherTeamsAndTeammates: attr(),
  mobileForMentions: attr(),
  mobileForMessagesYouSent: attr(),
  mobileForOwnedConversations: attr(),
  mobileForOwnedAccountRevisits: attr(),

  emailForAssignedToMe: attr(),
  emailForAssignedToTeam: attr(),
  emailForUnassigned: attr(),
  emailForOtherTeamsAndTeammates: attr(),
  emailForMentions: attr(),
  emailForMessagesYouSent: attr(),
  emailForOwnedConversations: attr(),
  emailForOwnedAccountRevisits: attr(),

  browserAlertSetting: attr(),
  browserAudioAlerts: attr(),

  accountOwnerSpecificPageNotificationEnabled: attr(),
  accountOwnerSpecificPageNotificationUrl: attr(),

  updatesAboutAppsEnabled: attr(),

  emailForContentReviewRequests: attr(),

  notificationSound: attr(),

  /*
    Browser notification settings are treated as bits of a binary number, each bit representing if a notification setting is
    turned on or not.

    111 (7) -> all notifications turned on
    101 (5) -> notifications for Unassigned & conversations assigned to you
    011 (3) -> notifications for your Team & conversations assigned to you
    001 (1) -> Only notifications for conversations assigned to you
   */

  browserAlertsForUnassigned: computed('browserAlertSetting', {
    get() {
      return this.browserAlertSetting === 5 || this.browserAlertSetting === 7;
    },
    set(key, value) {
      let sum = this.browserAlertsForTeam ? 2 : 0;
      this.set('browserAlertSetting', value ? sum + 5 : sum + 1);
      return value;
    },
  }),
  browserAlertsForTeam: computed('browserAlertSetting', {
    get() {
      return this.browserAlertSetting === 3 || this.browserAlertSetting === 7;
    },
    set(key, value) {
      let sum = this.browserAlertsForUnassigned ? 4 : 0;
      this.set('browserAlertSetting', value ? sum + 3 : sum + 1);
      return value;
    },
  }),

  dailySummaryEmailFor: attr(),

  savePushSubscription(app, admin, pushSubscription) {
    let subscriptionJson = pushSubscription.toJSON();
    return ajax({
      type: 'POST',
      url: '/ember/web_push_endpoints',
      data: JSON.stringify({
        id: admin.get('id'),
        app_id: app.get('id'),
        client_auth_secret: subscriptionJson.keys.auth,
        client_public_key: subscriptionJson.keys.p256dh,
        endpoint: subscriptionJson.endpoint,
      }),
    });
  },
});
