/* RESPONSIBLE TEAM: team-workflows */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import { fragmentArray, fragmentOwner } from 'ember-data-model-fragments/attributes';
import Validations from 'embercom/validations/operator/bot-only-message/localization';

export default class Localization extends Fragment.extend(Validations) {
  @attr('string') locale;
  @attr('string') name;
  @fragmentArray('operator/bot-only-message/part') parts;
  @fragmentOwner() behavior;

  get hasChanges() {
    return this.hasDirtyAttributes || this.parts.hasDirtyAttributes;
  }
}
