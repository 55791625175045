/* RESPONSIBLE TEAM: team-knowledge-interop */
import Model, { attr, hasMany, belongsTo, type SyncHasMany } from '@ember-data/model';
import { MAX_DEPTH, orderFolders } from 'embercom/components/knowledge-hub/folders/tree-helper';
import KnowledgeHubItemMixin, { type KnowledgeHubItem } from './knowledge-hub-item';
import { type TreeItem, type TreeParent } from 'embercom/objects/tree-list';
import { tracked } from 'tracked-built-ins';
import { EntityType } from '../data/entity-types';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { type RouteInfoWithAttributes } from '@ember/routing/route-info';
import type KnowledgeHubContentWrapper from './knowledge-hub-content-wrapper';
import type ImportSource from '../import-service/import-source';
import { SourceType, SourceTypeName } from '../import-service/import-source';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

export const SourceTypeNamesWithIcon = new Set([
  SourceTypeName[SourceType.Confluence],
  SourceTypeName[SourceType.Guru],
  SourceTypeName[SourceType.Notion],
  SourceTypeName[SourceType.Zendesk],
]);

export default class Folder extends KnowledgeHubItemMixin(Model) implements KnowledgeHubItem {
  @attr('string') declare name: string;
  @attr('string') declare emoji: string;
  @attr('string') declare description: string;
  @attr('boolean') declare isSynced: boolean;
  @attr('string') declare sourceTypeName?: string;
  @attr('number') declare sourceId?: number;

  @belongsTo('content-service/knowledge-hub-content-wrapper', { async: false })
  declare contentWrapper: KnowledgeHubContentWrapper;

  @hasMany('content-service/folder', { inverse: 'parentFolder', async: false })
  declare subFolders: SyncHasMany<Folder>;
  @belongsTo('content-service/folder', { inverse: 'subFolders', async: false })
  declare parentFolder?: Folder;
  @belongsTo('import-service/import-source', { async: false })
  declare source?: ImportSource;

  @tracked
  declare hoveredOverByDragable: boolean;

  get title(): string {
    return this.name;
  }

  get hasChildren(): boolean {
    return this.numberOfSubFolders > 0;
  }

  get numberOfSubFolders(): number {
    return this.subFolders.length as number;
  }

  get orderedSubFolders(): Folder[] {
    return orderFolders(this.subFolders.toArray());
  }

  get isEditable(): boolean {
    return !this.isSynced;
  }

  get folderPath(): Array<Folder> {
    if (this.isRoot) {
      return [this];
    } else {
      return [...this.parentFolder!.folderPath, this];
    }
  }

  get analyticsData(): any {
    return {
      object: 'folder',
      id: this.id,
      name: this.name,
      is_synced: this.isSynced,
      depth: this.depth,
    };
  }

  get url(): string {
    return this.router.urlFor('apps.app.knowledge-hub.folder', this);
  }

  get descendantIds(): string[] {
    let descendants = this.subFolders.toArray();
    let ids = descendants.map((folder) => folder.id);
    for (let folder of descendants) {
      ids = ids.concat(folder.descendantIds);
    }
    return ids;
  }

  moveChildrenToParent(): void {
    if (this.subFolders) {
      for (let folder of this.subFolders.toArray()) {
        folder.parentFolder = this.parentFolder;
      }
    }
  }

  async delete(): Promise<void> {
    this.moveChildrenToParent();
    this.parent = undefined;
    await this.destroyRecord();
  }

  atMaxDepth(): boolean {
    return this.depth >= MAX_DEPTH;
  }

  maxSubfoldersBelow(): number {
    if (this.hasChildren) {
      let maxOfSubfolders = Math.max(
        ...this.subFolders.map((folder) => folder.maxSubfoldersBelow()),
      );
      return maxOfSubfolders + 1;
    } else {
      return 0;
    }
  }

  get isSyncedSubFolder(): boolean {
    return this.isSynced && !this.isRoot;
  }

  get hasSourceIconUrl(): boolean {
    return !!this.sourceTypeName && SourceTypeNamesWithIcon.has(this.sourceTypeName);
  }

  get sourceIconURL(): string {
    return assetUrl(`/assets/images/knowledge-hub/icons/${this.sourceTypeName}.png`);
  }

  // ======= implementation of KnowledgeHubItem =======

  @tracked
  declare treeItem: TreeItem<Folder> | undefined;

  get displayName(): string {
    return this.title;
  }

  get parent() {
    return this.parentFolder;
  }

  set parent(_parent: Folder | undefined) {
    this.parentFolder = _parent;
    this.onParentChange(_parent);
  }

  get parentIdFromRelationship(): string | undefined {
    // @ts-ignore - TS doesn't like the 'parentFolder' key here for some reason
    let folderRelationship = this.belongsTo('parentFolder');
    return folderRelationship?.id();
  }

  hasMoved({ oldParent }: { oldParent: TreeParent }): boolean {
    if (super.hasMoved({ oldParent })) {
      return true;
    }
    let oldParentItem = oldParent as TreeItem<Folder>;
    return (
      oldParentItem.children?.indexOf(this.treeItem!) !==
      oldParentItem.dataObject?.orderedSubFolders.indexOf(this)
    );
  }

  updateLocationToMatch({ treeItem }: { treeItem: TreeItem<Folder> }) {
    this.treeItem = treeItem;
  }

  get contentWrapperId(): string {
    return `${ContentWrapperType.SUPPORT_CONTENT}_${EntityType.ContentLibraryFolder}_${this.id}`;
  }

  get parentIsCurrentRoute(): boolean {
    let currentRoute = this.router.currentRoute as RouteInfoWithAttributes;
    return (
      this.parent === currentRoute.attributes ||
      (!this.parent && currentRoute.name === 'apps.app.knowledge-hub.all-content')
    );
  }

  async moveToFolderAndSave({ folder }: { folder?: Folder }): Promise<void> {
    try {
      this.parent = folder;
      await super.save();
      this.knowledgeHubService.api?.removeContentWrapper(this.contentWrapperId);
      if (this.parentIsCurrentRoute) {
        this.knowledgeHubService.api?.refreshPageAfterDelay();
      }

      this.notifyMoveConfirmation();
    } catch (e) {
      this.notifyMoveError();
    }
  }

  get entityType(): EntityType {
    return EntityType.ContentLibraryFolder;
  }

  moveToRootSuccessNotificationText(): string {
    return this.intl.t('knowledge-hub.folders.did-drop-item.root-folder');
  }

  // ======= end of KnowledgeHubItem =======
}
