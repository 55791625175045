/* RESPONSIBLE TEAM: team-tickets-1 */
import { type BlockList } from '@intercom/interblocks.ts';
import UserSummary, { type UserSummaryWireFormat } from './user-summary';

export interface SmartReplyWireFormat {
  blocks: BlockList;
  version_id: string;
  type: string;
  answer_bot_transaction_id: string;
  sources: Array<{
    entity_id: number;
    entity_type: number;
    user_summary: UserSummaryWireFormat | null;
  }> | null;
  conversation_id: number;
}

export interface SmartReplySource {
  entityId: number;
  entityType: number;
  userSummary?: UserSummary;
}

export default class SmartReply {
  readonly conversationId: number;
  readonly blocks: BlockList;
  readonly versionId: string;
  readonly isPending: boolean;
  readonly type: string;
  readonly answerBotTransactionId: string;
  readonly sources: SmartReplySource[];

  viewed = false;

  constructor(inputs: SmartReplyWireFormat, isPending = false) {
    this.conversationId = inputs.conversation_id;
    this.blocks = inputs.blocks;
    this.versionId = inputs.version_id;
    this.isPending = isPending;
    this.type = inputs.type;
    this.answerBotTransactionId = inputs.answer_bot_transaction_id;
    this.sources =
      inputs.sources?.map((source) => ({
        entityId: source.entity_id,
        entityType: source.entity_type,
        ...(source.user_summary
          ? { userSummary: UserSummary.deserialize(source.user_summary) }
          : {}),
      })) || [];
  }

  get analyticsData() {
    return {
      object: 'smart_reply',
      suggestion_version_id: this.versionId,
      type: this.type,
      answer_bot_transaction_id: this.answerBotTransactionId,
      blocks: this.blocks,
    };
  }

  static createPending() {
    return new SmartReply(
      {
        blocks: [],
        version_id: '',
        type: '',
        answer_bot_transaction_id: '',
        sources: [],
        conversation_id: 0,
      },
      true,
    );
  }
}
