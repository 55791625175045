/* RESPONSIBLE TEAM: team-reporting */
import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';
import type ReportAccessService from 'embercom/services/report-access-service';
import { type Fn } from 'glint/common';

export interface Signature {
  Element: HTMLElement;
  Args: Args;
}

interface Args {
  Positional: [boolean, Fn];
}

export default class OnAccessRevoke extends Modifier<Signature> {
  @service declare reportAccessService: ReportAccessService;

  modify(_: HTMLElement, [hasRequiredAccess, onRevokeAccess]: Args['Positional']) {
    if (!hasRequiredAccess) {
      onRevokeAccess();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'on-access-revoke': typeof OnAccessRevoke;
  }
}
